import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";

const USER_GUIDE = `
This Privacy Policy is provided to Virgin Islands by using Virgin Island’s website, software, digital media, storage media or functions related to the GXT platform (collectively “GXT Platform”), the company responsible for the sale of the GXT Platform. The standards and conditions that apply to the processing of personal information. If you use this GXT platform, you should always review this policy when using this GXT platform to ensure that you are bound by the current privacy and cookie policy and that you agree to this policy. This Privacy and Cookie Policy may be amended in accordance with applicable laws, regulations and guidelines. Amendments to this Privacy Policy and their reasons will be posted on this website. You promise to notify the GXT Platform of any changes or corrections to your personal information. You are solely responsible for any loss or damage caused by errors or inaccuracies in the personal information you provide to GXT Platform or its representatives or third parties.
`;

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const Title = styled.h3`
    margin: 21px;
    color: black;
`;

const GuideWrap = styled.pre`
    border: 1px solid rgb(209, 209, 219);
    font-size: 14px;
    padding: 16px;
    margin: 21px;
    word-break: break-all;
    white-space: break-spaces;
    font-family: 'Noto Sans' sans-serif;
    color: #666A80;
`;

//function UserGuide(props) {
export default class Payment extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        content: '',
        file: '',
    }

    async componentDidMount() {
        document.getElementById('app_page_name').value = "payment";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const result = await API.getTermData(3);
        if (result.status === 'success') {
            var parser = new DOMParser();
            var doc = parser.parseFromString(result.content, 'text/html');
            var stringToHTML = doc.body;
            this.setState({
                content: stringToHTML.innerText,
                file: result.attachedFile,});
        }
    }

    render() {

        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                User Guide
            </GuideTitle>
            <Title>
                1. User Guide Example
            </Title>
            <GuideWrap>
                {this.state.content}
            </GuideWrap>
        </>
    }
}

