import React from 'react';
import Tab from './components/Tab';
import NavigationBar from './components/NavigationBar';
import {Button} from './styled-components';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";

const ContactWrap = styled.div`
    padding: 20px;
    h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    select {
        height: 50px;
        width: 100%;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight : bold;
        padding: 0 16px;
        border-radius: 24px;
        background-color: white;
        box-shadow: 1px 1px 10px 5px #00000029;
        background:url('/static/img/bottom_arrow.png') no-repeat 94% 50%/15px auto;
        appearance : none ;
        -webkit-appearance : none ;
        border : none;
    }
    select::-ms-expand {
        display : none ;
    }
    textarea {
        box-sizing: border-box;
        height: 180px;
        resize: none;
        width: 100%;
        margin-bottom: 24px;
        padding: 16px;
        outline: none;
        border-radius: 24px;
        border : none;
        box-shadow: 1px 1px 10px 5px #00000029;
    }
`;

class Contact extends React.PureComponent {
    state = {
        category : '0',
        message : '',
        tabValue: 'contact',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        list : [],
        showList : true,
        showNoticeDetail : false,
        popup_time: '',
        popup_title: '',
        popup_content: '',
        popup_answer: '',
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "contact";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        let list_inquiry = await API.getInquiry();
        if (list_inquiry.status === 'success') {
            this.setState({
                list: list_inquiry.result,
                showList : list_inquiry.result.length !== 0,
            });
        }
    }

    renderForm() {
        const {lang_data, message, category} = this.state;
        return (
            <ContactWrap>
                <h4>{lang_data.category_label}</h4>
                <select onChange={(e) => this.setState({category: e.currentTarget.value})}>
                    <option value={0}>Support</option>
                    <option value={1}>Donation</option>
                    <option value={2}>Dorothy</option>
                    <option value={3}>System</option>
                    <option value={4}>Deposite</option>
                    <option value={5}>Withdraw</option>
                    <option value={6}>ETC</option>
                </select>
                <h4>{lang_data.message_label}</h4>
                <textarea placeholder={lang_data.level_me_message_label}
                          onChange={(e) => this.setState({message: e.currentTarget.value})}/>
                <Button className="green"
                        onClick={async () => {
                            if (message.length < 3) {
                                alert("Please enter a message of at least 3 characters.");
                                return;
                            }
                            let check = await API.insertInquiry(category, message);
                            if (check.status === 'success') {
                                alert("Your inquiry was successful.");
                                this.props.history.goBack();
                            } else {
                                alert("Your inquiry has failed.");
                            }
                        }}>{lang_data.send_label}</Button>
            </ContactWrap>
        );
    }

    renderHistory() {
        const {lang_data, list, showList, showNoticeDetail, popup_time, popup_content, popup_title, popup_answer} = this.state;
        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
        <div style={{ marginLeft: 16, marginTop: 16, display: list.length === 0 ? 'block' : 'none' }}>
            {lang_data.no_history_label}
        </div>
        {(list || []).map((e, i) =>
            <div style={{width: '100%', display: showList ? 'block' : 'none', height: 'auto'}} key={i}>
                <div style={{width: '100%', display: 'block', height: 'auto'}}  onClick={async () => {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(e.answer, 'text/html');
                    var stringToHTML = doc.body;
                    this.setState({
                        showNoticeDetail: true,
                        showList : false,
                        popup_time: e.createdAt.substr(0, 10),
                        popup_title: e.title,
                        popup_content: e.content,
                        popup_answer: stringToHTML.innerHTML,
                    });
                    document.getElementById('div_answer').innerHTML = stringToHTML.innerHTML == null ? '' : stringToHTML.innerHTML;
                }}>
                    <div style={{display: 'block', paddingTop: 5, paddingBottom: 5, paddingRight: 15, paddingLeft: 15, borderBottom: 3,
                        borderBottomStyle:'solid', borderBottomColor: '#f7f7f7', cursor: 'pointer'}}>
                        <div style={{fontSize: 14, lineHeight: 2, color: '#a2a2a2', wordBreak: 'break-all'}}>{e.createdAt.substr(0, 10)}</div>
                        <div style={{fontSize: 16, lineHeight: 3, marginTop: 2, color: '#000', wordBreak: 'break-all'}}>{e.title}</div>
                    </div>
                </div>

            </div>
        )}
        <div style={{padding: 20, marginTop: 16, display: showNoticeDetail ? 'block' : 'none' }}>
            <div style={{fontSize: 14, color: '#a2a2a2', wordBreak: 'break-all'}}>{popup_time}</div>
            <div style={{fontSize: 16, marginTop: 5, color: '#000', wordBreak: 'break-all'}}>{popup_title}</div>
            <div style={{fontSize: 16, marginTop: 15, color: '#000', wordBreak: 'break-all'}}>{popup_content}</div>
            <div style={{fontSize: 16, marginTop: 25, color: '#000', wordBreak: 'break-all'}}>{popup_answer === '' || popup_answer === null ? '' : '관리자답변'}</div>
            <div id={'div_answer'} style={{fontSize: 16, marginTop: 5, color: '#000', wordBreak: 'break-all'}}/>
        </div>
        </div>
    }

    render() {
        const {lang_data, showNoticeDetail, tabValue} = this.state;
        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column'}}>
            <NavigationBar
                onBackClick={() => showNoticeDetail ? this.setState({
                    showNoticeDetail: false,
                    showList : true,
                    popup_time: '',
                    popup_title: '',
                    popup_content: '',
                }) : this.props.history.goBack()}
                lang_data={lang_data}/>
            <Tab
                selected={tabValue}
                tabs={[
                    { text: lang_data.contact_label, value: 'contact' },
                    { text: lang_data.contact_history_label, value: 'history' },
                ]}
                onTabClick={(value) => this.setState({ tabValue: value })}/>
            {tabValue === 'contact' ? this.renderForm() : this.renderHistory()}
        </div>
    }
}

export default Contact;
