import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";
import API from "./utils/api";

const PushSettingBox = styled.div`
  background: white;
  padding: 24px;
  border: none;

  .fullnoti {
    font-size: 14px;
    color: deeppink;
    margin-top: 20px;
    display: flex;
  }
`;

const NotificationArea = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 25px;
  text-align: justify;

`;

const SettingBox = styled.div`
  background: white;
  padding-top: 10px;

  img {
    margin-left: auto;
    height: 31px;
    
  }
  .big_button {
    object-fit: contain;
  }
  .value-wrap {
    display: flex;
    font-size: 14px;
    align-items: center;
  }
`;

const Items = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;

`;

export default class PushServiceSetting extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        isPush: false,
        btc_charge_push: false,
        donation_push: false,
        pending_occurrence_push: false,
        pending_donation_push: false,
        dorothy_push: false,
        contact_push: false,
        voting_push: false,
        voting_comment_push: false,
        voting_end_push: false,
        voting_select_push: false
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "push_setting";
        window.scrollTo(0, 0);
        const me = GlobalState.me;
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
            btc_charge_push: me.btc_charge_push == null || me.btc_charge_push === 1,
            donation_push: me.donation_push == null || me.donation_push === 1,
            pending_occurrence_push: me.pending_occurrence_push == null || me.pending_occurrence_push === 1,
            pending_donation_push: me.pending_donation_push == null || me.pending_donation_push === 1,
            dorothy_push: me.dorothy_push == null || me.dorothy_push === 1,
            contact_push: me.contact_push == null || me.contact_push === 1,
            voting_push: me.voting_push == null || me.voting_push === 1,
            voting_comment_push: me.voting_comment_push == null || me.voting_comment_push === 1,
            voting_end_push: me.voting_end_push == null || me.voting_end_push === 1,
            voting_select_push: me.voting_select_push == null || me.voting_select_push === 1
        }, () => {
            const {
                btc_charge_push, donation_push, pending_occurrence_push, pending_donation_push, dorothy_push,
                contact_push, voting_push, voting_comment_push, voting_end_push, voting_select_push
            } = this.state;
            this.setState({
                isPush: btc_charge_push && donation_push && pending_occurrence_push && pending_donation_push &&
                    dorothy_push && contact_push && voting_push &&
                    voting_comment_push && voting_end_push && voting_select_push
            })
        });
    }

    updatePush = async (type, check) => {
        await API.updateSetting(type, check ? 1 : 0);
    };

    checkAll() {
        const {
            btc_charge_push, donation_push, pending_occurrence_push, pending_donation_push, dorothy_push,
            contact_push, voting_push, voting_comment_push, voting_end_push, voting_select_push
        } = this.state;
        this.setState({
            isPush: btc_charge_push && donation_push && pending_occurrence_push && pending_donation_push &&
                dorothy_push && contact_push && voting_push &&
                voting_comment_push && voting_end_push && voting_select_push
        })
    }

    selectAll = async (check) => {
        await API.updateSetting(0, check ? 1 : 0);
        this.setState({
            isPush: check,
            btc_charge_push: check,
            donation_push: check,
            pending_occurrence_push: check,
            pending_donation_push: check,
            dorothy_push: check,
            contact_push: check,
            voting_push: check,
            voting_comment_push: check,
            voting_end_push: check,
            voting_select_push: check
        })
    };

    render() {

        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={this.state.lang_data}
            />
            <PushSettingBox>
                <div style={{fontSize:20}}>{this.state.lang_data.app_push_setting_label}</div>
                <NotificationArea>
                    {this.state.lang_data.app_push_noti_label}
                </NotificationArea>
                <SettingBox>
                    <div className="value-wrap" style={{color:"deeppink"}}>{this.state.lang_data.receive_full_noti_label}
                        <img className={'big_button'}
                             src={this.state.isPush ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                             onClick={() => {
                                 this.selectAll(!this.state.isPush).then(r => {})
                            }}/>
                    </div>
                </SettingBox>
                <div style={{borderTop: 'solid', borderWidth: "thin", marginTop:20}}/>
                <Items>{this.state.lang_data.charge_label}</Items>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.btc_recharge_noti_label}
                        <img
                            src={this.state.btc_charge_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.btc_charge_push;
                                this.updatePush(3, val).then(r => this.setState({btc_charge_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.donation_noti_label}
                        <img
                            src={this.state.donation_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.donation_push;
                                this.updatePush(4, val).then(r => this.setState({donation_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <Items>{this.state.lang_data.pending_label}</Items>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.pending_occur_noti_label}
                        <img
                            src={this.state.pending_occurrence_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.pending_occurrence_push;
                                this.updatePush(5, val).then(r => this.setState({pending_occurrence_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.pending_payment_label}
                        <img
                            src={this.state.pending_donation_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.pending_donation_push;
                                this.updatePush(6, val).then(r => this.setState({pending_donation_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <Items>{this.state.lang_data.dorothy_label}</Items>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.dorothy_lottery_schedule_label}
                        <img
                            src={this.state.dorothy_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.dorothy_push;
                                this.updatePush(7, val).then(r => this.setState({dorothy_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <Items>{this.state.lang_data.contact_label}</Items>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.answers_my_questions_label}
                        <img
                            src={this.state.contact_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.contact_push;
                                this.updatePush(8, val).then(r => this.setState({contact_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <Items>{this.state.lang_data.donation_recommendation_label}</Items>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.my_voting_noti_label}
                        <img
                            src={this.state.voting_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.voting_push;
                                this.updatePush(9, val).then(r => this.setState({voting_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.comments_my_post_label}
                        <img
                            src={this.state.voting_comment_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.voting_comment_push;
                                this.updatePush(10, val).then(r => this.setState({voting_comment_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.end_voting_my_post_label}
                        <img
                            src={this.state.voting_end_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.voting_end_push;
                                this.updatePush(11, val).then(r => this.setState({voting_end_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <div className="value-wrap">{this.state.lang_data.selection_my_article_label}
                        <img
                            src={this.state.voting_select_push ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const val = !this.state.voting_select_push;
                                this.updatePush(12, val).then(r => this.setState({voting_select_push: val}, () => {
                                    this.checkAll()
                                }));
                            }}/>
                    </div>
                </SettingBox>

            </PushSettingBox>

        </>
    }
}

