import React from 'react';
import styled from 'styled-components';

const BackgroundShadow = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;

    .layer-pop {
        width: 320px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        .caution {
            background: white;
            padding: 20px 10px 20px 10px;

            h3 {
                font-size: 21px;
                color: #00b5c3;
                font-weight: 700;
                padding: 15px 0px 10px;
                line-height: 1.5;
                letter-spacing: 0px;
            }
           
            .comment {
                font-size: 14px;
                color: #666a80;
                line-height: 1.5;
                padding: 1px 0px;
                min-height: 200px;
                max-height: 450px;
                overflow-y: auto;
                margin: 0;
            }
        }

        .buttons {
            color: white;
            height: 52px;
            font-size: 18px;
            line-height: 52px;
            letter-spacing: 0.5px;
            display: flex;
            .ok-button {
                flex: 1;
                overflow: hidden;
                font-weight: 500;
                background: #00b5c3;
                cursor: pointer;
            }
        }
    }
`;

export function NoticeDetail ({
    enabled,
    title,
    description,
    buttonTitle='OK',
    onButtonClick,
}) {
    if (!enabled) {
        return null;
    }

    return (
        <BackgroundShadow>
            <div className="layer-pop">
                <div className="popupCont">
                    <div className="caution">
                        {
                              <h3>{title}</h3>
                        }
                        <div className="comment" id={'div_description'}>
                            {/*{description}*/}
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="ok-button" id={'btn_dlg_close'} onClick={() => onButtonClick()}>
                            {buttonTitle}
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundShadow>
    );
}
