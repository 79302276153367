import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {Button} from './styled-components';
import {getLangCode, getLangData, readLangData} from "./utils/LangState";
import {API, API_PATH} from "./utils/api";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const Title = styled.h3`
    margin: 21px;
    color: black;
`;

const GuideDiv = styled.div`
    border: 1px solid rgb(209, 209, 219);
    font-size: 14px;
    padding: 16px;
    margin: 21px;
    word-break: break-all;
    white-space: break-spaces;
    font-family: 'Noto Sans' sans-serif;
    color: #666A80;
`;

const ContinueButton = styled(Button)`
  border-radius: 0;
`;

const TermImage = styled.img`
    margin: 16px;
    width: calc(100% - 32px);
    height: auto;
`;

export default class Policy extends React.Component {

    type = parseInt(this.props.match.params.type);
    login = parseInt(this.props.match.params.login);

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        terms_content: '',
        terms_file: '',
        private_content: '',
        private_file: '',
        is_login: this.login === 1
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "policy";
        var language = document.getElementById('app_lang').value;
        let lang_code = getLangCode();
        if (lang_code != "en") {
            language = lang_code;
            document.getElementById('app_lang').value = lang_code;
        }
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
        });

        const term_result = await API.getTermData(1);
        if (term_result.status === 'success') {
            document.getElementById('div_service_term').innerHTML = term_result.content;
            this.setState({
                terms_content: term_result.content,
                terms_file: term_result.attach
            });
        }

        const private_result = await API.getTermData(2);
        if (private_result.status === 'success') {
            var contents = private_result.content;
            contents = contents.replace('<span', '<span style="display:none"');
            document.getElementById('div_policyB').innerHTML = contents;
            this.setState({
                private_content: private_result.content,
                private_file: private_result.attach
            });
        }

        if (this.type === 1) {
            window.scrollTo(0, document.getElementById('div_service_title').offsetTop - 80);
        }
    }

    render() {
        const {lang_data} = this.state;
        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={this.state.lang_data}
                hideMenu={!this.state.is_login}
            />
            <GuideTitle>
                TERMS OF USE / PRIVACY POLICY
            </GuideTitle>
            <Title>
                1. TERMS OF USE
            </Title>
            {
                this.state.terms_file !== '' &&
                <TermImage className={'term_file'} src={API_PATH + "/" + this.state.terms_file}/>
            }
            <GuideDiv id={"div_service_term"}/>
            <Title id={"div_service_title"}>
                2. PRIVACY POLICY
            </Title>
            {
                this.state.private_file !== '' &&
                <TermImage className={'term_file'} src={API_PATH + "/" + this.state.private_file}/>
            }
            <GuideDiv style={this.state.is_login ? {} : {marginBottom: 80}} id={"div_policyB"}/>
            {
                !this.state.is_login &&
                <ContinueButton
                    style={{
                        bottom: '0',
                        left: '0',
                        position: 'fixed',
                        zIndex: '1000',
                    }}
                    onClick={async () => {
                        let sign = GlobalState.sign;
                        if (this.type === 0) {
                            sign.terms_of_use = true;
                        } else {
                            sign.privacy_policy = true;
                        }
                        GlobalState.sign = sign;
                        props.history.goBack();
                }}>{lang_data.understand_agree_all_label}</ContinueButton>
            }
        </>
    }
}

