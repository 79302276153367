import React from 'react';
import styled from 'styled-components';

const BackgroundShadow = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;

    .layer-pop {
        width: 300px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        .b-close {
            position: absolute;
            width: 80px;
            height: 80px;
            background: #fff;
            box-sizing: border-box;
            border-radius: 50%;
            padding: 10px;
            left: auto;
            top: 0px;
            margin-top: -35px;
            right: 50%;
            transform: translateX(50%);
        }

        .caution {
            background: white;
            padding: 40px 20px 22px;

            h3 {
                font-size: 21px;
                color: #00b5c3;
                font-weight: 700;
                padding: 15px 0px 10px;
                line-height: 1.5;
                letter-spacing: 0px;
            }
            
            h4 {
                font-size: 21px;
                color: #d0274e;
                font-weight: 700;
                padding: 15px 0px 10px;
                line-height: 1.5;
                letter-spacing: 0px;
            }

            .comment {
                font-size: 14px;
                color: #666a80;
                line-height: 1.5;
                padding: 1px 0px;
                margin: 0;
            }
        }

        .buttons {
            color: white;
            height: 52px;
            font-size: 18px;
            line-height: 52px;
            letter-spacing: 0.5px;
            display: flex;

            .sub-button {
                flex: 1;
                overflow: hidden;
                font-weight: 500;
                background: rgb(156, 159, 175);
                cursor: pointer;
            }

            .ok-button {
                flex: 1;
                overflow: hidden;
                font-weight: 500;
                background: #00b5c3;
                cursor: pointer;
            }
        }
    }
`;

export function Popup({
    isSuccess,
    title,
    description,
    enabled,
    buttonTitle='OK',
    subButtonTitle=null,
    onButtonClick,
    onSubButtonClick,
}) {
    if (!enabled) {
        return null;
    }

    title = title == null ? (isSuccess ? 'Success' : 'Failure' ) : title;

    return (
        <BackgroundShadow>
            <div className="layer-pop">
                <div className="b-close">
                    {
                        isSuccess ?
                            <img src="/static/img/check_green.png" width="60" />
                            : <img src="/static/img/x_red.png" width="60" />
                    }
                </div>
                <div className="popupCont">
                    <div className="caution">
                        {
                            isSuccess ?
                                <h3>{title}</h3>
                                : <h4>{title}</h4>
                        }
                        <p className="comment">
                            {description}
                        </p>
                    </div>
                    <div className="buttons">
                        {
                            subButtonTitle != null ?
                                <div className="sub-button" onClick={() => onSubButtonClick()}>
                                    {subButtonTitle}
                                </div>
                                : null
                        }
                        <div className="ok-button" id={'btn_dlg_close'} onClick={() => onButtonClick()}>
                            {buttonTitle}
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundShadow>
    );
}
