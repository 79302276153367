import React from 'react';
import NavigationBar from './components/NavigationBar';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {NoticeDetail} from "./components/NoticeDetail";
import GlobalState from "./mobx/GlobalState";
import {Popup} from "./components/Popup";
import {Button, Dollars, DollarsDorothy} from "./styled-components";
import styled from "styled-components";

const NextLevelWrapper = styled.div`
    border: 1px solid #D1D1DB;
    padding: 20px;
    border-radius: 12px;
    margin-top: 50px;
    margin-bottom: 100px;
    text-align: center;
    .title {
        font-size: 14px;
        color: #8BC7FF;
        font-weight: normal;
        margin: 0;
        margin-bottom: 8px;
    }

    .description {
        font-size: 12px;
        color: #666A80;
    }
`;

const NextLevelPrice = styled.div`
    color: #00B5C3;
    border-bottom: 1px solid #D1D1DB;
    padding-bottom: 12px;
    margin-bottom: 12px;
`;

class Notification extends React.PureComponent {
    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        showWithdrawalDlg : false,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "withdrawal";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    async componentWillUnmount() {
        document.getElementById('app_lang').value = this.state.language;
    }

    render() {
        const {lang_data, showWithdrawalDlg} = this.state;
        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <div style={{
                backgroundColor: '#E7E7EF',
                color: 'black',
                fontWeight: 'bold',
                lineHeight: '60px',
                fontSize: 18,
                paddingLeft: 20,
            }}>
                {lang_data.str_member_withdraw}
            </div>
            <div style={{ margin: 21 }}>
                <NextLevelWrapper>
                    <h5 className="title">{lang_data.str_withdraw_noti}</h5>
                    <div className="description">
                        {lang_data.str_withdraw_noti_content}
                    </div>
                </NextLevelWrapper>
                <Button
                    style={{display:'relative', bottom: 50}}
                    className="green"
                    onClick={async () => {
                        this.setState({
                            showWithdrawalDlg: true,
                        });
                    }}>{lang_data.str_withdrawal}</Button>
            </div>
            <Popup
                enabled={showWithdrawalDlg}
                isSuccess={true}
                title=''
                description={lang_data.str_withdraw_ask}
                buttonTitle={lang_data.ok_label}
                subButtonTitle={lang_data.no_label}
                onButtonClick={async () => {
                    const withdrawalResponse = await API.withdrawal();
                    if (withdrawalResponse.status === 'success') {
                        this.props.history.push('/signin');
                        this.setState({
                            showWithdrawalDlg: false,
                        });
                    } else {
                        alert("네트웍 오류");
                    }
                }}
                onSubButtonClick={() => {
                    this.props.history.push('/dashboard');
                }}
            />
        </div>
    }
}

export default Notification;
