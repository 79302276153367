import React from 'react';
import API from './utils/api';
import NavigationBar from './components/NavigationBar';
import {Button, Input} from './styled-components';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const Title = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
    img {
        vertical-align: middle;
    }
`;

const Wrap = styled.div`
    margin: 24px;
    ${Input} {
        margin-bottom: 24px;
        box-shadow: none;
        border: 1px solid #D1D1DB;
    }
`;

const ChangeButton = styled(Button)`
    &:disabled {
        background: #D1D1DB;
    }
`;

class ChangeEth extends React.PureComponent {
    state = {
        ethAddress: '',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "change_etc";
        // const browser = await SignIn.launch();
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {
        const user = GlobalState.me;
        const {ethAddress, lang_data} = this.state;

        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <Title>
                <img src="/static/img/icon_account_dark.png" width="22"/>{lang_data.register_eth_address_label}
            </Title>
            <Wrap>
                <h3 style={{ marginBottom: 11 }}>ETH Account</h3>
                <div>
                    <span style={{ fontSize: 12 }}>{user.ethAddress == null || user.ethAddress ==='' ? 'None' : user.ethAddress}</span>
                </div>
                <Input type="text" placeholder="New Address" value={ethAddress}
                       onChange={e => this.setState({ ethAddress: e.currentTarget.value })}
                       style={{marginTop: 30}}/>
                <ChangeButton className="green" disabled={ethAddress.length === 0} onClick={async () => {
                    if (await API.changeEth(ethAddress)) {
                        GlobalState.me.ethAddress = ethAddress;
                        this.setState({ethAddress: ''});
                    }
                }}>{lang_data.change_label}</ChangeButton>
            </Wrap>
        </div>
    }
}

export default ChangeEth;
