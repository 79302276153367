import React from 'react';
import styled from 'styled-components';
import Tab from './components/Tab';
import NavigationBar from './components/NavigationBar';
import History from './History';
import {Button, Input} from './styled-components';
import TxPassword from './components/TxPassword';
import API from './utils/api';
import {Popup} from "./components/Popup";
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const AvailableBalanceWrapper = styled.div`
    line-height: 22px;
    height: 22px;
    & > span {
        display: inline-block;
        vertical-align: middle;
    }
    .btc {
        float: right;
        font-weight: bold;
    }
`;

const Icon = styled.span`
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: cover;
    background-position: center;
    margin-right: 12px;
`;

const ConfirmButton = styled(Button)`
    &:disabled {
        background: #D1D1DB;
    }
`;

class Withdraw extends React.PureComponent {
    state = {
        tabValue: 'withdraw',
        transactions: [],
        showTxPassword: false,
        btcAddressCheck: false,
        address: '',
        amount: '',
        showDontationSuccessPopup: false,
        showDontationFailPopup: false,
        showDontationPwdFailPopup: false,
        password: '',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        user: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "withdraw";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        const transactions = await API.withdrawTransactions();
        if (transactions.status === 'success')
            this.setState({transactions: transactions.data});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({user: me.user});
            GlobalState.me = me.user;
        }
    }

    async componentWillUnmount() {
        const me = await API.me();
        if (me.status === 'success') {
            GlobalState.me = me.user;
        }
    }

    async withdraw(passwd) {
        const {user, amount, address} = this.state;

        const amountNumber = parseFloat(amount);
        if (address !== '' && amountNumber < user.btc && !isNaN(amountNumber)) {
            // try {
            const result = await API.withdraw(address, amount, passwd, user.userId);
            if (result.status === 'fail') {
                this.setState({showDontationFailPopup: true})
            } else if (result.status === 'pwd fail') {
                this.setState({showDontationPwdFailPopup: true})
            } else {
                this.setState({showDontationSuccessPopup: true})
            }
            // } catch(e) {
            //     //alert("Server error");
            //     this.setState({showDontationFailPopup: true})
            // }
        } else {
            alert("Please check address or amount");
        }
    }

    renderWithdrawTab() {
        const {user, amount, address, lang_data, btcAddressCheck} = this.state;

        const btc = user.btc.toFixed(6);

        return <div style={{margin: 21}}>
            <AvailableBalanceWrapper>
                <Icon style={{backgroundImage: 'url(/static/img/BTC.png)'}}/>
                <span style={{fontSize: 12}}>{lang_data.available_balance_label}</span>
                <span className="btc">{btc} BTC</span>
            </AvailableBalanceWrapper>
            <hr style={{margin: '21px 0'}}/>
            <h3 style={{marginBottom: 24}}>Address</h3>
            <Input
                className="no-shadow"
                style={{marginBottom: 24, paddingRight: 95}}
                value={address}
                onChange={(e) => this.setState({address: e.currentTarget.value, btcAddressCheck: false})}/>
            <ConfirmButton className="green" disabled={address.length === 0} style={{
                position: 'absolute',
                top: 0,
                right: 21,
                bottom: 24,
                width: 90,
                height: 52,
                marginTop: 258,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }} onClick={async () => {
                let check = await API.checkAddress(address);
                if (check.status === 'success') {
                    this.setState({btcAddressCheck: true});
                    alert("The BTC address is valid.");
                } else {
                    this.setState({address: '', btcAddressCheck: false});
                    alert("Please enter the correct BTC address.");
                }
                // if (user.btcAddress === this.state.address) {
                //     this.setState({btcAddressCheck: true});
                //     alert("The bit address is valid.");
                // } else {
                //     this.setState({address: '', btcAddressCheck: false});
                //     alert("Please enter the correct bit address.");
                // }
            }}>{lang_data.check_label}</ConfirmButton>
            <h3 style={{marginBottom: 24}}>{lang_data.amount_label}</h3>
            <Input
                type="number"
                step={0.001}
                className="no-shadow"
                style={{marginBottom: 24}}
                value={amount}
                onChange={(e) => this.setState({amount: e.currentTarget.value})}/>
            <Button className="green" onClick={
                () => {
                    if (address === '') {
                        alert('Please enter the address');
                        return;
                    }

                    if (!btcAddressCheck) {
                        alert('Please check the address.');
                        return;
                    }

                    if (amount === '') {
                        alert('Please enter the amount');
                        return;
                    }

                    if (amount < 0.002) {
                        alert('Minimum withdraw BTC is 0.002 BTC');
                        return;
                    }
                    this.setState({showTxPassword: true})
                }
            }>{lang_data.withdraw_label}</Button>
            <div style={{marginTop: 24, fontSize: 14}}>
                <img src="/static/img/info.png" width="20" style={{verticalAlign: 'middle', marginRight: 12}}/>
                {lang_data.withdrawal_fee_charged_label}
            </div>
            <div style={{marginTop: 4, fontSize: 14}}>
                <img src="/static/img/info.png" width="20" style={{verticalAlign: 'middle', marginRight: 12}}/>
                {lang_data.withdrawal_fee_charged_label_1}
            </div>
            <div style={{marginTop: 4, fontSize: 14}}>
                <img src="/static/img/info.png" width="20" style={{verticalAlign: 'middle', marginRight: 12}}/>
                {lang_data.withdrawal_fee_charged_label_2}
            </div>
        </div>
    }

    render() {
        const {showTxPassword, lang_data, tabValue, transactions, showDontationSuccessPopup, showDontationFailPopup, showDontationPwdFailPopup} = this.state;
        if (showTxPassword) {
            return <TxPassword
                onBackClick={() => this.setState({showTxPassword: false})}
                onSubmit={async (passwd) => {
                    this.withdraw(passwd);
                    this.setState({showTxPassword: false})
                }}/>
        }
        return <>
            <NavigationBar
                onBackClick={() => {
                    this.props.history.goBack();
                }}
                title={lang_data.withdraw_label}
                lang_data={lang_data}/>
            <Tab
                selected={tabValue}
                tabs={[
                    {text: lang_data.withdraw_label, value: 'withdraw'},
                    {text: lang_data.withdraw_history_label, value: 'history'},
                ]}
                onTabClick={(value) => this.setState({tabValue: value})}
            />
            {tabValue === 'withdraw' ? this.renderWithdrawTab() : <History history={transactions} type={'withdraw'}/>}
            <Popup
                enabled={showDontationSuccessPopup}
                isSuccess={true}
                title={lang_data.welcome_title}
                description={lang_data.withdraw_successful_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={async () => {
                    this.setState({
                        showDontationSuccessPopup: false,
                    });
                    const me = await API.me();
                    if (me.status === 'success') {
                        this.setState({user: me.user});
                        GlobalState.me = me.user;
                    }
                    this.props.history.push('/dashboard');
                }}/>
            <Popup
                enabled={showDontationFailPopup}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.check_current_balance_label}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({
                        showDontationFailPopup: false,
                    });
                }}/>
            <Popup
                enabled={showDontationPwdFailPopup}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.check_current_password}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({
                        showDontationPwdFailPopup: false,
                    });
                }}/>
        </>
    }
}

export default Withdraw;
