import React from 'react';
// import styled from 'styled-components';

// const YelloCircle = styled.circle`
//     @keyframes circle-progress-animation {

//     }
//     animation:
// `;

export default class Donut extends React.PureComponent {
    state = {
        yellow: 0,
    }

    progressTimer() {
        const newYellow = this.state.yellow + 0.5;
        const limit = parseInt(100 * (this.props.guage || 0));
        if ( newYellow > limit) {
            return;
        }

        this.setState({
            yellow: newYellow,
        });
        setTimeout(this.progressTimer.bind(this), 16);
    }

    componentDidMount() {
        this.progressTimer();
    }

    render() {
        const { onClick, style, level, description } = this.props;
        const yellow = this.state.yellow;
        const blue = 100 - this.state.yellow;

        return <div style={{
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            userSelect: 'none',
            ...style,
        }}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" onClick={() => onClick && onClick()}>
                <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#62ec91" strokeWidth="4"></circle>
                <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#ff815a" strokeWidth="4"
                    strokeDasharray={`${yellow} ${blue}`}
                    strokeDashoffset="25"></circle>
            </svg>
            <div style={{ fontSize: 36, position: 'absolute', textAlign: 'center', color: '#0085ff' }}>
                <strong>{level}</strong>
                <div style={{ fontSize: 10, color: '#666A80' }}>{description}</div>
            </div>
        </div>
    }
}

