import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import GlobalState from "../mobx/GlobalState";
import Common from "../utils/Common";


const NavigationBarWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 62px;
  z-index: 99;
  background-color: #E974A9;

  .back:after {
    content: "";
    position: absolute;
    top: 0;
    left: 16px;
    font-size: 36px;
    line-height: 62px;
    padding: 0 8px;
    color: white;
    cursor: pointer;
    background-image: url(/static/img/arr_back.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 62px;
    box-sizing: border-box;
  }

  .title {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    line-height: 62px;
    padding-left: 45px;
    padding-right: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu {
    background-image: url(/static/img/menu.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 18px;
    right: 16px;
    height: 26px;
    width: 26px;
    color: white;
    box-sizing: border-box;
  }
`;

const MenuWrap = styled.div`
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
`;

const Menu = styled.div`
  background: linear-gradient(#E26FA4, #F8CB9B);
  width: 256px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .menu {
    display: grid;
    grid-template-rows: repeat(4, 95px);
    grid-template-columns: repeat(2, 95px);
    grid-gap: 14px;

    a {
      display: block;
      text-decoration: none;
    }

    .menu-button {
      display: flex;
      height: 100%;
      flex-direction: column;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0.15);
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 28px;
        height: 28px;
      }

      h6 {
        color: white;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
`;

const Title = styled.div`
  background: url(/static/img/main_title.png);
  width: 146px;
  height: 126px;
  background-size: cover;
  margin-bottom: 24px;
`;

const VotingDonation = styled.div`
  font-size: 12px;
  color: white;
  background-color: #FF4C89;
  border: #FF4C89 solid;
  border-radius: 12px;
  margin-top: 14px;
  display: flex;
  width: 180px;
  padding: 5px 10px;
  align-items: center;

  img {
    width: 28px;
  }
`;

const TermLink = styled.a`
  font-size: 12px;
  color: white;
  display: block;
  margin-top: 20px;
  text-decoration: underline;
`;

function isIOS() {
    var userAgent = navigator.userAgent.toLowerCase();

    var isIOS = ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1) || (userAgent.search("apple") > -1) || (userAgent.search("mac") > -1));
    if (isIOS == true && userAgent.search("safari") > -1) {
        return false;
    }

    return isIOS;
}
function MenuComponent({onMenuBackClick, lang_data}) {
    return <MenuWrap onClick={onMenuBackClick} id={'navigationBar'}>
        <Menu onClick={(e) => e.stopPropagation()}>
            <MenuContent>
                <Title/>
                <div className="menu">
                    <Link to="/dashboard">
                        <div className="menu-button">
                            <img src="/static/img/home.png"/>
                            <h6>MAIN</h6>
                        </div>
                    </Link>
                    <Link to="/my-code" onClick={() => {GlobalState.sel_branch = 0;}}>
                        <div className="menu-button">
                            <img src="/static/img/dashboard.png"/>
                            <h6>DASHBOARD</h6>
                        </div>
                    </Link>
                    <Link to="/charge">
                        <div className="menu-button">
                            <img src="/static/img/btc_white.png"/>
                            <h6>CHARGE</h6>
                        </div>
                    </Link>
                    <Link to="/withdraw">
                        <div className="menu-button">
                            <img src="/static/img/usd_white.png"/>
                            <h6>WITHDRAW</h6>
                        </div>
                    </Link>
                    <Link to="/dorothy">
                        <div className="menu-button">
                            <img src="/static/img/dorothy_white.png"/>
                            <h6>DOROTHY</h6>
                        </div>
                    </Link>
                    {/*<Link onClick={() => {*/}
                    {/*    const support_url = 'http://app.everythanks.com/supports.html?userId=' + GlobalState.me.userId;*/}
                    {/*    const platform = Common.checkMobile();*/}
                    {/*    if (platform === "android") {*/}
                    {/*        window.androidBridge.bridge_open_url(support_url);*/}
                    {/*    } else { // if (platform === "ios")*/}
                    {/*        window.webkit.messageHandlers.bridge_open_url.postMessage({url: support_url});*/}
                    {/*    }*/}
                    {/*}}>*/}
                    <Link to="/support"
                          style={{
                              display: isIOS() ? '' : ''
                          }}>
                        <div className="menu-button">
                            <img src="/static/img/support.png"/>
                            <h6>SUPPORT</h6>
                        </div>
                    </Link>
                    <Link to="/user-guide">
                        <div className="menu-button">
                            <img src="/static/img/user_guide.png"/>
                            <h6>USER GUIDE</h6>
                        </div>
                    </Link>
                    <Link to="/settings">
                        <div className="menu-button">
                            <img src="/static/img/settings.png"/>
                            <h6>SETTINGS</h6>
                        </div>
                    </Link>
                </div>
                <Link to="/voting" style={{textDecoration:"none"}}>
                    <VotingDonation>Voting for Donation
                        Recommendation
                        <img src='/static/img/icon_vote.png'/>
                    </VotingDonation>
                </Link>
                <TermLink href="#/policy/0/1">
                    TERMS OF USE / PRIVACY POLICY
                </TermLink>
            </MenuContent>
        </Menu>
    </MenuWrap>
}


function NavigationBar({onBackClick, hideBack, hideMenu, title = 'EVERY THANKS', lang_data, bgColor='#E974A9'}) {
    const [isMenuOpen, setMenuOpen] = React.useState(false);

    return <>
        <NavigationBarWrapper style={{backgroundColor:bgColor}}>
            {hideBack ? null :
                <div className="back" onClick={() => onBackClick && onBackClick()}/>
            }
            <div className="title">{title}</div>
            {hideMenu ? null :
                <div className="menu"
                     onClick={() => setMenuOpen(true)}
                />
            }
        </NavigationBarWrapper>
        {isMenuOpen ?
            <MenuComponent
                onMenuBackClick={() => setMenuOpen(false)}
            />
            : null
        }
    </>;
}

export default NavigationBar;
