import 'moment/min/locales'

export default {
    formatNumber(num) {
        if (num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        } else {
            return num
        }
    },

    formatPhone(num) {
        if (num) {
            return num.toString().replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
            return num
        }
    },
    checkMobile() {
        var varUA = navigator.userAgent.toLowerCase();

        if ( varUA.indexOf('android') > -1) {
            //Android
            return "android";
        } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            //IOS
            return "ios";
        } else {
            //iOS, Android Etc
            return "other";
        }
    },
    find(arr, obj) {
        const res = [];
        arr.forEach(o => {
            let match = false;
            Object.keys(obj).forEach(i => {
                if (obj[i] == o[i]) {
                    match = true;
                }
            });
            if (match) {
                res.push(o);
            }
        });
        return res;
    }
}
