import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const ContactWrap = styled.div`
    select {
        height: 50px;
        width: 100%;
        margin-bottom: 24px;
        font-size: 16px;
        padding: 0 10px;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #D1D1DB;
    }
`;

//Purcharse 4단계화면
export default class ChargeCardAddress extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        address: '',
        address_detail: '',
        city: '',
        postal_code: '',
        nation: 'Korea, Republic of',
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_card_address";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user
        }
    }

    render() {

        const props = this.props;
        const user = this.state.me;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Enter Card Billing Address
            </GuideTitle>
            <div style={{margin: 21}}>
            <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                * Address
            </div>
            <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                   onChange={(e) => this.setState({address: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    Detailed Address (option)
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({address_detail: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * City
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({city: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Postal code
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({postal_code: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Nation (select)
                </div>
                <ContactWrap>
                <select onChange={(e) => this.setState({nation: e.currentTarget.value})}>
                    <option value={'Korea, Republic of'}>Korea, Republic of</option>
                </select>
                </ContactWrap>

                <Button className="green" onClick={
                    async () => {
                        if (this.state.address === '' || this.state.city === '' || this.state.postal_code === '') {
                            alert("Incorrect input info, please check.");
                            return;
                        }

                        let result = await API.cardAddress(user.userId, this.state.address, this.state.address_detail, this.state.city, this.state.postal_code, this.state.nation);
                        if (result.status === 'success') {
                            window.location.href = ('/#/Charge/ChargeCardDetail');
                        }
                    }
                } > Continue </Button>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
            </div>
        </>
    }
}

