import React from 'react';
import NavigationBar from './components/NavigationBar';
import API from './utils/api';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";
import {ArticlePopup} from "./components/ArticlePopup";

const SettingsWrap = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const SettingsList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background: linear-gradient(#E974A9, #C1ACD2);
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        flex: 1;
        background-color: #E7E7EF26;
        font-size: 20px;
        padding: 0 34px;
        .settings-icon {
            width: 27px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            &.profile {
                background-image: url(/static/img/profile.png);
            }
            &.notification {
                background-image: url(/static/img/notification.png);
            }
            &.contact {
                background-image: url(/static/img/contact.png);
            }
            &.language {
                background-image: url(/static/img/language.png);
            }
            &.logout {
                background-image: url(/static/img/logout.png);
            }
        }
    }

    .row:nth-child(2) {
        background-color: #9C9FB126;
    }
    .row:nth-child(3) {
        background-color: #666A8026;
    }
    .row:nth-child(4) {
        background-color: #2A2D4226;
    }
    .row:nth-child(5) {
        background-color: #00000026;
    }

    .row:hover {
        background-color: rgb(207, 98, 149);
    }
`;


class Settings extends React.PureComponent {
    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        logoutPopup: false,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "setting";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    async onClickLogout() {
        await API.logout();
    }

    render() {
        const props = this.props;
        const {logoutPopup, lang_data} = this.state;
        return <SettingsWrap>
            <ArticlePopup
                title={"Delete"}
                enabled={logoutPopup}
                description={lang_data.popup_logout_ask_label}
                buttonTitle={lang_data.yes_label}
                buttonTitle2={lang_data.no_label}
                onButtonClick={(confirm) => {
                    this.setState({logoutPopup: false});
                    if (confirm) {
                        this.onClickLogout().then(r => {
                            const this_id = '';
                            const this_passwd = '';
                            const this_isAutoLogin = '';
                            GlobalState.me = null;
                            localStorage.setItem('login', JSON.stringify({
                                this_id, this_passwd, this_isAutoLogin
                            }));
                            props.history.push('/signin');
                        })
                    }
                }}/>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={lang_data.setting_label}
                lang_data={lang_data}/>
            <SettingsList>
                <div className="row" onClick={() => props.history.push('/settings/profile')}>
                    <span>{lang_data.profile_label}</span>
                    <span className="settings-icon profile"/>
                </div>
                <div className="row" onClick={() => props.history.push('/settings/notification')}>
                    <span>{lang_data.notification_label}</span>
                    <span className="settings-icon notification"/>
                </div>
                <div className="row" onClick={() => props.history.push('/settings/contact')}>
                    <span>{lang_data.contact_label}</span>
                    <span className="settings-icon contact"/>
                </div>
                <div className="row" onClick={() => props.history.push('/settings/language')}>
                    <span>{lang_data.language_label}</span>
                    <span className="settings-icon language"/>
                </div>
                <div className="row" onClick={() => this.setState({logoutPopup: true})}>
                    <span>{lang_data.logout_label}</span>
                    <span className="settings-icon logout"/>
                </div>
            </SettingsList>
        </SettingsWrap>
    }
}

export default Settings;
