import React from 'react';
import Common from "./Common";
const state = { data: null, lang: 'en' };

export function setLang(lang) {
    state.code = lang;
    document.getElementById('app_lang').value = lang;
}
export function getLangData () {
    return state.data;
}

export function getLangCode () {
    return state.code;
}

export async function readLangData(code) {
    state.code = code;
    // var xml = require("../lang/lang_en.xml");
    // var xml2js = require('xml2js');
    // var parser = new xml2js.Parser(xml);
    // console.log(parser);
    if (code === "kr")
        setLangData(ko_data);
    else if (code === "zh-hk")
        setLangData(zh_data);
    else if (code === "jp")
        setLangData(jp_data);
    else
        setLangData(en_data);
}

export function setLangData(language) {
    state.data = {
        ...language,
    };
}

export function setLangCode(code) {
    state.code = code;
    const platform = Common.checkMobile();
    if (platform === "android") {
        window.androidBridge.bridge_set_language(code);
    } else { //  if (platform === "ios")
        window.webkit.messageHandlers.bridge_set_language.postMessage({code: code});
    }
}

const en_data = {
    "id_hint" : "ID",
    "password_label" : "PASSWORD",
    "kor_label" : "KOR",
    "eng_label" : "ENG",
    "chn_label" : "CHN",
    "jpn_label" : "JPN",
    "password_hint" : "Password",
    "email_hint" : "Email",
    "code_hint" : "Code",
    "signin_label" : "SIGN IN",
    "signup_label" : "SIGN UP",
    "find_password_label" : "Find Password",
    "join_member_label" : "Join Member",
    "find_id_label" : "FIND ID",
    "send_email_label" : "SEND E-MAIL",
    "hello_label" : "HELLO!",
    "welcome_title" : "Welcome!",
    "welcome_des_label" : "Your account has been created.!",
    "btn_ok_label" : "OK",
    "id_available_label" : "ID available.",
    "confirm_label" : "CONFIRM",
    "id_already_use_label" : "It already used user id.",
    "password_error_label" : "The password must be a combination of 8 or more digits and 15 or less\n&lt;English + Special Character + Number&gt;.",
    "confirm_password_hint" : "Confirm Password",
    "send_label" : "SEND",
    "email_verify_label" : "Your email has been verified.",
    "code_correct_label" : "Please enter the correct code.",
    "reference_hint" : "Reference",
    "reference_error_label" : "Please make sure your reference.",
    "transaction_hint" : "Transaction Password 6 num",
    "confirm_transaction_hint" : "Confirm Transaction Password",
    "confirm_password_error_label" : "Confirm password is not match to your password.",
    "confirm_password_equal_error_label" : "Confirm transaction password is not match to your transaction password.",
    "fail_signup_label" : "failed to sign up",
    "korean_label" : "Korean",
    "english_label" : "English",
    "chinese_label" : "Chinese",
    "japanese_label" : "Japanese",
    "select_one_label" : "Please select one",
    "select_your_language_label" : "Select Your Language",
    "check_address_amount_label" : "Please check address or amount",
    "bit_address_valid_label" : "The BTC address is valid.",
    "enter_bit_address_label" : "Please enter the correct BTC address.",
    "available_balance_label" : "AVAILABLE BALANCE",
    "address_label" : "Address",
    "check_label" : "CHECK",
    "amount_label" : "Amount",
    "withdraw_label" : "WITHDRAW",
    "enter_address_label" : "Please enter the address.",
    "check_address_label" : "Please check the address.",
    "enter_amount_label" : "Please enter the amount.",
    "minimum_withdrawal_quantity_label" : "The minimum withdrawal quantity is 0.0001 BTC.",
    "withdrawal_fee_charged_label" : "Minimum withdraw BTC is 0.002 BTC.",
    "withdrawal_fee_charged_label_1" : "2% of withdrawal fee is charged.",
    "withdrawal_fee_charged_label_2" : "The minimum withdrawal fee is 0.0005BTC",
    "support_fee_charged_label" : "10% of support fee is charged.",
    "contact_history_label" : "HISTORY",
    "charge_history_label" : "HISTORY",
    "withdraw_history_label" : "HISTORY",
    "support_history_label" : "HISTORY",
    "history_label" : "HISTORY",
    "history_value" : "history",
    "withdraw_value" : "withdraw",
    "comment_successful_label" : "Your comment has been posted.",
    "donation_successful_label" : "Your donation was successful",
    "withdraw_successful_label" : "Your withdraw was successful",
    "check_current_balance_label" : "Check your current balance (BTC)",
    "check_current_password" : "The password is incorrect.",
    "failure_title" : "Failure",
    "success_title" : "Success",
    "retry_label" : "Retry",
    "user_guide_label" : "User Guide",
    "donate_label" : "Donate",
    "you_want_continue_donate_label" : "Are you sure you want to continue your donation?",
    "no_label" : "NO",
    "mycode_total_support_amount_label" : "TOTAL SUPPORT AMOUNT",
    "total_support_amount_label" : "TOTAL SUPPORT AMOUNT",
    "next_level_label" : "NEXT LEVEL",
    "after_receive_supports_level_label" : "Your level will be G",
    "after_receive_supports_level_label2" : " after you receive supports",
    "every_thanks_label" : "EVERY THANKS",
    "support_label" : "SUPPORT",
    "support_value" : "support",
    "setting_label" : "SETTINGS",
    "profile_label" : "PROFILE",
    "notification_label" : "NOTIFICATION",
    "contact_label" : "CONTACT",
    "language_label" : "LANGUAGE",
    "logout_label" : "LOGOUT",
    "edit_label" : "EDIT",
    "save_label" : "SAVE",
    "saved_label" : "It has been saved.",
    "failed_label" : "It has failed.",
    "register_time_zone_label" : "Register Time Zone",
    "register_eth_address_label" : "Register ETH Address (FOR ERTS)",
    "transaction_password_label" : "Transaction Password",
    "dashboard_label" : "DASHBOARD",
    "my_level_label" : "MY LEVEL",
    "summary_label" : "SUMMARY",
    "mycode_pending_amount_label" : "PENDING AMOUNT",
    "pending_amount_label" : "PENDING AMOUNT",
    "upgrade_level_more_donation_label" : "Upgrade to G ",
    "upgrade_level_more_donation_label_2" : " level to get more donations",
    "mentor_label" : "Mentee",
    "referral_label" : "REFERRAL",
    "referral_hint" : "Mentor ID",
    "no_mentor_id" : "I don't have a Mentor ID",
    "referral_error_label" : "Please enter a referral.",
    "supporters_label" : "SUPPORTERS",
    "rewarded_label" : "Rewards",
    "pending_label" : "Pending",
    "my_tree_label" : "MY TREE",
    "branch_label" : "Branch",
    "date_label" : "DATE",
    "detail_history_label" : "Details",
    "event_label" : "EVENT",
    "ok_label" : "OK",
    "check_your_email_confirm_label" : "Your email has been sent. Please check your email to confirm.",
    "something_went_wrong_label" : "Something went wrong. Please make sure you filed out true and again.",
    "enter_id_label" : "Please enter your ID",
    "dorothy_amount_label" : "DOROTHY AMOUNT",
    "days_label" : "DAYS",
    "hours_label" : "HOURS",
    "minutes_label" : "MINUTES",
    "seconds_label" : "SECONDS",
    "dorothy_provides_level_des_label" : "Dorothy's chance to win is open to all members who achieve EVERY THANKS G16 or higher.",
    "be_supported_label" : "BE SUPPORTED",
    "charge_label" : "CHARGE",
    "to_my_account_label" : "to my account",
    "dorothy_label" : "DOROTHY",
    "take_gift_from_god_label" : "BE THE LUCKY ONE",
    "share_give_your_hope_label" : "SHARE AND GIVE YOUR HOPE",
    "category_label" : "Category",
    "service_label" : "Service",
    "coin_label" : "Coin",
    "message_label" : "Message",
    "level_me_message_label" : "Please leave me a message (3~300)",
    "no_history_label" : "No History",
    "contact_value" : "contact",
    "angle_complete_label" : "COMPLETE",
    "congrats_label" : "Congrats!",
    "my_btc_address_label" : "MY BTC ADDRESS",
    "btc_address_copied_label" : "My btc address has been copied.",
    "my_total_balance_label" : "MY TOTAL BALANCE",
    "buy_btc_with_credit_card_label" : "BUY BTC WITH CREDIT CARD",
    "buy_btc_with_credit_card_label_simplex" : "BUY BTC WITH CREDIT CARD - simplex",
    "buy_btc_with_credit_card_label_indacoin" : "BUY BTC WITH CREDIT CARD - INDACOIN",
    "charge_value" : "charge",
    "cur_trans_pwd_label" : "Current Transaction Password",
    "new_trans_pwd_label" : "New Transaction Password",
    "confirm_new_trans_pwd_label" : "Confirm New Transaction Password",
    "failed_change_pwd_label" : "Failed to change password",
    "change_label" : "CHANGE",
    "change_pwd_label" : "Change Password",
    "cur_pwd_label" : "Current Password",
    "new_pwd_label" : "New Password",
    "confirm_pwd_label" : "Confirm New Password",
    "change_email_label" : "Change E-mail",
    "new_email_label" : "New Email",
    "authentication_code_label" : "The authentication code is",
    "entered_email_verified_label" : "'The email you entered has been verified.",
    "login_failed" : "Login Failed",
    "charge_noti_label" : "Notification",
    "charge_noti_label_des_1" : "1. Deposit below 0.000001 BTC is not reflected",
    "charge_noti_label_des_2" : "2. Please press the [copy] button at the top to copy the [MY BTC ADDRESS] address, then paste to BTC buying site or BTC wallet transfer address [Bitcoin address].",
    "charge_noti_label_des_3" : "3. If your credit card (debit card) is blocked from overseas online payment, and you cannot purchase BTC. On this situation, please call the credit card customer center to unblock your card and finish the overseas online payment.",
    "charge_noti_label_des_4" : "4. Buying BTC may not be possible due to the credit card (debit card) issuer's overseas merchant payment limit policy. Please contact the credit card issuer if you cannot finish the payment.",
    "charge_noti_label_des_5" : "5. When you register credit card (Debit card) information, please make sure your address and personal information accurate. If the information does not match, the credit card company’s approval may be rejected.",
    "i_agree_with_both_label" : "I agree with both",
    "term_of_use_label" : "TERM(CLICK) OF USE",
    "and_label" : "and",
    "privacy_policy_label" : "PRIVACY POLICY(CLICK)",
    "of_everythanks_app_label" : "of EVERYTHANKS App.",
    "app_push_setting_label" : "App Push Service Settings",
    "app_push_noti_label" : "If you set the Push item to 'On', you can receive information about various benefits and events such as sponsorship history, pending status, and Dorothy lottery that occur in EVERY THANKS.",
    "receive_full_noti_label": "Receive full notifications",
    "btc_recharge_noti_label": "BTC Recharge Notification",
    "donation_noti_label": "Notification of Donation",
    "pending_occur_noti_label": "PENDING Occurrence Notification",
    "pending_payment_label": "Pending Donation Payment",
    "dorothy_lottery_schedule_label": "Dorothy Lottery Schedule Announcement",
    "answers_my_questions_label": "Notification of receiving answers to my questions",
    "donation_recommendation_label": "Donation Recommendation Board",
    "my_voting_noti_label": "My Voting Notifications",
    "comments_my_post_label": "Notification of comments on my post",
    "end_voting_my_post_label": "Notification of End of Voting for My Post",
    "selection_my_article_label": "Notification of selection of donation destination for my article",
    "comments_on_my_post_noti_label": "Notification of comments on my post",
    "end_of_voting_for_my_post_noti_label": "Notification of End of Voting for My Post",
    "selection_of_donation_destination_for_my_article_noti_label": "Notification of selection of donation destination for my article",
    "birthday": "Date of Birth",
    "registration": "REG",
    "edit": "Edit",
    "mobile": "Mobile",
    "phone_hint": "Enter the mobile number",
    "country_hint": "Select the country",
    "erts_paid": "ERTS to be paid",
    "erts_complete": "ERTS payment completed",
    "achieve_date": "Achievement date",
    "step_achieve": "Steps to achieve",
    "payment_date": "Payment date",
    "quantity_paid": "Quantity to be paid",
    "erts_paid_guide": "* When the ERTS achievement stage is met, the administrator directly checks and sends the ERTS to the registered ETH address. If the member's ETH address is not registered at the time of transmission, transmission is not possible, so please register the ETH address in advance.",
    "erts_complete_guide": "* This is the completed ERTS payment to the registered ETH address.",
    "erts_help_title": "What is ERTS?",
    "erts_help_content_1": "Click to see more about ERTS. You can see more about ERTS. Please also edit the last rank coin payment details.",
    "erts_help_content_2": "Requirements after change:",
    "erts_help_content_3": "<ERTS Coin payment by stage at the time of sponsorship (lock-up for 1 year) >",
    "erts_help_content_4": "G1 donation level: 100 ERTS coins paid",
    "erts_help_content_5": "G3 donation level: 200 ERTS coins paid",
    "erts_help_content_6": "G6 donation level: 200 ERTS coins paid",
    "erts_help_content_7": "G9 donation level: 300 ERTS coins paid",
    "erts_help_content_8": "G12 donation level: 400 ERTS coins paid",
    "erts_help_content_9": "G24 donation level: 600 ERTS coins paid",
    "erts_help_content_10": "G36 donation level: 900 ERTS coins paid",
    "erts_help_content_11": "G48 donation level: 1,200 ERTS coins paid",
    "erts_help_content_12": "G60 donation level: 1,500 ERTS coins paid",
    "erts_help_content_13": "G72 donation level: 1,800 ERTS coins paid",
    "erts_help_content_14": "G84 donation level: 2,100 ERTS coins paid",
    "erts_help_content_15": "G96 donation level: 2,300 ERTS coins paid",
    "erts_help_content_16": "G99 donation level: ERTS 2,400 coins issued",
    "erts_help_content_17": "The total issuance of ERTS coins is 1.4 billion. *Additional issuance is possible when the number exceeds 100,000.",
    "close": "Close",
    "donation_recommendation_label2": "Donation Recommendation Board",
    "donation_recom_noti_label": "EVERY THANKS is delivering <span style='background-color: #ff5a57; font-weight: bold'>100% of donation money</span> to the <span style='background-color: #ff5a57; font-weight: bold'>place in need</span>\n\n If you recommend places where need donation with your special story, we will select a place to receive the donation according to the <span style='background-color: #ff5a57; font-weight: bold'>number of votes</span> from members",
    "donation_recom_noti_label0": "EVERY THANKS is delivering ",
    "donation_recom_noti_label1": "100% of donation money",
    "donation_recom_noti_label2": " to the ",
    "donation_recom_noti_label3": "place in need",
    "donation_recom_noti_label4": "\n\nIf you recommend places where need donation with your special story, we will select a place to receive the donation according to the ",
    "donation_recom_noti_label5": "number of votes",
    "donation_recom_noti_label6": " from members",
    "donation_recom_list_label": "Donation Recommendation List",
    "contents_by_me_label": "Contents written by me",
    "search_word_label": "Search Word (title and text)",
    "search_label": "Search",
    "country_author_label": "Country of Author",
    "classification_label": "Classification",
    "registration_order_label": "Registration Order",
    "classification1_label": "All",
    "classification2_label": "Orphanage (Nursery Center)",
    "classification3_label": "Facilities for the Disabled",
    "classification4_label": "Homeless Facilities",
    "classification5_label": "Medical Support",
    "classification6_label": "Single Mother Facilities",
    "classification7_label": "Elderly Nursing Home",
    "classification8_label": "War Damage Support",
    "classification9_label": "Disaster Support",
    "classification10_label": "Poverty & Famine Support",
    "classification11_label": "Animal Welfare",
    "classification12_label": "Other Support",
    "recom_order_label": "Recommendation Order",
    "comment_order_label": "Comment Order",
    "completion_order": "Order of completion",
    "selected_as_donee_label": "Selected as a donee",
    "voting_label": "Voting",
    "voting_end_label": "End of voting",
    "edit_article_noti1_label": "Please write the main information about where and why needs donation",
    "edit_article_noti2_label": "※ Any content unrelated to the topic on the board may be deleted without any notice",
    "choose_donee_label": "Choose a donee",
    "title_label":"Title",
    "title_placeholder_label":"Please enter the title of recommendation contents",
    "donation_org_name_label": "Name of donation organization",
    "donation_org_placeholder_label": "Please enter the name of recommended donation organization",
    "donation_orgnum_label": "Contact number of donation organization",
    "select_country_label": "Select the country",
    "orgnum_placeholder_label": "Please enter number of donation organization",
    "donation_org_address_label": "Address of donation organization",
    "org_address_placeholder_label": "Please enter the address of donation organization",
    "Why_donation_label": "Why the donation is needed and my story",
    "upload_label": "Upload",
    "edit_article_success_label": "Donation recommendation contents writing has been completed",
    "edit_article_fail_label": "Please enter all contents for donation recommendation writing",
    "article_noti1_label": "If you need donation, please click ”Voting heart” button below",
    "article_noti2_label": "※ You can only click the recommendation button one time for one posting",
    "comment_send_label": "Send",
    "comment_placeholder_label": "Please write your comments",
    "comment_warning_label": "Please enter your comments",
    "delete_warning_label": "Are you sure to delete it?",
    "yes_label": "YES",
    "delete_label": "Delete",
    "addinfo_noti_label": "Additional registration of membership information is required\n\nAdditionally entered information can be checked and changed in “Profile”",
    "member_label": "Member",
    "member_contact_label": "Contact Number of Members",
    "member_contact_placeholder_label": "Enter the contact number of members",
    "addinfo_register_label": "Register the information of members",
    "addinfo_success_label": "Additional registration of membership information has been completed\nIt will move to the writing page",
    "addinfo_fail_label": "Please enter all contents for additional registration of membership information",
    "modify_label": "Modify",
    "modification_fail_label": "Modification cannot been completed if you vote more than one person",
    "modification_success_label": "Modification has been completed",
    "article_delete_waring_label": "If you delete the posting, voting history will be invalid and cannot be recovered. Are you sure to delete it?",
    "finish_label": "Finish",
    "no_information": "No Information",
    "support_selection": "Support Selection",
    "support": "Support",
    "donation": "Donation",
    "support_selection_desc": "If you select Support , you will receive partial support for mentors up to 12 LEVEL.\nIf you select Donation, all donations will be sent to the donor.\nSupport helps promote the decentralized donation platform EVERY THANKS",
    "my_support_total_amount": "My Support Total amount",
    "donation_status": "Donation Status",
    "donation_status_desc": "Donation information and all donation usage status\nCheck it out on the EVERY THANKS website",
    "total_btc_supported": "Total BTC Supported",
    "total_btc_rewarded": "Total BTC Rewarded",
    "total_donation_amount": "Total Donation Amount",
    "voting_donation_recommendation": "Voting for Donation Recommendation",
    "voting_description_1": "Make donations transparent",
    "voting_description_2": "Please recommend donation projects and participate in voting.",
    "voting_register_1": "",
    "voting_register_2": " registered donation destinations",
    "str_report": "Report",
    "str_block": "Block",
    "str_report_writer": "Report Writer",
    "str_dorothy_info_1": "",
    "str_dorothy_info_2": "Rules",
    "str_dorothy_info_3": " and ",
    "str_dorothy_info_4": "precautions",
    "str_dorothy_info_5": " of Dorothy events",
    "str_dorothy_info_rule_title": "Rule of event :",
    "str_dorothy_info_rule_content": "Winnings: All sponsorships from 13G to 16G will be accumulated and become Dorothy lottery winnings.\n" +
        "Winners: accounts with more than 16G\n" +
        "Winning date: 1st day of every month UTC+8 18:00\n" +
        "Winning method: 2 winners will be drawn by lottery and 50% of the accumulated amount will be allocated to each.\n" +
        "Probability of winning: the new account that reached 16G in the current month has a high probability of winning, and the closer the account that has reached 16G in the previous month is closer to 99G, the higher the probability of winning.",
    "str_dorothy_info_caution_title": "Note:",
    "str_dorothy_info_caution_content": "This event is hosted by EVERY THANKS and has nothing to do with APPLE.",
    "str_reported": "Reported.",
    "str_member_withdraw": "Membership Withdrawal",
    "str_withdraw_noti": "[Note] Please read the following carefully before canceling membership.",
    "str_withdraw_noti_content": "You will not be able to edit/delete content and comments registered in the application. If necessary, please edit or delete it before applying for cancellation.",
    "str_withdrawal": "To withdrawal",
    "str_withdraw_ask": "Do you want to withdraw？",
    "str_withdraw_prev": "This account has already been withdrawn.",
    "str_no_find_account": "User Id does not exist.",
    "str_password_error": "Incorrect Password.",
    "understand_agree_all_label" : "I understand and agree all of the above",
    "noti_label": "Alarm",
    "noti_bitcoin_network_label": "Bitcoin price information cannot be loaded at this time. Please check the network status and try again later.",
    "nation_label": "Nation",
    "bitcoin_address_label": "Bitcoin Address",
    "direct_sponsorship_label": "Direct Sponsorship",
    "popup_logout_ask_label": "Are you sure you want to logout?",
    "account_status_label": "Account Status",
    "signup_check_policy_label": "Please check both TERM OF USE and PRIVACY POLICY.",
    "change_password_completed": "Your password change has been completed!",
};

const ko_data = {
    "id_hint" : "아이디",
    "password_label" : "비밀번호",
    "kor_label" : "한국어",
    "eng_label" : "영어",
    "chn_label" : "중국어",
    "jpn_label" : "일본어",
    "password_hint" : "비밀번호",
    "email_hint" : "이메일",
    "code_hint" : "코드",
    "signin_label" : "로그인",
    "signup_label" : "회원가입",
    "find_password_label" : "비밀번호 찾기",
    "join_member_label" : "회원가입",
    "find_id_label" : "아이디 찾기",
    "send_email_label" : "이메일 전송",
    "hello_label" : "안녕하세요!",
    "welcome_title" : "환영합니다!",
    "welcome_des_label" : "계정 생성이 완료되었습니다!",
    "btn_ok_label" : "확인",
    "id_available_label" : "사용가능한 ID입니다",
    "confirm_label" : "확인",
    "id_already_use_label" : "이미 사용중인 아이디입니다",
    "password_error_label" : "비밀번호는 8자리 이상 15자리 이하의 영어+숫자+특수문자의 조합으로 구성되어야 합니다",
    "confirm_password_hint" : "비밀번호가 확인되었습니다",
    "send_label" : "보내기",
    "email_verify_label" : "메일이 인증되었습니다",
    "code_correct_label" : "올바른 코드를 입력해주세요",
    "reference_hint" : "참고자료",
    "reference_error_label" : "참고자료를 반드시 작성해주시길 바랍니다",
    "transaction_hint" : "거래 비밀번호는 6자리입니다",
    "confirm_transaction_hint" : "거래 비밀번호가 확인되었습니다",
    "confirm_password_error_label" : "입력한 비밀번호가 확인된 비밀번호와 일치하지 않습니다",
    "confirm_password_equal_error_label" : "입력한 거래 비밀번호가 확인된 거래 비밀번호와 일치하지 않습니다",
    "fail_signup_label" : "회원가입을 하지 못했습니다",
    "korean_label" : "한국어",
    "english_label" : "영어",
    "chinese_label" : "중국어",
    "japanese_label" : "일본어",
    "select_one_label" : "하나를 선택해 주세요",
    "select_your_language_label" : "사용자 언어를 선택해주세요",
    "check_address_amount_label" : "주소 또는 금액을 확인해주세요",
    "bit_address_valid_label" : "유효한 비트 주소입니다",
    "enter_bit_address_label" : "올바른 비트 주소를 입력하십시오",
    "available_balance_label" : "출금 가능한 잔액",
    "address_label" : "주소",
    "check_label" : "확인",
    "amount_label" : "금액",
    "withdraw_label" : "출금",
    "enter_address_label" : "주소를 입력하십시오",
    "check_address_label" : "주소를 확인하시길 바랍니다",
    "enter_amount_label" : "금액을 입력하십시오",
    "minimum_withdrawal_quantity_label" : "최소 출금 수량은 0_0001 BTC입니다",
    "withdrawal_fee_charged_label" : "인출 가능한 최소 BTC는 0.002 BTC입니다.",
    "withdrawal_fee_charged_label_1" : "2%의 출금수수료가 부과됩니다.",
    "withdrawal_fee_charged_label_2" : "최소 인출 수수료는 0.0005 BTC입니다.",
    "support_fee_charged_label" : "10%의 후원수수료가 부과됩니다",
    "contact_history_label" : "문의내역",
    "charge_history_label" : "충전내역",
    "withdraw_history_label" : "출금내역",
    "support_history_label" : "후원내역",
    "history_label" : "기록",
    "history_value" : "기록",
    "withdraw_value" : "출금",
    "comment_successful_label" : "댓글이 등록되었습니다.",
    "donation_successful_label" : "당신의 기부가 성공했습니다",
    "withdraw_successful_label" : "출금이 성공했습니다.",
    "check_current_balance_label" : "현재의 잔액을 확인해주세요(BTC)",
    "check_current_password" : "비밀번호가 잘못되었습니다.",
    "failure_title" : "실패",
    "success_title" : "성공",
    "retry_label" : "재시도",
    "user_guide_label" : "사용자 설명서",
    "donate_label" : "기부",
    "you_want_continue_donate_label" : "기부를 계속하시겠습니까?",
    "no_label" : "아니오",
    "mycode_total_support_amount_label" : "총 후원받은 금액",
    "total_support_amount_label" : "총 후원한 금액",
    "next_level_label" : "다음 등급",
    "after_receive_supports_level_label" : "후원을 하시면 다음 등급인 G",
    "after_receive_supports_level_label2" : "이 되실 수 있습니다",
    "every_thanks_label" : "EVERY THANKS",
    "support_label" : "후원",
    "support_value" : "후원",
    "setting_label" : "설정",
    "profile_label" : "프로필",
    "notification_label" : "공지사항",
    "contact_label" : "문의하기",
    "language_label" : "언어",
    "logout_label" : "로그아웃",
    "edit_label" : "수정",
    "save_label" : "저장",
    "saved_label" : "저장되었습니다.",
    "failed_label" : "실패되었습니다.",
    "register_time_zone_label" : "시간대 등록",
    "register_eth_address_label" : "ETH 주소 등록 (FOR ERTS)",
    "transaction_password_label" : "거래 비밀번호",
    "dashboard_label" : "대시보드",
    "my_level_label" : "나의 등급",
    "summary_label" : "요약",
    "mycode_pending_amount_label" : "Pending 중인 금액",
    "pending_amount_label" : "보류 중인 금액",
    "upgrade_level_more_donation_label" : "더 많은 기부를 받으려면 G ",
    "upgrade_level_more_donation_label_2" : " 등급으로 업그레이드하세요",
    "mentor_label" : "Mentee",
    "referral_label" : "추천",
    "referral_hint" : "멘토 ID",
    "no_mentor_id" : "입력할 멘토 ID가 없습니다.",
    "referral_error_label" : "추천인을 입력해 주세요.",
    "supporters_label" : "SUPPORTERS",
    "rewarded_label" : "Rewards",
    "pending_label" : "Pending",
    "my_tree_label" : "나의 TREE",
    "branch_label" : "Branch",
    "date_label" : "날짜",
    "detail_history_label" : "상세내역",
    "event_label" : "이벤트",
    "ok_label" : "확인",
    "check_your_email_confirm_label" : "회원님의 이메일로 전송되었습니다. 이메일 확인 후 승인해주시길 바랍니다",
    "something_went_wrong_label" : "문제가 발생했습니다. 작성한 내용이 정확한지 확인해주시길 바랍니다.",
    "enter_id_label" : "아이디를 입력하세요",
    "dorothy_amount_label" : "도로시 후원장려금",
    "days_label" : "일",
    "hours_label" : "시간",
    "minutes_label" : "분",
    "seconds_label" : "초",
    "dorothy_provides_level_des_label" : "도로시 당첨 기회는 EVERY THANKS G16 등급 이상을 달성한 모든 회원에게 제공됩니다.",
    "be_supported_label" : "후원받은 총 BTC",
    "charge_label" : "충전",
    "to_my_account_label" : "나의 계정",
    "dorothy_label" : "DOROTHY",
    "dorothy_winning_result_label" : "DOROTHY 당첨 결과",
    "take_gift_from_god_label" : "행운의 주인공이 되어보세요",
    "share_give_your_hope_label" : "EVERY THANKS를 통한 소중한 기부로\n\r희망을 나눠보세요",
    "category_label" : "카테고리",
    "service_label" : "서비스",
    "coin_label" : "코인",
    "message_label" : "메시지",
    "level_me_message_label" : "제게 메시지를 남겨주세요 (3 ~ 300)",
    "no_history_label" : "기록 없음",
    "contact_value" : "연락처",
    "angle_complete_label" : "완료",
    "congrats_label" : "축하합니다!",
    "my_btc_address_label" : "나의 BTC 주소",
    "btc_address_copied_label" : "내 BTC 주소가 복사되었습니다",
    "my_total_balance_label" : "나의 총 잔액",
    "buy_btc_with_credit_card_label" : "신용 카드로 BTC 구매",
    "buy_btc_with_credit_card_label_simplex" : "BUY BTC WITH CREDIT CARD - simplex",
    "buy_btc_with_credit_card_label_indacoin" : "BUY BTC WITH CREDIT CARD - INDACOIN",
    "charge_value" : "충전",
    "cur_trans_pwd_label" : "현재 거래 비밀번호",
    "new_trans_pwd_label" : "새로운 거래 비밀번호",
    "confirm_new_trans_pwd_label" : "새로운 거래 비밀번호 확인",
    "failed_change_pwd_label" : "비밀번호를 변경하지 못했습니다",
    "change_label" : "변경",
    "change_pwd_label" : "비밀번호 변경",
    "cur_pwd_label" : "현재 비밀번호",
    "new_pwd_label" : "새로운 비밀번호",
    "confirm_pwd_label" : "새로운 비밀번호를 확인하였습니다",
    "change_email_label" : "이메일 변경",
    "new_email_label" : "새로운 이메일",
    "authentication_code_label" : "인증 코드는 다음과 같습니다",
    "entered_email_verified_label" : "'입력하신 이메일이 확인되었습니다",
    "login_failed" : "로그인 실패",
    "charge_noti_label" : "신용카드(직불카드) 구매 안내",
    "charge_noti_label_des_1" : "1. 0.000001 BTC 미만의 충전금액은 반영되지 않습니다.",
    "charge_noti_label_des_2" : "2. 상단의 [copy] 버튼을 눌러 [MY BTC ADDRESS] 주소를 복사해서, BTC 구매사이트 또는 BTC지갑 송금 주소 [Bitcoin address]에 붙여 넣으세요.",
    "charge_noti_label_des_3" : "3. 본인의 신용카드(현금카드)가 해외 온라인 결제 차단이 되어있으면 BTC 구매가 불가합니다. 신용카드 고객센터에 전화해서 해외 온라인 결제가 가능하도록 차단을 해제해주세요",
    "charge_noti_label_des_4" : "4. 신용카드(현금카드) 발행사의 해외 가맹점 결제한도 정책으로 인해 BTC구매가 이루어지지 않을 수도 있습니다. 결제가 되지 않을 때는 신용카드 발행사에 문의해주세요.",
    "charge_noti_label_des_5" : "5. 신용카드(현금카드) 정보 등록 시 주소와 개인정보를 정확하게 등록해 주셔야 합니다. 정보가 일치하지 않으면 카드사의 승인이 거절될 수 있습니다.",
    "i_agree_with_both_label" : "EVERYTHANKS App",
    "term_of_use_label" : "이용약관(클릭필요)",
    "and_label" : "과",
    "privacy_policy_label" : "개인정보보호정책(클릭필요)",
    "of_everythanks_app_label" : "에 모두 동의합니다.",
    "app_push_setting_label" : "App Push 서비스 설정",
    "app_push_noti_label" : "Push 항목을 'On'으로 설정하시면 EVERY THANKS에서 발생하는 후원내역과 펜딩상태, 도로시 추첨 등 다양한 혜택과 이벤트에 대해 안내받을 수 있습니다.",
    "receive_full_noti_label": "전체 알림 수신",
    "btc_recharge_noti_label": "BTC 충전 알림",
    "donation_noti_label": "후원금 발생 알림",
    "pending_occur_noti_label": "PENDING 발생 알림",
    "pending_payment_label": "PENDING 후원금 지급 알림",
    "dorothy_lottery_schedule_label": "도로시 추첨 일정 알림",
    "answers_my_questions_label": "내 문의사항 답변 수신 알림",
    "donation_recommendation_label": "기부추천 게시판",
    "my_voting_noti_label": "내글 투표 알림",
    "comments_my_post_label": "내글 댓글 알림",
    "end_voting_my_post_label": "내글 투표 종료 알림",
    "selection_my_article_label": "내글 기부처 선정 알림",
    "comments_on_my_post_noti_label": "내글 댓글 알림",
    "end_of_voting_for_my_post_noti_label": "내글 투표 종료 알림",
    "selection_of_donation_destination_for_my_article_noti_label": "내글 기부처 선정 알림",
    "birthday": "생년월일",
    "registration": "등록",
    "edit": "수정",
    "mobile": "전화번호",
    "phone_hint": "전화번호 입력",
    "country_hint": "Select the country",
    "erts_paid": "ERTS 지급 예정 수량",
    "erts_complete": "ERTS 지급 완료 수량",
    "achieve_date": "달성 일시",
    "step_achieve": "달성 단계",
    "payment_date": "지급 일시",
    "quantity_paid": "지급 예정 수량",
    "erts_paid_guide": "* ERTS 달성 단계가 충족되면 관리자가 직접 확인 후 등록하신 ETH주소로 ERTS를 전송합니다. 전송 시점에 회원의 ETH주소가 등록되어 있지 않으면 전송이 불가하니 사전에 ETH주소를 등록하시길 바랍니다.",
    "erts_complete_guide": "* 등록하신 ETH주소로 ERTS 지급이 완료된 내역입니다.",
    "erts_help_title": "ERTS란?",
    "erts_help_content_1": "ERTS에 추가 내용을 보기 위해 클릭하면 ERTS에 관한 추가 내용을 볼수있습니다. 마지막 직급별 코인 지급 내용도 수정해주시기 바랍니다.",
    "erts_help_content_2": "변경 후 요구 사항:",
    "erts_help_content_3": "<후원 시 단계별 ERTS 코인 지급(1년간 락업)>",
    "erts_help_content_4": "G1 기부 등급 : ERTS 코인 100개 지급",
    "erts_help_content_5": "G3 기부 등급 : ERTS 코인 200개 지급",
    "erts_help_content_6": "G6 기부 등급 : ERTS 코인 200개 지급",
    "erts_help_content_7": "G9 기부 등급 : ERTS 코인 300개 지급",
    "erts_help_content_8": "G12 기부 등급 : ERTS 코인 400개 지급",
    "erts_help_content_9": "G24 기부 등급 : ERTS 코인 600개 지급",
    "erts_help_content_10": "G36 기부 등급 : ERTS 코인 900개 지급",
    "erts_help_content_11": "G48 기부 등급 : ERTS 코인 1,200개 지급",
    "erts_help_content_12": "G60 기부 등급 : ERTS 코인 1,500개 지급",
    "erts_help_content_13": "G72 기부 등급 : ERTS 코인 1,800개 지급",
    "erts_help_content_14": "G84 기부 등급 : ERTS 코인 2,100개 지급",
    "erts_help_content_15": "G96 기부 등급 : ERTS 코인 2,300개 지급",
    "erts_help_content_16": "G99 기부 등급: ERTS 코인 2,400개 지급",
    "erts_help_content_17": "ERTS 코인 총 발행량은 14억개 입니다. *100,000만명 초과시 추가 발행 가능",
    "close": "닫기",
    "donation_recommendation_label2": "기부처 추천 게시판",
    "donation_recom_noti_label": "EVERY THANKS는 <span style='background-color: #ff5a57; font-weight: bold'>도움이 필요한 곳</span>을 찾아 기부금 100%를 직접 기부 하고 있습니다\n\n" +
        "본인의 소중한 사연과 함께 기부가 필요한 곳을 추천해 주시면 <span style='background-color: #ff5a57; font-weight: bold'>회원들의 투표수를 기준<span>으로 기부받을 곳을 선정하겠습니다 \n",
    "donation_recom_noti_label0": "EVERY THANKS는 ",
    "donation_recom_noti_label1": "도움이 필요한 곳",
    "donation_recom_noti_label2": "을 찾아 ",
    "donation_recom_noti_label3": "기부금 100%",
    "donation_recom_noti_label4": "를 직접 기부 하고 있습니다\n\n 본인의 소중한 사연과 함께 기부가 필요한 곳을 추천해 주시면 ",
    "donation_recom_noti_label5": "회원들의 투표수를 기준",
    "donation_recom_noti_label6": "으로 기부받을 곳을 선정하겠습니다 \n",
    "donation_recom_list_label": "기부처 추천글 목록",
    "contents_by_me_label": "내가 작성한 글",
    "search_word_label": "검색어 (제목 또는 본문)",
    "search_label": "검색",
    "country_author_label": "작성자 국가",
    "classification_label": "기부처 구분",
    "registration_order_label": "등록순",
    "classification1_label": "전체",
    "classification2_label": "고아원(보육원)",
    "classification3_label": "장애인시설",
    "classification4_label": "노숙자시설",
    "classification5_label": "의료지원",
    "classification6_label": "미혼모시설",
    "classification7_label": "노인요양원",
    "classification8_label": "전쟁피해지원",
    "classification9_label": "재난지원",
    "classification10_label": "가난&기근지원",
    "classification11_label": "동물복지",
    "classification12_label": "기타 지원",
    "recom_order_label": "추천순",
    "comment_order_label": "댓글순",
    "completion_order": "선정완료순",
    "selected_as_donee_label": "기부처 선정",
    "voting_label": "투표중",
    "voting_end_label": "투표 종료",
    "edit_article_noti1_label": "기부가 필요한 곳의 주요 정보와 \n" +
        "기부가 필요한 이유에 대해 작성해주세요\n",
    "edit_article_noti2_label": "※ 게시판의 주제와 관계 없는 글은 사전 통지 없이 삭제될 수 있습니다",
    "choose_donee_label": "기부처 구분",
    "title_label":"제목",
    "title_placeholder_label":"추천글 제목 입력",
    "donation_org_name_label": "기부처 이름",
    "donation_org_placeholder_label": "추천하는 기부처 이름 입력",
    "donation_orgnum_label": "기부처 전화번호 ",
    "select_country_label": "국가 선택",
    "orgnum_placeholder_label": "기부처 전화번호 입력",
    "donation_org_address_label": "기부처 주소",
    "org_address_placeholder_label": "기부처 주소 입력",
    "Why_donation_label": "기부가 필요한 이유와 나의 이야기",
    "upload_label": "등록하기",
    "edit_article_success_label": "기부 추천 글 작성이 완료되었습니다",
    "edit_article_fail_label": "기부 추천 글 작성을 위해\n내용을 모두 입력해 주세요",
    "article_noti1_label": "기부가 필요하다고 생각하시면 아래 투표하기 하트버튼을 눌러주세요",
    "article_noti2_label": "※ 추천 버튼은 게시물 1개에서 한번만 누를 수 있습니다",
    "comment_send_label": "등록",
    "comment_placeholder_label": "댓글을 작성해주세요",
    "comment_warning_label": "댓글을 입력해 주세요",
    "delete_warning_label": "정말 삭제하시겠습니까?",
    "yes_label": "네",
    "delete_label": "삭제",
    "addinfo_noti_label": "회원정보 추가 등록이 필요합니다\n" +
        "추가입력된 정보는 '프로필'에서 확인 및 변경이 \n" +
        "가능합니다",
    "member_label": "회원",
    "member_contact_label": "회원 전화번호",
    "member_contact_placeholder_label": "회원 전화번호 입력",
    "addinfo_register_label": "회원정보 등록",
    "addinfo_success_label": "회원정보 추가 등록이 \n" +
        "완료되었습니다\n" +
        "글쓰기 페이지로 이동합니다",
    "addinfo_fail_label": "회원정보 추가 등록을 위해 \n" +
        "내용을 모두 입력해 주세요",
    "modify_label": "수정",
    "modification_fail_label": "1명 이상 투표를 하였을때는\n" +
        "수정이 불가 합니다",
    "modification_success_label": "수정이 완료되었습니다",
    "article_delete_waring_label": "게시물을 삭제하시면 \n" +
        "투표받은 내역은 무효가 되며\n" +
        "삭제 후 되돌릴 수 없습니다\n" +
        "삭제하시겠습니까?",
    "finish_label": "저장",
    "no_information": "No Information",
    "support_selection": "후원 여부 선택",
    "support": "후원",
    "donation": "기부",
    "support_selection_desc": "후원을 선택하시면 12 LEVEL까지 멘토들에게 일부 후원이 됩니다.\n기부를 선택하시면 기부금 모두가 기부처로 전달됩니다.\n후원은 탈중앙 기부 플랫폼 EVERY THANKS를 널리 알리는데 도움이 됩니다\n",
    "my_support_total_amount": "나의 후원 금액",
    "donation_status": "기부 현황",
    "donation_status_desc": "기부처 정보와 모든 기부금 사용 현황을\nEVERYTHANKS서 확인해보세요.",
    "total_btc_supported": "총 후원 받은 금액",
    "total_btc_rewarded": "총 리워드 받은 금액",
    "total_donation_amount": "총 기부 금액",
    "voting_donation_recommendation": "기부처 추천",
    "voting_description_1": "너와 나의 투명한 기부",
    "voting_description_2": "기부처를 추천하고 투표에 참여하세요.",
    "voting_register_1": "등록된 기부처 ",
    "voting_register_2": "개",
    "str_report": "신고하기",
    "str_block": "차단하기",
    "str_report_writer": "작성자 신고하기",
    "str_dorothy_info_1": "도로시 이벤트의 ",
    "str_dorothy_info_2": "행사규정",
    "str_dorothy_info_3": " 및 ",
    "str_dorothy_info_4": "유의사항",
    "str_dorothy_info_5": "",
    "str_dorothy_info_rule_title": "후원장려금:",
    "str_dorothy_info_rule_content": "16단계의 모든 후원금은 적립되어 도로시 후원장려금이 됩니다.\n" +
        "당첨 대상 : 16단계 이상을 달성한 계정\n" +
        "당첨 일자 : 매달 1일 UTC +8 18:00\n" +
        "당첨 방식 : 추첨을 통해 2명 추첨 후 누적 금액을 50%씩 분배\n" +
        "당첨 확률 : 해당월에 신규로 16단계 달성한 사람이 가장 높으며, 16단계 달성 익월부터는 99단계에 가까울수록 당첨확률이 올라 갑니다.(이하 나머지 뒷부분은 동일하게 유지)",
    "str_dorothy_info_caution_title": "유의사항:",
    "str_dorothy_info_caution_content": "본 행사는 EVERY THANKS가 주관하는 이벤트 행사이며 APPLE과는 아무런 관련이 없습니다.",
    "str_reported": "신고되었습니다.",
    "str_member_withdraw": "회원탈퇴",
    "str_withdraw_noti": "탈퇴 요청 전 주의사항",
    "str_withdraw_noti_content": "해당 앱에 내가 등록한 내용 및 댓글 등을 수정/삭제할 수 없게 됩니다. 필요한 경우 탈퇴 요청 전에 수정하거나 삭제하시기 바랍니다.",
    "str_withdrawal": "탈퇴하기",
    "str_withdraw_ask": "회원 탈퇴를 원하십니까?",
    "str_withdraw_prev": "이미 탈퇴한 계정입니다.",
    "str_no_find_account": "존재하지 않는 계정입니다.",
    "str_password_error": "비밀번호가 틀립니다.",
    "understand_agree_all_label" : "위의 모든 내용을 이해하고 동의합니다.",
    "noti_label": "알림",
    "noti_bitcoin_network_label" : "현재 비트코인 시세 정보를 불러올 수 없습니다. 네트워크 상태를 확인하신 후, 잠시 후에 다시 시도해 주세요.",
    "nation_label": "국가",
    "bitcoin_address_label": "비트코인 주소",
    "direct_sponsorship_label": "직접후원",
    "popup_logout_ask_label": "로그아웃 하시겠습니까?",
    "account_status_label": "계정상태",
    "signup_check_policy_label": "이용약관과 개인보호정책을 모두 확인해주세요.",
    "change_password_completed": "비밀번호 변경이 완료 되었습니다!",
};

const zh_data = {
    "id_hint" : "账号",
    "password_label" : "密码",
    "kor_label" : "韩国语",
    "eng_label" : "英语",
    "chn_label" : "简体中文",
    "jpn_label" : "日本语",
    "password_hint" : "密码",
    "email_hint" : "邮箱",
    "code_hint" : "代码",
    "signin_label" : "登录",
    "signup_label" : "加入会员",
    "find_password_label" : "忘记密码？",
    "join_member_label" : "注册会员",
    "find_id_label" : "找回账号",
    "send_email_label" : "发送邮件",
    "hello_label" : "您好",
    "welcome_title" : "欢迎!",
    "welcome_des_label" : "您的账号已创建!",
    "btn_ok_label" : "是",
    "id_available_label" : "该账号可以使用",
    "confirm_label" : "确认",
    "id_already_use_label" : "该账号已被使用",
    "password_error_label" : " 密码必须是8位以上15位以下的<英文+特殊字符+数字>组合",
    "confirm_password_hint" : "确认密码",
    "send_label" : "提交",
    "email_verify_label" : "您的邮箱已验证",
    "code_correct_label" : "请输入正确的代码",
    "reference_hint" : "参考",
    "reference_error_label" : "请务必参考",
    "transaction_hint" : "6位数交易密码",
    "confirm_transaction_hint" : "确认交易密码",
    "confirm_password_error_label" : "确认密码与密码不一致",
    "confirm_password_equal_error_label" : "确认交易密码与交易密码不一致.",
    "fail_signup_label" : "非常遗憾，注册失败",
    "korean_label" : "韩语",
    "english_label" : "英语",
    "chinese_label" : "简体中文",
    "japanese_label" : "日本语",
    "select_one_label" : "请选择一项",
    "select_your_language_label" : "请选择语言",
    "check_address_amount_label" : "请核对地址或金额",
    "bit_address_valid_label" : "有效位地址",
    "enter_bit_address_label" : "请输入正确的位地址",
    "available_balance_label" : "可用余额",
    "address_label" : "地址",
    "check_label" : "核对",
    "amount_label" : "总计",
    "withdraw_label" : "提现",
    "enter_address_label" : "请输入地址",
    "check_address_label" : "请核对地址",
    "enter_amount_label" : "请输入金额",
    "minimum_withdrawal_quantity_label" : "最小提现数量为0.0001比特币",
    "withdrawal_fee_charged_label" : "最小取款BTC数为0.002BTC.",
    "withdrawal_fee_charged_label_1" : "将收取2%的取款手续费.",
    "withdrawal_fee_charged_label_2" : "最小提取费为0.0005BTC.",
    "support_fee_charged_label" : "收取10%的系统手续费",
    "contact_history_label" : "历史记录",
    "charge_history_label" : "历史记录",
    "withdraw_history_label" : "历史记录",
    "support_history_label" : "历史记录",
    "history_label" : "历史记录",
    "history_value" : "历史记录",
    "withdraw_value" : "提现",
    "comment_successful_label" : "您的评论已被记录。",
    "donation_successful_label" : "您的捐款已成功",
    "withdraw_successful_label" : "您的提现成功",
    "check_current_balance_label" : "检查当前余额(BTC)",
    "check_current_password" : "密码不正确。",
    "failure_title" : "失败",
    "success_title" : "成功",
    "retry_label" : "重试",
    "user_guide_label" : "用户指南",
    "donate_label" : "捐助",
    "you_want_continue_donate_label" : "您确定继续捐助？",
    "no_label" : "不",
    "mycode_total_support_amount_label" : "收款总额",
    "total_support_amount_label" : "收款总额",
    "next_level_label" : "下一个级别",
    "after_receive_supports_level_label" : "捐助之后，您的账户升级为G",
    "after_receive_supports_level_label2" : "",
    "every_thanks_label" : "EVERY THANKS",
    "support_label" : "捐助",
    "support_value" : "捐助",
    "setting_label" : "设置",
    "profile_label" : "简介",
    "notification_label" : "通知",
    "contact_label" : "联系我们",
    "language_label" : "语言",
    "logout_label" : "退出系统",
    "edit_label" : "编辑",
    "save_label" : "保存",
    "saved_label" : "已保存。",
    "failed_label" : "失败的。",
    "register_time_zone_label" : "注册时区",
    "register_eth_address_label" : "登记ETH地址（FOR ERTS）",
    "transaction_password_label" : "交易密码",
    "dashboard_label" : "商业智能仪表盘",
    "my_level_label" : "我的等级",
    "summary_label" : "概要",
    "mycode_pending_amount_label" : "待收BTC",
    "pending_amount_label" : "待收BTC",
    "upgrade_level_more_donation_label" : "升级到G ",
    "upgrade_level_more_donation_label_2" : " 级别可获得更多捐款",
    "mentor_label" : "Mentee",
    "referral_label" : "推荐",
    "referral_hint" : "推荐人 ID",
    "no_mentor_id" : "您没有可以输入的导师ID。",
    "referral_error_label" : "请确保输入推荐人。",
    "supporters_label" : "SUPPORTERS",
    "rewarded_label" : "Rewards",
    "pending_label" : "Pending",
    "my_tree_label" : "我的树",
    "branch_label" : "分支",
    "date_label" : "日期",
    "detail_history_label" : "细节",
    "event_label" : "活动",
    "ok_label" : "OK",
    "check_your_email_confirm_label" : "您的邮件已发送，请检查您的电子邮箱。",
    "something_went_wrong_label" : "发生了错误，请确认您填写的信息无误。",
    "enter_id_label" : "请输入您的账号",
    "dorothy_amount_label" : "DOROTHY金额",
    "days_label" : "天",
    "hours_label" : "小时",
    "minutes_label" : "分钟",
    "seconds_label" : "秒",
    "dorothy_provides_level_des_label" : "多萝西将提供给所有16级以上的EVERY THANKS会员提供中奖机会。",
    "be_supported_label" : "收款总额",
    "charge_label" : "充值",
    "to_my_account_label" : "到我的账户",
    "dorothy_label" : "DOROTHY",
    "take_gift_from_god_label" : "让我们成为幸运的主人公",
    "share_give_your_hope_label" : "分享并给与希望",
    "category_label" : "分类",
    "service_label" : "服务",
    "coin_label" : "币",
    "message_label" : "消息",
    "level_me_message_label" : "请留言 (3~300)",
    "no_history_label" : "没有历史记录",
    "contact_value" : "联系",
    "angle_complete_label" : "完成",
    "congrats_label" : "恭喜!",
    "my_btc_address_label" : "我的比特币地址",
    "btc_address_copied_label" : "已复制我的比特币地址",
    "my_total_balance_label" : "我的总资产",
    "buy_btc_with_credit_card_label" : "使用信用卡购买比特币",
    "buy_btc_with_credit_card_label_simplex" : "BUY BTC WITH CREDIT CARD - simplex",
    "buy_btc_with_credit_card_label_indacoin" : "BUY BTC WITH CREDIT CARD - INDACOIN",
    "charge_value" : "充值",
    "cur_trans_pwd_label" : "当前交易密码",
    "new_trans_pwd_label" : "新交易密码",
    "confirm_new_trans_pwd_label" : "确认新的交易密码",
    "failed_change_pwd_label" : "修改密码失败",
    "change_label" : "修改",
    "change_pwd_label" : "修改密码",
    "cur_pwd_label" : "当前密码",
    "new_pwd_label" : "新密码",
    "confirm_pwd_label" : "确认新密码",
    "change_email_label" : "修改邮箱",
    "new_email_label" : "新邮箱",
    "authentication_code_label" : "认证代码为",
    "entered_email_verified_label" : "您输入的电子邮箱已通过验证",
    "login_failed" : "登录失败",
    "charge_noti_label" : "通知事项",
    "charge_noti_label_des_1" : "1. 充值少于0.000001 BTC时将不会被正常反映。",
    "charge_noti_label_des_2" : "2. 请点击上端的[copy]按钮来复制[MY BTC ADDRESS]地址，之后粘贴在BTC购买网站或BTC钱包汇款地址[Bitcoin address]上。",
    "charge_noti_label_des_3" : "3. 如果本人的信用卡（借记卡）不可用于海外在线支付则无法购买BTC。请联系信用卡客服中心添加相关服务来完成海外在线支付。",
    "charge_noti_label_des_4" : "4. 信用卡（借记卡）发行公司的海外加盟店结算限额政策可能导致无法购买BTC。无法进行结算时请向信用卡发行公司客服中心咨询。",
    "charge_noti_label_des_5" : "5. 在填写信用卡（现金卡）的信息时，请准确填入地址和个人信息。 如果信息出现不一致的情况，信用卡公司的批准可能会被拒绝。",
    "i_agree_with_both_label" : "我同意EVERY THANKS App的",
    "term_of_use_label" : "使用条款（需要点击）",
    "and_label" : "和",
    "privacy_policy_label" : "个人信息保护政策（需要点击）",
    "of_everythanks_app_label" : "的全部内容。",
    "app_push_setting_label" : "设置App Push提醒功能",
    "app_push_noti_label" : "通过Push功能设置为\"On\",每当EVERY THANKS发生捐助，待收BTC, DOROTHY抽奖等多种优惠和活动时， 您将会收到提醒短信。",
    "receive_full_noti_label": "接收全部提醒短信",
    "btc_recharge_noti_label": "BTC充值提醒",
    "donation_noti_label": "发生捐助提醒",
    "pending_occur_noti_label": "发生PENDING提醒",
    "pending_payment_label": "已支付PENDING捐助金提醒",
    "dorothy_lottery_schedule_label": "DOROTHY抽奖日期提醒",
    "answers_my_questions_label": "留言已回复提醒",
    "donation_recommendation_label": "推荐捐助对象公告栏",
    "my_voting_noti_label": "投票提醒",
    "comments_my_post_label": "留言提醒",
    "end_voting_my_post_label": "投票结束提醒",
    "selection_my_article_label": "选定捐助对象提醒",
    "comments_on_my_post_noti_label": "留言提醒",
    "end_of_voting_for_my_post_noti_label": "投票结束提醒",
    "selection_of_donation_destination_for_my_article_noti_label": "选定捐助对象提醒",
    "birthday": "出生年月日",
    "registration": "注册",
    "edit": "变更",
    "mobile": "电话号码",
    "phone_hint": "输入电话号码",
    "country_hint": "选择国家",
    "erts_paid": "预付 ERTS数量",
    "erts_complete": "已付ERTS数量",
    "achieve_date": "达成日期",
    "step_achieve": "达成等级",
    "payment_date": "支付日期",
    "quantity_paid": "预付数量",
    "erts_paid_guide": "* 当达到ERTS支付条件时，通过管理员确认，将ERTS 发送至会员登记的ETH 地址。如果支付ERTS 时会员的ETH地址尚未登记，则无法发送，因此请提前登记ETH地址.",
    "erts_complete_guide": "* 管理者已向用户登记的ETH地址支付ERTS代币的明细.",
    "erts_help_title": "何为ERTS?",
    "erts_help_content_1": "单击查看有关 ERTS 的其他信息 您可以查看有关 ERTS 的其他信息。还请更改每个等级的硬币支付详细信息。",
    "erts_help_content_2": "变更后的要求：",
    "erts_help_content_3": "<ERTS支付条件（锁定1年）>",
    "erts_help_content_4": "G1 捐赠级别：支付 100个 ERTS 币",
    "erts_help_content_5": "G3 捐赠级别：支付 200个 ERTS 币",
    "erts_help_content_6": "G6 捐赠级别：支付 200个 ERTS 币",
    "erts_help_content_7": "G9 捐赠级别：支付 300个 ERTS 币",
    "erts_help_content_8": "G12 捐赠级别：支付 400个 ERTS币",
    "erts_help_content_9": "G24 捐赠级别：支付 600个 ERTS币",
    "erts_help_content_10": "G36 捐赠级别：支付 900个 ERTS币",
    "erts_help_content_11": "G48 捐赠级别：支付 1,200个 ERTS 币",
    "erts_help_content_12": "G60 捐赠级别：支付 1,500个 ERTS 币",
    "erts_help_content_13": "G72 捐赠级别：支付 1,800个 ERTS 币",
    "erts_help_content_14": "G84 捐赠级别：支付 2,100个 ERTS 币",
    "erts_help_content_15": "G96 捐赠级别：支付 2,300个 ERTS 币",
    "erts_help_content_16": "G99 捐赠级别：支付 2,400个 ERTS 币",
    "erts_help_content_17": "ERTS币发行总量为14亿枚。 *超过10万人时可追加发行",
    "close": "关闭",
    "donation_recommendation_label2": "捐赠推荐论坛",
    "donation_recom_noti_label": "EVERY THANKS找到<span style='background-color: #ff5a57; font-weight: bold'>需要帮助的地方</span>，并直接转交<span style='background-color: #ff5a57; font-weight: bold'>全部的捐助款</span> \n \n 如果您提供本人的珍贵故事并向我们推荐需要捐赠的地方的话，我们将会以<span style='background-color: #ff5a57; font-weight: bold'>投票数为标准</span>来选择接受捐赠的地方",
    "donation_recom_noti_label0": "EVERY THANKS找到",
    "donation_recom_noti_label1": "需要帮助的地方",
    "donation_recom_noti_label2": "，并直接转交",
    "donation_recom_noti_label3": "全部的捐助款",
    "donation_recom_noti_label4": " \n \n 如果您提供本人的珍贵故事并向我们推荐需要捐赠的地方的话，我们将会以",
    "donation_recom_noti_label5": "投票数为标准",
    "donation_recom_noti_label6": "来选择接受捐赠的地方",
    "donation_recom_list_label": "捐赠推荐文目录",
    "contents_by_me_label": "我写的内容",
    "search_word_label": "检索语 (题目和本文)",
    "search_label": "搜索",
    "country_author_label": "作者的国家",
    "classification_label": "捐赠项目",
    "registration_order_label": "上传顺序",
    "classification1_label": "全部",
    "classification2_label": "支援孤儿院(保育员)",
    "classification3_label": "支援残障人士机构",
    "classification4_label": "支援无家可归者机构",
    "classification5_label": "支援医疗机构",
    "classification6_label": "支援单身母亲",
    "classification7_label": "支援疗养院",
    "classification8_label": "支援被战争迫害的难民",
    "classification9_label": "支援灾难地区",
    "classification10_label": "支援贫困&饥荒",
    "classification11_label": "支援动物保护机构",
    "classification12_label": "其他",
    "recom_order_label": "推荐顺序",
    "comment_order_label": "评论顺序",
    "completion_order": "按选择顺序",
    "selected_as_donee_label": "被选为捐赠者",
    "voting_label": "表决",
    "voting_end_label": "投票结束",
    "edit_article_noti1_label": "请写下关于需要捐赠地方的主要信息和需要捐赠的理由",
    "edit_article_noti2_label": "※ 和论坛主题无关的内容可能会在没有事先通知的情况下被删除",
    "classification_required_label":"捐赠项目",
    "choose_donee_label": "选择",
    "title_label":"题目",
    "title_placeholder_label":"请输入推荐文的题目",
    "donation_org_name_label": "捐赠处名称",
    "donation_org_placeholder_label": "请输入推荐的捐赠处名称",
    "donation_orgnum_label": "捐赠处联系方式",
    "select_country_label": "选择国家",
    "orgnum_placeholder_label": "请输入捐赠处的电话号码",
    "donation_org_address_label": "捐赠处地址",
    "org_address_placeholder_label": "请输入捐赠处地址",
    "Why_donation_label": "需要捐赠的理由和我的故事",
    "upload_label": "上传",
    "edit_article_success_label": "捐赠推荐文撰写完成",
    "edit_article_fail_label": "为了撰写捐赠推荐文，请您先填写以上所有内容",
    "article_noti1_label": "如果您需要捐赠的话，\n请点击下方的“投票心”按钮",
    "article_noti2_label": "※ 每一个帖子只可点击一次推荐键",
    "comment_send_label": "登记",
    "comment_placeholder_label": "请填写评论",
    "comment_warning_label": "请输入评论",
    "delete_warning_label": "您确定删除吗？",
    "yes_label": "是",
    "delete_label": "删除",
    "addinfo_noti_label": "需要补充会员注册的信息\n\n补充后的信息可在“个人资料”中进行查看和变更",
    "member_label": "会员",
    "member_contact_label": "会员联系方式",
    "member_contact_placeholder_label": "输入会员的电话号码",
    "addinfo_register_label": "登陆会员信息",
    "addinfo_success_label": "会员注册信息补充已经完成 \n 您将移到输入文字的界面",
    "addinfo_fail_label": "为了完成会员注册信息补充，请输入全部内容",
    "modify_label": "修改",
    "modification_fail_label": "当为1名以上投票时不可进行修改",
    "modification_success_label": "修改完成",
    "article_delete_waring_label": "如果您删除帖子的话投票详情将会被无效处理，并且删除之后也不可以撤回，您确定删除帖子吗？",
    "finish_label": "完成",
    "no_information": "No Information",
    "support_selection": "选择赞助与否",
    "support": "赞助",
    "donation": "捐助",
    "support_selection_desc": "如果选择赞助条款，到12等级为止,捐款中的一部分赞助给导师。\n如果选择捐助,捐款将全部转交给捐赠处。\n赞助条款有助于广泛宣传去中心捐赠平台EVERY THANKS。",
    "my_support_total_amount": "我的捐赠总额",
    "donation_status": "捐赠状况",
    "donation_status_desc": "捐赠处信息和所有捐赠使用状态\n可以再EVERY THANKS 网站确认！",
    "total_btc_supported": "收款总额(BTC)",
    "total_btc_rewarded": "奖励金总额（BTC)",
    "total_donation_amount": "捐款总额",
    "voting_donation_recommendation": "捐赠项目推荐",
    "voting_description_1": "实现捐款透明化",
    "voting_description_2": "请推荐捐赠项目，并参与投票。",
    "voting_register_1": "已登记的捐赠项目",
    "voting_register_2": "处",
    "str_report": "举报",
    "str_block": "屏蔽",
    "str_report_writer": "举报作者",
    "str_dorothy_info_1": "Dorothy",
    "str_dorothy_info_2": "活动规定",
    "str_dorothy_info_3": "及",
    "str_dorothy_info_4": "注意事项",
    "str_dorothy_info_5": "",
    "str_dorothy_info_rule_title": "活动规则 :",
    "str_dorothy_info_rule_content": "中奖金额:该月13G~16G的所有累积金为彩票中奖金。\n" +
        "中奖对象:达到16G以上的账户\n" +
        "中奖日期:每月1日 UTC +8 18:00\n" +
        "中奖方式:通过抽签抽取2名后累计金额各分配50%\n" +
        "中奖概率:当月新达到16G的账户中奖概率高，上月已经达成16G的账户越接近99G中奖概率就越高。",
    "str_dorothy_info_caution_title": "注意事项:",
    "str_dorothy_info_caution_content": "本活动为EVERY THANKS主办的活动，与APPLE无关。",
    "str_reported": "報了。",
    "str_member_withdraw": "注销会员",
    "str_withdraw_noti": "【注意】注销会员前，请仔细阅读以下内容。",
    "str_withdraw_noti_content": "您将无法编辑/删除已在应用程序中注册的内容与评论。如有必要，请在申请注销前对其进行编辑或删除。",
    "str_withdrawal": "注销",
    "str_withdraw_ask": "您想要注销会员吗？",
    "str_withdraw_prev": "已注销的账户。",
    "str_no_find_account": "此账户不存在.",
    "str_password_error": "密码错误.",
    "understand_agree_all_label" : "我理解并同意以上所有内容。",
    "noti_label": "警报",
    "noti_bitcoin_network_label" : "目前无法加载比特币价格信息。 请检查网络状态并稍后重试。",
    "nation_label": "国家",
    "bitcoin_address_label": "比特币地址",
    "direct_sponsorship_label": "直接赞助",
    "popup_logout_ask_label": "确定要退出吗？",
    "account_status_label": "帐户状态",
    "signup_check_policy_label": "请查看使用条款和个人信息保护政策的全部内容。",
    "change_password_completed": "您的密码更改已完成！",
};

const jp_data = {
    "id_hint" : "アカウント",
    "password_label" : "パスワード",
    "kor_label" : "韓国語",
    "eng_label" : "英語",
    "chn_label" : "中国語",
    "jpn_label" : "日本語",
    "password_hint" : "パスワード",
    "email_hint" : "メールアドレス",
    "code_hint" : "コード",
    "signin_label" : "ログイン",
    "signup_label" : "会員登録",
    "find_password_label" : "パスワードを忘れた場合",
    "join_member_label" : "無料登録",
    "find_id_label" : "アカウントを忘れた場合",
    "send_email_label" : "メール送信",
    "hello_label" : "こんにちは!",
    "welcome_title" : "ようこそ!",
    "welcome_des_label" : "アカウント作成が成功しました!",
    "btn_ok_label" : "OK",
    "id_available_label" : "使用可能なアカウント",
    "confirm_label" : "確認",
    "id_already_use_label" : "使用中のアカウント",
    "password_error_label" : " パスワードは、8桁以上15桁以下の英語+特殊文字+数字組み合わせである必要があります。;.",
    "confirm_password_hint" : "パスワード（確認）",
    "send_label" : "送信",
    "email_verify_label" : "メールアドレスが確認されました.",
    "code_correct_label" : "正しいコードをご入力ください",
    "reference_hint" : "参考資料",
    "reference_error_label" : "参考資料をご確認ください",
    "transaction_hint" : "取引パスワードは６桁",
    "confirm_transaction_hint" : "取引パスワード（確認）",
    "confirm_password_error_label" : "ご入力のパスワード（確認）がパスワードと一致しません.",
    "confirm_password_equal_error_label" : "ご入力の取引パスワード（確認）が取引パワードと一致しません",
    "fail_signup_label" : "無料会員登録失敗しました",
    "korean_label" : "韓国語",
    "english_label" : "英語",
    "chinese_label" : "中国語",
    "japanese_label" : "日本語",
    "select_one_label" : "一つをご選択ください",
    "select_your_language_label" : "言語をご選択ください",
    "check_address_amount_label" : "アドレスまたは金額をご確認ください",
    "bit_address_valid_label" : "ビットアドレスは有効です",
    "enter_bit_address_label" : "正しいビットアドレスをご入力ください",
    "available_balance_label" : "ご利用可能な残高",
    "address_label" : "アドレス",
    "check_label" : "確認",
    "amount_label" : "金額",
    "withdraw_label" : "出金",
    "enter_address_label" : "アドレスをご入力ください",
    "check_address_label" : "アドレスをご確認ください",
    "enter_amount_label" : "金額をご入力ください",
    "minimum_withdrawal_quantity_label" : "最低出金量は0.0001 BTCです",
    "withdrawal_fee_charged_label" : "最低出金額は0.002BTCです。",
    "withdrawal_fee_charged_label_1" : "２％の出金手数料がかかります。",
    "withdrawal_fee_charged_label_2" : "出金手数料は最小で0.0005BTCです。",
    "support_fee_charged_label" : "サポート手数料は10%です",
    "contact_history_label" : "履歴",
    "charge_history_label" : "履歴",
    "withdraw_history_label" : "履歴",
    "support_history_label" : "履歴",
    "history_label" : "履歴",
    "history_value" : "履歴",
    "withdraw_value" : "出金",
    "comment_successful_label" : "コメントが登録されました。",
    "donation_successful_label" : "会員様の寄付は成功しました",
    "withdraw_successful_label" : "あなたの撤退は成功しました",
    "check_current_balance_label" : "今の残高をご確認ください (BTC)",
    "check_current_password" : "パスワードが間違っています。",
    "failure_title" : "失敗",
    "success_title" : "成功",
    "retry_label" : "リトライ",
    "user_guide_label" : "ユーザーガイド",
    "donate_label" : "寄付",
    "you_want_continue_donate_label" : "寄付を続けてもよろしいでしょうか",
    "no_label" : "いいえ",
    "mycode_total_support_amount_label" : "いただいた総計",
    "total_support_amount_label" : "いただいた総計",
    "next_level_label" : "次のレベル",
    "after_receive_supports_level_label" : "サポートしたら次のレベルG",
    "after_receive_supports_level_label2" : "になります。",
    "every_thanks_label" : "EVERY THANKS",
    "support_label" : "サポート",
    "support_value" : "サポート",
    "setting_label" : "設定",
    "profile_label" : "プロフィール",
    "notification_label" : "お知らせ",
    "contact_label" : "お問い合わせ",
    "language_label" : "言語",
    "logout_label" : "ログアウト",
    "edit_label" : "訂正",
    "save_label" : "保存",
    "saved_label" : "保存された。",
    "failed_label" : "失敗しました。",
    "register_time_zone_label" : "タイムゾーンの登録",
    "register_eth_address_label" : "ETHアドレスを登録 (FOR ERTS)",
    "transaction_password_label" : "取引パワード",
    "dashboard_label" : "DASHBOARD",
    "my_level_label" : "私のレベル",
    "summary_label" : "概要",
    "mycode_pending_amount_label" : "Pending中のBTC",
    "pending_amount_label" : "Pending中のBTC",
    "upgrade_level_more_donation_label" : "寄付を増やすには、G ",
    "upgrade_level_more_donation_label_2" : "レベルにアップグレードしてください",
    "mentor_label" : "Mentee",
    "referral_label" : "推薦",
    "referral_hint" : "メント ID",
    "no_mentor_id" : "入力するメンターIDがありません。",
    "referral_error_label" : "オススメをぜひ入力してください。",
    "supporters_label" : "SUPPORTERS",
    "rewarded_label" : "Rewards",
    "pending_label" : "Pending",
    "my_tree_label" : "マイ TREE",
    "branch_label" : "Branch",
    "date_label" : "日付",
    "detail_history_label" : "詳細",
    "event_label" : "イベント",
    "ok_label" : "OK",
    "check_your_email_confirm_label" : "メールを送信しました。確認後、承認をお願いいたします。",
    "something_went_wrong_label" : "エラー発生、作成内容に問題がないのかご確認ください。",
    "enter_id_label" : "IDをご入力ください",
    "dorothy_amount_label" : "ドロシー（dorothy）金額",
    "days_label" : "日",
    "hours_label" : "時間",
    "minutes_label" : "分",
    "seconds_label" : "秒",
    "dorothy_provides_level_des_label" : "ドロシー当選の機会は、EVERY THANKS G16等級以上を達成した全ての会員様に提供します",
    "be_supported_label" : "いただいた総計",
    "charge_label" : "チャージ",
    "to_my_account_label" : "私のアカウントへ",
    "dorothy_label" : "ドロシー（dorothy）",
    "take_gift_from_god_label" : "幸運の主人公になりましょう♪",
    "share_give_your_hope_label" : "希望を共有し、ご寄付ください",
    "category_label" : "カテゴリ",
    "service_label" : "サービス",
    "coin_label" : "コイン",
    "message_label" : "メッセージ",
    "level_me_message_label" : "メッセージの内容を入力してください（3～300文字）",
    "no_history_label" : "履歴なし",
    "contact_value" : "コンタクト",
    "angle_complete_label" : "完了",
    "congrats_label" : "おめでとうございます!",
    "my_btc_address_label" : "私のBTC アドレス",
    "btc_address_copied_label" : "私のbtc アドレスがコピーペーストされました",
    "my_total_balance_label" : "私の総残高",
    "buy_btc_with_credit_card_label" : "クレジットカードでBTCを購入する",
    "buy_btc_with_credit_card_label_simplex" : "BUY BTC WITH CREDIT CARD - simplex",
    "buy_btc_with_credit_card_label_indacoin" : "BUY BTC WITH CREDIT CARD - INDACOIN",
    "charge_value" : "料金",
    "cur_trans_pwd_label" : "現在の取引パワード",
    "new_trans_pwd_label" : "新しい取引パワード",
    "confirm_new_trans_pwd_label" : "新しい取引パワード（確認）",
    "failed_change_pwd_label" : "パスワード変更失敗しました",
    "change_label" : "変更",
    "change_pwd_label" : "パスワード変更",
    "cur_pwd_label" : "現在のパスワード",
    "new_pwd_label" : "新しいパスワード",
    "confirm_pwd_label" : "新しいパスワード（確認）",
    "change_email_label" : "メールアドレス変更",
    "new_email_label" : "新しいメールアドレス",
    "authentication_code_label" : "認証コードは",
    "entered_email_verified_label" : "ご入力したメールアドレスが確認されました",
    "login_failed" : "ログインに失敗し",
    "charge_noti_label" : "案内事項",
    "charge_noti_label_des_1" : "1. 000001BTC未満のチャージ金額は反映されません。",
    "charge_noti_label_des_2" : "2. 上段の[copy]ボタンを押して[MY BTC ADDRESS]アドレスをコピーして、BTC購入サイトまたBTCウォレット送金アドレス[Bitcoin address]に貼り付けてください。",
    "charge_noti_label_des_3" : "3. 本人のクレジットカード(チェックカード)は海外オンライン決済がブロックされている場合、BTC購入できません。クレジットカードのカスタマーセンターに電話して、海外オンライン決済ができるようブロックを解除してください。",
    "charge_noti_label_des_4" : "4. クレジットカード(チェックカード)発行会社の海外加盟店決済限度についての政策によって、BTC購入ができない場合もあります。決済ができない時には、クレジットカード発行会社にお問い合わせください。",
    "charge_noti_label_des_5" : "5. クレジットカード(キャッシュカード)情報登録時に、住所と個人情報を正確にご登録してください。情報が一致しない場合、カード会社の承認が拒否される可能性があります。",
    "i_agree_with_both_label" : "EVERY THANKS Appの",
    "term_of_use_label" : "利用約款（クリック必要）",
    "and_label" : "と",
    "privacy_policy_label" : "個人情報保護政策（クリック必要）",
    "of_everythanks_app_label" : "にすべて同意します。",
    "app_push_setting_label" : "App Push通知の設定",
    "app_push_noti_label" : "Push項目を'On'にすることで、EVERY THANKSで発生するサポートの内訳、PENDING状態、 ドロシー抽選など、様々な特典とイベントに関する通知が受信します。",
    "receive_full_noti_label": "全通知受信",
    "btc_recharge_noti_label": "BTCチャージの通知",
    "donation_noti_label": "サポート発生の通知",
    "pending_occur_noti_label": "PENDING発生の通知",
    "pending_payment_label": "PENDING支給完了の通知",
    "dorothy_lottery_schedule_label": "ドロシー抽選日の通知",
    "answers_my_questions_label": "お問い合わせに回答完了の通知",
    "donation_recommendation_label": "寄付推薦掲示板",
    "my_voting_noti_label": "投稿の通知",
    "comments_my_post_label": "コメントの通知。",
    "end_voting_my_post_label": "コメント投票終了の通知",
    "selection_my_article_label": "寄付先選定の通知",
    "comments_on_my_post_noti_label": "コメントの通知。",
    "end_of_voting_for_my_post_noti_label": "コメント投票終了の通知",
    "selection_of_donation_destination_for_my_article_noti_label": "寄付先選定の通知",
    "birthday": "生年月日",
    "registration": "登録",
    "edit": "変更",
    "mobile": "電話番号",
    "phone_hint": "電話番号入力",
    "country_hint": "国家選択",
    "erts_paid": "ERTS 支給予定数量",
    "erts_complete": "ERTS 支給完了数量",
    "achieve_date": "達成日付",
    "step_achieve": "達成レベル",
    "quantity_paid": "支給予定数量",
    "payment_date": "支給日付",
    "erts_paid_guide": "* ERTS達成条件を満たしたら、管理者が確認後、会員が登録されたETHアドレスにERTSを振り込みます。支給時点までETHアドレスが登録されていないと支給できませんので、事前にETHアドレスをご登録ください。",
    "erts_complete_guide": "* ご登録済みのETHアドレスへERTSが支給された内容です。",
    "erts_help_title": "ERTSとは？",
    "erts_help_content_1": "ERTSに追加内容を表示するためにクリックするとERTSに関する追加内容を見ることができます。最後の職級別コイン支給内容も修正してください。",
    "erts_help_content_2": "変更後の要件：",
    "erts_help_content_3": "<ERTSコイン支給条件(1年間ロックアップ)>",
    "erts_help_content_4": "G1寄付等級：ERTSコイン100支給",
    "erts_help_content_5": "G3寄付等級：ERTSコイン200支給",
    "erts_help_content_6": "G6寄付等級：ERTSコイン200支給",
    "erts_help_content_7": "G9寄付等級：ERTSコイン300支給",
    "erts_help_content_8": "G12寄付等級：ERTSコイン400支給",
    "erts_help_content_9": "G24寄付等級：ERTSコイン600支給",
    "erts_help_content_10": "G36寄付等級：ERTSコイン900支給",
    "erts_help_content_11": "G48寄付等級：ERTSコイン1,200支給",
    "erts_help_content_12": "G60寄付等級：ERTSコイン1,500支給",
    "erts_help_content_13": "G72寄付等級：ERTSコイン1,800支給",
    "erts_help_content_14": "G84寄付等級：ERTSコイン2,100支給",
    "erts_help_content_15": "G96寄付等級：ERTSコイン2,300支給",
    "erts_help_content_16": "G99寄付等級：ERTSコイン2,400支給",
    "erts_help_content_17": "ERTSコインの総発行量は14億個です。 *100,000万人超の場合追加発行可能",
    "close": "閉じる",
    "donation_recommendation_label2": "寄付推薦の掲示板",
    "donation_recom_noti_label": "EVERY THANKSは<span style='background-color: #ff5a57; font-weight: bold'>支援が必要な場</span>合所を訪れ、<span style='background-color: #ff5a57; font-weight: bold'>全ての寄付金</span>を直接寄付しています \n\n ご本人様の大切なお話と共に寄付が必要な場所を推薦していただきましたら、<span style='background-color: #ff5a57; font-weight: bold'>会員様の投票数を基準</span>に寄付される場所を選定します",
    "donation_recom_noti_label0": "EVERY THANKSは",
    "donation_recom_noti_label1": "支援が必要な場",
    "donation_recom_noti_label2": "合所を訪れ、",
    "donation_recom_noti_label3": "全ての寄付金",
    "donation_recom_noti_label4": "を直接寄付しています \n\n ご本人様の大切なお話と共に寄付が必要な場所を推薦していただきましたら、",
    "donation_recom_noti_label5": "会員様の投票数を基準",
    "donation_recom_noti_label6": "に寄付される場所を選定します",
    "donation_recom_list_label": "寄付推薦文の目録",
    "contents_by_me_label": "私が書いた文",
    "search_word_label": "検索語 (タイトルと本文)",
    "search_label": "検索する",
    "country_author_label": "作成者の国",
    "classification_label": "寄付先の区分",
    "registration_order_label": "登録順",
    "classification1_label": "全体",
    "classification2_label": "孤児院(保育園)",
    "classification3_label": "障害者施設",
    "classification4_label": "ホームレス施設",
    "classification5_label": "医療支援",
    "classification6_label": "未婚母施設",
    "classification7_label": "老人療養院",
    "classification8_label": "戦争被害支援",
    "classification9_label": "災害支援",
    "classification10_label": "貧困&飢饉支援",
    "classification11_label": "動物福祉",
    "classification12_label": "その他の支援",
    "recom_order_label": "推薦順",
    "comment_order_label": "コメント順",
    "completion_order": "選定完了順に",
    "selected_as_donee_label": "寄付先選定完了",
    "voting_label": "投票中",
    "voting_end_label": "投票終了",
    "edit_article_noti1_label": "寄付が必要な場所の主な情報と寄付が必要な理由についてお書きください",
    "edit_article_noti2_label": "※ 掲示板のテーマと関係ない文は、事前通知なく削除されることがあります",
    "classification_required_label":"寄付先の区分",
    "choose_donee_label": "選択する",
    "title_label":"タイトル",
    "title_placeholder_label":"推薦文のタイトルを入力してください",
    "donation_org_name_label": "寄付先の名称",
    "donation_org_placeholder_label": "おすすめする寄付先の名称を入力してください",
    "donation_orgnum_label": "寄付先の連絡先",
    "select_country_label": "国家を選択する",
    "orgnum_placeholder_label": "寄付先の電話番号を入力してください",
    "donation_org_address_label": "寄付先の住所",
    "org_address_placeholder_label": "寄付先の住所を入力してください",
    "Why_donation_label": "寄付が必要な理由と私の話",
    "upload_label": "登録する",
    "edit_article_success_label": "寄付推薦文の作成が完了されました",
    "edit_article_fail_label": "寄付の推薦文を作成するために全ての内容を入力してください",
    "article_noti1_label": "寄付が必要だと考えると、\n下記の「投票する」 ハートボタンをお押してください",
    "article_noti2_label": "※ 推薦ボタンは掲示物1つで一度だけ押すことができます",
    "comment_send_label": "登録",
    "comment_placeholder_label": "コメントに書いてください",
    "comment_warning_label": "コメントを入力してください",
    "delete_warning_label": "本当に削除しますか？",
    "yes_label": "はい",
    "delete_label": "削除する",
    "addinfo_noti_label": "会員情報の追加登録が必要です\n\n追加入力された情報は「プロフィール」で確認·変更が可能です",
    "member_label": "会員",
    "member_contact_label": "会員の電話番号",
    "member_contact_placeholder_label": "会員の電話番号を入力する",
    "addinfo_register_label": "会員情報を登録する",
    "addinfo_success_label": "会員情報の追加登録が完了されましたライティング・ページに移動します",
    "addinfo_fail_label": "会員情報の追加登録をするため、全ての内容を入力してください",
    "modify_label": "修正する",
    "modification_fail_label": "1人以上で投票した場合、修正ができません。",
    "modification_success_label": "修正が完了されました",
    "article_delete_waring_label": "掲示物を削除すると、投票された内訳は無効となり、削除後に戻すことはできません。本当に削除しますか？",
    "finish_label": "完成--no_Jap",
    "no_information": "No Information",
    "support_selection": "後援可否選択",
    "support": "後援",
    "donation": "寄付",
    "support_selection_desc": "後援を選択すると、12 LEVELまでサポート金の一部は上位のメントへ後援になります。\n寄付を選択すると、サポート金の全てが寄付先に渡されます。\n後援システムは脱中心化の寄付プラットフォーム「EVERY THANKS」を広く知らせるのに役立っています。",
    "my_support_total_amount": "マイ後援合計",
    "donation_status": "寄付状況",
    "donation_status_desc": "寄付先の情報と全ての寄付金の使用状況は\nEVERY THANKSの公式ホームページでご確認できます。",
    "total_btc_supported": "いただいた総計(BTC)",
    "total_btc_rewarded": "奖励金总额（BTC)",
    "total_donation_amount": "寄付総額",
    "voting_donation_recommendation": "寄付先推薦",
    "voting_description_1": "私たちの透明寄付",
    "voting_description_2": "寄付先の推薦・投票にご参考ください。",
    "voting_register_1": "登録済みの寄付先",
    "voting_register_2": "個",
    "str_report": "コンテンツを報告",
    "str_block": "コンテンツをブロック",
    "str_report_writer": "編集者を報告",
    "str_dorothy_info_1": "ドロシー",
    "str_dorothy_info_2": "イベントのルール",
    "str_dorothy_info_3": "及び",
    "str_dorothy_info_4": "注意事項",
    "str_dorothy_info_5": "",
    "str_dorothy_info_rule_title": "イベントのルール",
    "str_dorothy_info_rule_content": "当選金:13G~16Gまでのすべての後援金は積立てられ、ドロシー宝くじ当選金になります。\n" +
        "当選対象：16G以上を達成したアカウント\n" +
        "当選日：毎月1日 UTC+818:00\n" +
        "当選方法：ラムダムで二つのアカウントを抽選後、積立金を50%ずつ配ります。\n" +
        "当選確率：該当月に新規で16G達成した人が最も高く、16G達成翌月からは99段階に近いほど当選確率が上がります。",
    "str_dorothy_info_caution_title": "注意事項:",
    "str_dorothy_info_caution_content": "本イベントはEVERYTHANKSが主管するイベントであり、APPLEとは何の関係もありません。",
    "str_reported": "報告されました。",
    "str_member_withdraw": "退会手続き",
    "str_withdraw_noti": "【重要】退会前に必ずお読みください。",
    "str_withdraw_noti_content": "アプリに登録されているコンテンツやコメントの編集・削除はできません。必要に応じて、退会要求の前に編集または削除してください。",
    "str_withdrawal": "退会する",
    "str_withdraw_ask": "退会しますか？",
    "str_withdraw_prev": "既に退会したアカウントです。",
    "str_no_find_account": "このアカウントは存在しません",
    "str_password_error": "パスワードが間違っています",
    "understand_agree_all_label" : "上記の内容をすべて理解し同意します。",
    "noti_label": "お知らせ",
    "noti_bitcoin_network_label" : "現在ビットコインの相場情報を読み込めません。 ネットワークの状態を確認してから、しばらくしてからもう一度お試しください。",
    "nation_label": "国家",
    "bitcoin_address_label": "ビットコインアドレス",
    "direct_sponsorship_label": "直接スポンサー",
    "popup_logout_ask_label": "ログアウトしますか？",
    "account_status_label": "アカウントステータス",
    "signup_check_policy_label": "利用約款と個人情報保護政策をすべてご確認お願いします。",
    "change_password_completed": "비밀번호 변경이 완료 되였습니다.",
};




