import {API_PATH} from "./api";

export const FLAG_SELECTOR_OPTION_LIST = [
    { id: '+93', name: 'Afghanistan', displayText: 'Afghanistan(+93)', englishName: 'Afghanistan', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/af.png" },
    { id: '+244', name: 'Angola', displayText: 'Angola(+244)', englishName: 'Angola', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/al.png" },
    { id: '+54', name: 'Argentina', displayText: 'Argentina(+54)', englishName: 'Argentina', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/ar.png" },
    { id: '+61', name: 'Australia', displayText: 'Australia(+61)', englishName: 'Australia', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/au.png" },
    { id: '+43', name: 'Austria', displayText: 'Austria(+43)', englishName: 'Austria', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/at.png" },
    { id: '+994', name: 'Azerbaijan', displayText: 'Azerbaijan(+994)', englishName: 'Azerbaijan', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/az.png" },
    { id: '+880', name: 'Bangladesh', displayText: 'Bangladesh(+880)', englishName: 'Bangladesh', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bd.png" },
    { id: '+375', name: 'Belarus', displayText: 'Belarus(+375)', englishName: 'Belarus', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/by.png" },
    { id: '+501', name: 'Belize', displayText: 'Belize(+501)', englishName: 'Belize', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bz.png" },
    { id: '+591', name: 'Bolivia', displayText: 'Bolivia(+591)', englishName: 'Bolivia', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bo.png" },
    { id: '+267', name: 'Botswana', displayText: 'Botswana(+267)', englishName: 'Botswana', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bw.png" },
    { id: '+673', name: 'Brunei', displayText: 'Brunei(+673)', englishName: 'Brunei', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/br.png" },
    { id: '+229', name: 'Benin', displayText: 'Benin(+229)', englishName: 'Benin', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bj.png" },
    { id: '+975', name: 'Bhutan', displayText: 'Bhutan(+501)', englishName: 'Bhutan', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/bt.png" },
    { id: '+1', name: 'Canada', displayText: 'Canada(+1)', englishName: 'Canada', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/ca.png" },
    { id: '+1', name: 'U.S.A', displayText: 'U.S.A(+1)', englishName: 'U.S.A', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/us.png" },
    { id: '+238', name: 'CapeVerd', displayText: 'CapeVerd(+238)', englishName: 'CapeVerd', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/cv.png" },
    { id: '+235', name: 'Chad', displayText: 'Chad(+235)', englishName: 'Chad', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/td.png" },
    { id: '+56', name: 'Chile', displayText: 'Chile(+56)', englishName: 'Chile', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/cl.png" },
    { id: '+86', name: 'China', displayText: 'China(+86)', englishName: 'China', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/cn.png" },
    { id: '+886', name: 'Taiwan', displayText: 'Taiwan(+886)', englishName: 'Taiwan', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/tw.png" },
    { id: '+57', name: 'Colombia', displayText: 'Colombia(+86)', englishName: 'Colombia', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/co.png" },
    { id: '+242', name: 'Congo', displayText: 'Congo(+242)', englishName: 'Congo', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/cg.png" },
    { id: '+53', name: 'Cuba', displayText: 'Cuba(+53)', englishName: 'Cuba', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/cu.png" },
    { id: '+385', name: 'Croatia', displayText: 'Croatia(+385)', englishName: 'Croatia', locale: 'zh-CN', flag: API_PATH + "/static/global/img/flags/hr.png" },
    { id: '+81', name: 'Japan', displayText: 'JP(+81)', englishName: 'JAPAN', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/jp.png"},
    { id: '+82', name: '대한민국', displayText: 'KR(+82)', englishName: 'South Korea', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/kr.png"},
    { id: '+45', name: 'Denmark', displayText: 'Denmark(+45)', englishName: 'Denmark', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/dk.png" },
    { id: '+20', name: 'Egypt', displayText: 'Egypt(+20)', englishName: 'Egypt', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/eg.png" },
    { id: '+593', name: 'Ecuador', displayText: 'Ecuador(+593)', englishName: 'Ecuador', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ec.png" },
    { id: '+251', name: 'Ethiopia', displayText: 'Ethiopia(+1)', englishName: 'Ethiopia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/et.png" },
    { id: '+679', name: 'Fiji', displayText: 'Fiji(+679)', englishName: 'Fiji', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/fj.png" },
    { id: '+358', name: 'Finland', displayText: 'Finland(+358)', englishName: 'Finland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/fi.png" },
    { id: '+63', name: 'Pilipinas', displayText: 'Pilipinas(+63)', englishName: 'Pilipinas', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ph.png" },
    { id: '+90', name: 'Turkey', displayText: 'Turkey(+1)', englishName: 'Turkey', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/tr.png" },
    { id: '+41', name: 'Switzerland', displayText: 'Switzerland(+41)', englishName: 'Switzerland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ch.png" },
    { id: '+34', name: 'Spain', displayText: 'Spain(+34)', englishName: 'USA', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/es.png" },
    { id: '+963', name: 'Syria', displayText: 'Syria(+963)', englishName: 'Syria', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/sy.png" },
    { id: '+60', name: 'Malaysia', displayText: 'Malaysia(+60)', englishName: 'Malaysia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/my.png" },
    { id: '+84', name: 'Vietnam', displayText: 'Vietnam(+84)', englishName: 'Vietnam', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/vn.png" },
    { id: '+62', name: 'Indonesia', displayText: 'Indonesia(+62)', englishName: 'Indonesia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/id.png" },
    { id: '+66', name: 'Thai', displayText: 'Thai(+66)', englishName: 'Thai', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/th.png" },
    { id: '+852', name: 'Hongkong', displayText: 'Hongkong(+852)', englishName: 'Hongkong', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/hk.png" },
    { id: '+91', name: 'India', displayText: 'India(+91)', englishName: 'India', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/in.png" },
    { id: '+65', name: 'Singapore', displayText: 'Singapore(+65)', englishName: 'Singapore', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/sg.png" },
    { id: '+31', name: 'Netherlands', displayText: 'Netherlands(+31)', englishName: 'Netherlands', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/nl.png" },
    { id: '+49', name: 'Germany', displayText: 'Germany(+49)', englishName: 'Germany', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/de.png" },
    { id: '+7', name: 'Russia', displayText: 'Russia(+7)', englishName: 'Russia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ru.png" },
    { id: '+41', name: 'Switzerland', displayText: 'Switzerland(+41)', englishName: 'Switzerland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ch.png" },
    { id: '+44', name: 'Unitedkingdom', displayText: 'Unitedkingdom(+44)', englishName: 'Unitedkingdom', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/gb.png" },
    { id: '+420', name: 'Czechrep', displayText: 'Czechrep(+420)', englishName: 'Czechrep', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/cz.png" },
    { id: '+48', name: 'Poland', displayText: 'Poland(+48)', englishName: 'Poland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/pl.png" },
    { id: '+33', name: 'France', displayText: 'France(+33)', englishName: 'France', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/fr.png" },
    { id: '+30', name: 'Greece', displayText: 'Greece(+30)', englishName: 'Greece', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/gr.png" },
    { id: '+36', name: 'Hungary', displayText: 'Hungary(+36)', englishName: 'Hungary', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/hu.png" },
    { id: '+32', name: 'Belgium', displayText: 'Belgium(+32)', englishName: 'Belgium', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/be.png" },
    { id: '+351', name: 'Portugal', displayText: 'Portugal(+351)', englishName: 'Portugal', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/pt.png" },
    { id: '+353', name: 'Ireland', displayText: 'Ireland(+353)', englishName: 'Ireland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ie.png" },
    { id: '+354', name: 'Iceland', displayText: 'Iceland(+354)', englishName: 'Iceland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/is.png" },
    { id: '+356', name: 'Malta', displayText: 'Malta(+356)', englishName: 'Malta', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/mt.png" },
    { id: '+358', name: 'Finland', displayText: 'Finland(+358)', englishName: 'Finland', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/fi.png" },
    { id: '+377', name: 'Monaco', displayText: 'Monaco(+377)', englishName: 'Monaco', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/mc.png" },
    { id: '+386', name: 'Slovenia', displayText: 'Slovenia(+386)', englishName: 'Slovenia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/si.png" },
    { id: '+39', name: 'Italy', displayText: 'Italy(+39)', englishName: 'Italy', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/it.png" },
    { id: '+966', name: 'Saudiarabia', displayText: 'Saudiarabia(+966)', englishName: 'Saudiarabia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/sa.png" },
    { id: '+968', name: 'Oman', displayText: 'Oman(+968)', englishName: 'Oman', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/om.png" },
    { id: '+962', name: 'Jordan', displayText: 'Jordan(+962)', englishName: 'Jordan', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/sa.png" },
    { id: '+964', name: 'Iraq', displayText: 'Iraq(+964)', englishName: 'Iraq', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/iq.png" },
    { id: '+98', name: 'Iran', displayText: 'Iran(+98)', englishName: 'Iran', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ir.png" },
    { id: '+972', name: 'Israel', displayText: 'Israel(+972)', englishName: 'Israel', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/il.png" },
    { id: '+974', name: 'Qatar', displayText: 'Qatar(+974)', englishName: 'Qatar', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/qa.png" },
    { id: '+965', name: 'Kuwait', displayText: 'Kuwait(+965)', englishName: 'Kuwait', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/kw.png" },
    { id: '+233', name: 'Ghana', displayText: 'Ghana(+233)', englishName: 'Ghana', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/gh.png" },
    { id: '+241', name: 'Gabon', displayText: 'Gabon(+241)', englishName: 'Gabon', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ga.png" },
    { id: '+234', name: 'Nigeria', displayText: 'Nigeria(+234)', englishName: 'Nigeria', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ng.png" },
    { id: '+212', name: 'Morocco', displayText: 'Morocco(+212)', englishName: 'Morocco', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ma.png" },
    { id: '+230', name: 'Mauritius', displayText: 'Mauritius(+230)', englishName: 'Mauritius', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/mu.png" },
    { id: '+213', name: 'Algeria', displayText: 'Algeria(+213)', englishName: 'Algeria', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/dz.png" },
    { id: '+251', name: 'Ethiopia', displayText: 'Ethiopia(+251)', englishName: 'Ethiopia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/et.png" },
    { id: '+254', name: 'Kenya', displayText: 'Kenya(+254)', englishName: 'Kenya', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ke.png" },
    { id: '+216', name: 'Tunisia', displayText: 'Tunisia(+216)', englishName: 'Tunisia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/tn.png" },
    { id: '+502', name: 'Guatemala', displayText: 'Guatemala(+502)', englishName: 'Guatemala', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/gt.png" },
    { id: '+52', name: 'Mexico', displayText: 'Mexico(+52)', englishName: 'Mexico', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/mx.png" },
    { id: '+55', name: 'Brazil', displayText: 'Brazil(+55)', englishName: 'Brazil', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/br.png" },
    { id: '+593', name: 'Ecuador', displayText: 'Ecuador(+593)', englishName: 'Ecuador', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/ec.png" },
    { id: '+56', name: 'Chile', displayText: 'Chile(+56)', englishName: 'Chile', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/cl.png" },
    { id: '+57', name: 'Colombia', displayText: 'Colombia(+57)', englishName: 'Colombia', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/co.png" },
    { id: '+507', name: 'Panama', displayText: 'Panama(+507)', englishName: 'Panama', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/pa.png" },
    { id: '+595', name: 'Paraguay', displayText: 'Paraguay(+595)', englishName: 'Paraguay', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/py.png" },
    { id: '+51', name: 'Peru', displayText: 'Peru(+51)', englishName: 'Peru', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/pe.png" },
    { id: '+64', name: 'Newzealand', displayText: 'Newzealand(+64)', englishName: 'Newzealand', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/nz.png" },
    { id: '+7', name: 'Kazakhstan', displayText: 'Kazakhstan(+7)', englishName: 'Kazakhstan', locale: 'en-US', flag: API_PATH + "/static/global/img/flags/kz.png" },
];

export const DEFAULT_AREA_CODE = FLAG_SELECTOR_OPTION_LIST[0].id;

export const ITEM_LENGTH = 20;

export const REPORT_TYPE_POST = 0;
export const REPORT_TYPE_COMMENT = 1;

export const REPORT_PROC_TYPE_POST = 0;
export const REPORT_PROC_TYPE_BLOCK = 1;
export const REPORT_PROC_TYPE_MAKER = 2;
