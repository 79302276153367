import React from 'react';
import styled from 'styled-components';
import NavigationBar from './components/NavigationBar';
import Donut from './components/Donut';
import API from './utils/api';
import {BoxTitle, DashboardBox, Dollars, DollarsDorothy} from './styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const ColorWell = styled.div`
  width: 17px;
  height: 17px;
  margin-right: 12px;
  display: inline-block;
  position: relative;
  top: 4px;
`;

const SummaryTitle = styled.h4`
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 100;
  line-height: 17px;
  white-space: nowrap;
`;

const Hr = styled.hr`
  border: none;
  border-top: 1px solid #E7E7EF;
  margin: 20px 0;
`;

const StrongCount = styled.strong`
  display: inline-block;
  font-size: 24px;
  margin-left: 24px;
  margin-top: 12px;
`;

const BranchButton = styled.div`
  font-size: 14px;
  background-color: #E7E7EF;
  color: #666A80;
  padding: 8px 24px;
  display: inline-block;
  margin-right: 16px;
  border-radius: 24px;
  user-select: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: #666A80;
    color: white;
    font-weight: bold;
  }
`;

const MenteeButton = styled.div`
  font-size: 14px;
  background-color: #FF4C89;
  color: white;
  padding: 8px 24px;
  display: inline-block;
  border-radius: 24px;
  user-select: none;
  cursor: pointer;
`;

const TreeChartSvg = styled.svg`
  text {
    text-anchor: middle;
    fill: #666A80;
    font-size: 16px;
  }
`;

export default class MyCode extends React.Component {

    state = {
        pending: 0,
        total: 0,
        referral: 0,
        supporters: 0,
        reward: 0,
        sel_branch: GlobalState.sel_branch,
        branch_data: [null,null,null,null,null,null,null,null,null,null,null,null],
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "my_code";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
        });

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user;
        }

        let branch_data = [null, null, null, null, null, null, null, null, null, null, null, null];
        for (let i = 0; i < 12; i++) {
            const branchData = await API.branchData(i);
            if (branchData.status === 'success') {
                branch_data[i] = branchData;
            }
        }

        const sel_branch = GlobalState.sel_branch;

        this.setState({
            sel_branch: sel_branch,
            branch_data: branch_data,
            pending: branch_data[sel_branch] == null ? 0 : branch_data[sel_branch].pending,
            total: branch_data[sel_branch] == null ? 0 : parseInt(branch_data[sel_branch].amount),
            referral: branch_data[sel_branch] == null ? 0 : branch_data[sel_branch].referral,
            supporters: branch_data[sel_branch] == null ? 0 : branch_data[sel_branch].supporters,
            reward: branch_data[sel_branch] == null ? 0 : branch_data[sel_branch].reward
        });
    }

    render() {
        const props = this.props;
        const user = this.state.me;

        const support_dollars = ((user.btcSupported * user.btcPrice).toFixed(2)).toLocaleString();
        const support_btc = user.btcSupported.toFixed(6);

        const reward_dollars = (user.btcRewardDollar.toFixed(2)).toLocaleString();
        const reward_btc = user.btcReward.toFixed(6);

        const pending_dollars = ((user.btcPendding * user.btcPrice).toFixed(2)).toLocaleString();
        const pending_btc = user.btcPendding.toFixed(6);

        const {lang_data, sel_branch, branch_data, referral, total, supporters, pending, reward} = this.state;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={lang_data.dashboard_label}
                lang_data={lang_data}/>
            <div style={{background: '#E974A9', padding: 24}}>
                <DashboardBox style={{display: 'flex', justifyContent: 'center', height: 146, padding: 20}}>
                    <div style={{
                        width: "50%",
                        marginTop: 30,
                        textAlign: 'center',
                        fontSize: 12
                    }}>
                        <div style={{
                            width: 110,
                            height: 70,
                            borderRadius: 30,
                            margin: '0 auto',
                            marginBottom: 2,
                            boxShadow: '0 0 0px #999',
                        }}>
                            <img src="/static/img/main_icon_supporter.png" width="110" style={{
                                verticalAlign: 'middle',
                                marginRight: 0,
                                display: user.rank === 'SUPPORTER' ? 'block' : 'none'
                            }}/>
                            <img src="/static/img/main_icon_angel.png" width="110" style={{
                                verticalAlign: 'middle',
                                marginRight: 0,
                                display: user.rank === 'ANGEL' ? 'block' : 'none'
                            }}/>
                            <img src="/static/img/main_icon_arch.png" width="110" style={{
                                verticalAlign: 'middle',
                                marginRight: 0,
                                display: user.rank === 'ARCH' ? 'block' : 'none'
                            }}/>
                            <img src="/static/img/main_icon_seraphim.png" width="110" style={{
                                verticalAlign: 'middle',
                                marginRight: 0,
                                display: user.rank === 'SERAPHIM' ? 'block' : 'none'
                            }}/>
                        </div>
                        <span style={{color: 'rgb(102, 106, 128)', fontWeight: 'bold'}}>{user.rank}</span>
                    </div>
                    <Donut
                        guage={user.level / 99}
                        level={`G${user.level}`}
                        description={lang_data.my_level_label}
                    />
                </DashboardBox>
            </div>
            <div style={{background: '#f7f7fa', padding: 24}}>
                <BoxTitle>{lang_data.summary_label}</BoxTitle>
                <DashboardBox style={{
                    padding: 24,
                    marginTop: 12,
                    marginBottom: 24
                }}>
                    <SummaryTitle>
                        <ColorWell style={{backgroundColor: '#0085FF'}}/>{lang_data.mycode_total_support_amount_label}
                    </SummaryTitle>
                    <div style={{padding: '10px 0 0 24px'}}>
                        <DollarsDorothy style={{color: '#0085FF'}}>{support_btc}BTC</DollarsDorothy>
                        <Dollars style={{
                            marginLeft: 10,
                            fontWeight: 'normal',
                            color: '#9C9FB1',
                            fontSize: 20
                        }}>{support_dollars}</Dollars>
                    </div>
                    <Hr/>
                    <SummaryTitle>
                        <ColorWell style={{backgroundColor: '#B131FF'}}/>{lang_data.total_btc_rewarded}
                    </SummaryTitle>
                    <div style={{padding: '10px 0 0 24px'}}>
                        <DollarsDorothy style={{color: '#B131FF'}}>{reward_btc}BTC</DollarsDorothy>
                        <Dollars style={{
                            marginLeft: 10,
                            fontWeight: 'normal',
                            color: '#9C9FB1',
                            fontSize: 20
                        }}>{reward_dollars}</Dollars>
                    </div>
                    <Hr/>
                    <SummaryTitle>
                        <ColorWell style={{backgroundColor: '#AEB2C7'}}/>{lang_data.mycode_pending_amount_label}
                    </SummaryTitle>
                    <div style={{padding: '10px 0 0 24px'}}>
                        <DollarsDorothy style={{color: '#616161'}}>{pending_btc}BTC</DollarsDorothy>
                        <Dollars style={{
                            marginLeft: 10,
                            fontWeight: 'normal',
                            color: '#9C9FB1',
                            fontSize: 20
                        }}>{pending_dollars}</Dollars>
                    </div>
                    <div className="error" style={{display: user.btcPendding !== 0 ? 'block' : 'none'}}>
                        {lang_data.upgrade_level_more_donation_label} {user.level + 1} {lang_data.upgrade_level_more_donation_label_2}
                    </div>
                    <Hr/>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <SummaryTitle>
                                <ColorWell style={{backgroundColor: '#FF4C89'}}/>{lang_data.mentor_label}
                            </SummaryTitle>
                            <StrongCount>{user.bellow_all_count}</StrongCount>
                        </div>
                        <div style={{flex: 1}}>
                            <SummaryTitle>
                                <ColorWell style={{backgroundColor: '#9CF2BA'}}/>{`Over G1`}
                            </SummaryTitle>
                            <StrongCount>{user.countBellow}</StrongCount>
                        </div>
                        <div style={{flex: 1}}>
                            <SummaryTitle>
                                <ColorWell style={{backgroundColor: '#FF815A'}}/>{'Supporters'}
                            </SummaryTitle>
                            <StrongCount>{user.bellow_support_all_count}</StrongCount>
                        </div>
                    </div>
                    <Hr/>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <SummaryTitle>
                                <ColorWell style={{backgroundColor: '#B131FF'}}/>{lang_data.rewarded_label}
                            </SummaryTitle>
                            <StrongCount>{user.bellow_reward_all_count}</StrongCount>
                        </div>
                        <div style={{flex: 1}}>
                            <SummaryTitle>
                                <ColorWell style={{backgroundColor: '#AEB2C7'}}/>{lang_data.pending_label}
                            </SummaryTitle>
                            <StrongCount>{user.bellow_pending_all_count}</StrongCount>
                        </div>
                        <div style={{flex: 1}}/>
                    </div>
                </DashboardBox>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <BoxTitle style={{paddingTop: 8}}>{lang_data.my_tree_label}</BoxTitle>
                    </div>
                    <div style={{flex: '1 1 -10%'}}>
                        <MenteeButton
                            onClick={() => {
                                GlobalState.sel_branch = sel_branch;
                                props.history.push('/mentee_list/' + sel_branch)
                            }}>View Mentee ID</MenteeButton>
                    </div>
                </div>

                <DashboardBox style={{
                    padding: 20,
                    marginTop: 12,
                    marginBottom: 24
                }}>
                    <div style={{width: '100%', overflowX: 'auto', height: 45, whiteSpace: 'nowrap'}}>
                        {([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map((e, i) =>
                            <BranchButton className={sel_branch === i ? 'active' : ''} key={i} onClick={() => {
                                if (branch_data[i] == null || sel_branch === i) return;
                                this.setState({
                                    sel_branch: i,
                                    pending: branch_data[i].pending,
                                    total: parseInt(branch_data[i].amount),
                                    referral: branch_data[i].referral,
                                    supporters: branch_data[i].supporters,
                                    reward: branch_data[i].reward,
                                });
                            }}>{parseInt(i) + 1} {lang_data.branch_label}</BranchButton>
                        )}
                    </div>
                    {/*<TreeChart props={{*/}
                    {/*    pendding: pending,*/}
                    {/*    referral: referral,*/}
                    {/*    total: total,*/}
                    {/*    supporters: supporters}}/>*/}
                    <TreeChartSvg width="100%" height="100%" viewBox="0 0 400 300">
                        <g transform="translate(0,20)">
                            <g transform="translate(0,250)">
                                <g transform="translate(40,0)">
                                    <text dy=".71em" y="9" x="0">Mentee</text>
                                    <text dy=".71em" y={referral < 5 ? -34 : -34 - referral} x="0">{referral}</text>
                                </g>
                                <g transform="translate(120,0)">
                                    <text dy=".71em" y="9" x="0">Over G1</text>
                                    <text dy=".71em" y={total < 5 ? -34 : -34 - total} x="0">{total}</text>
                                </g>
                                <g transform="translate(200,0)">
                                    <text dy=".71em" y="9" x="0">Supporters</text>
                                    <text dy=".71em" y={parseInt(supporters) < 5 ? -34 : -34 - parseInt(supporters)}
                                          x="0">{parseInt(supporters)}</text>
                                </g>
                                <g transform="translate(280,0)">
                                    <text dy=".71em" y="9" x="0">Pending</text>
                                    <text dy=".71em" y={pending < 5 ? -34 : -34 - pending} x="0">{pending}</text>
                                </g>
                                <g transform="translate(360,0)">
                                    <text dy=".71em" y="9" x="0">Rewards</text>
                                    <text dy=".71em" y={reward < 5 ? -34 : -34 - reward} x="0">{reward}</text>
                                </g>
                            </g>
                            <line y1="0" y2="0" x2="400" stroke="#E7E7EF"/>
                            <line y1="20%" y2="20%" x2="400" stroke="#E7E7EF"/>
                            <line y1="40%" y2="40%" x2="400" stroke="#E7E7EF"/>
                            <line y1="60%" y2="60%" x2="400" stroke="#E7E7EF"/>
                            <line y1="80%" y2="80%" x2="400" stroke="#E7E7EF"/>
                            <rect x="20" width="40" y={referral < 5 ? '237' : 237 - referral}
                                  height={referral < 5 ? '4' : referral} rx="10" ry="10" fill="#FF4C89"/>
                            <rect x="100" width="40" y={total < 5 ? '237' : 237 - parseInt(total)}
                                  height={parseInt(total)< 5 ? '4' : parseInt(total)} rx="10" ry="10" fill="#62EC91"/>
                            <rect x="180" width="40" y={supporters < 5 ? '237' : 237 - supporters}
                                  height={supporters < 5 ? '4' : supporters} rx="10" ry="10" fill="#FF815A"/>
                            <rect x="260" width="40" y={pending < 5 ? '237' : 237 - pending}
                                  height={pending < 5 ? '4' : pending} rx="10" ry="10" fill="#AEB2C7"/>
                            <rect x="340" width="40" y={reward < 5 ? '237' : 237 - reward}
                                  height={reward < 5 ? '4' : reward} rx="10" ry="10" fill="#B131FF"/>
                        </g>
                    </TreeChartSvg>
                </DashboardBox>
            </div>
        </>
    }
}
