import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

//Purcharse 5단계화면
export default class ChargeCardDetail extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        card_number: '',
        card_date: '',
        card_sec_code: '',
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_card_detail";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user
        }
    }

    render() {

        const props = this.props;
        const user = this.state.me;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Enter card details
            </GuideTitle>

            <div style={{margin: 21}}>
                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Card Number
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({card_number: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Expriration Date
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({card_date: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Card Security Code
                </div>
                <Input type="text" placeholder={''} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20, marginBottom: 40}}
                       onChange={(e) => this.setState({card_sec_code: e.currentTarget.value})}/>

                <Button className="green" onClick={
                    async () => {
                        if (this.state.card_number === '' || this.state.card_date === '' || this.state.card_sec_code === '') {
                            alert("Incorrect input info, please check.");
                            return;
                        }

                        let result = await API.cardDetail(user.userId, this.state.card_number, this.state.card_date, this.state.card_sec_code);
                        if (result.status === 'success') {
                            window.location.href = ('/#/Charge/ChargeMakePayment');
                        }
                    }
                } > Continue </Button>
            </div>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

