import React from 'react';
import NavigationBar from './components/NavigationBar';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {NoticeDetail} from "./components/NoticeDetail";

class Notification extends React.PureComponent {
    state = {
        notices : [],
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        showNoticeDetail : false,
        notice_title : '',
        notice_content : '',
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "notification";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        let getNotices = await API.notices();
        if (getNotices.status === 'success') {
            this.setState({
                notices: getNotices.data
            });
        }
    }

    async componentWillUnmount() {
        document.getElementById('app_lang').value = this.state.language;
    }

    render() {
        const {lang_data, notices, showNoticeDetail, notice_title, notice_content} = this.state;
        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <div style={{
                backgroundColor: '#E7E7EF',
                color: 'black',
                fontWeight: 'bold',
                lineHeight: '60px',
                fontSize: 18,
                paddingLeft: 20,
            }}>
                {lang_data.notification_label}
            </div>
            <div style={{ textAlign: 'center', marginTop: 24, display: notices.length === 0 ? 'block' : 'none'}}>No Notifications</div>
            {(notices || []).map((e, i) =>
                <div style={{width: '100%', display: 'block', height: 'auto'}} key={i} onClick={async () => {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(e.content, 'text/html');
                    var stringToHTML = doc.body;
                    this.setState({
                        showNoticeDetail: true,
                        notice_title: e.title,
                        notice_content: stringToHTML.innerHTML,
                    });
                    setTimeout(function() {
                        document.getElementById('div_description').innerHTML = stringToHTML.innerHTML;
                    }, 500);
                }}>
                    <div style={{width: '100%', display: 'block', height: 'auto'}}>
                        <div style={{width: '100%', display: 'block', height: 'auto'}}>
                            <div style={{display: 'block', paddingTop: 5, paddingBottom: 5, paddingRight: 15, paddingLeft: 15, borderBottom: 3,
                            borderBottomStyle:'solid', borderBottomColor: '#f7f7f7', cursor: 'pointer'}}>
                                <div style={{fontSize: 16, lineHeight: 3, color: '#000', wordBreak: 'break-all'}}>{e.title}</div>
                                <div style={{fontSize: 12, lineHeight: 2, marginTop: 6, color: '#a2a2a2', wordBreak: 'break-all'}}>{e.createdAt}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <NoticeDetail
                enabled={showNoticeDetail}
                title={notice_title}
                description={notice_content}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        showNoticeDetail: false,
                    });
                }}/>
        </div>
    }
}

export default Notification;
