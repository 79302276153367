import React from 'react';
import styled from 'styled-components';
import NavigationBar from './components/NavigationBar';
import {DollarsDorothy} from './styled-components';
import moment from 'moment';
import API from './utils/api';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";
import {DorothyPopup} from "./components/DorothyPopup";

const Wrap = styled.div`
    background: linear-gradient(#E26FA4, #F8CB9B);
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

const Circle = styled.div`
    width: 256px;
    height: 256px;
    border-radius: 128px;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -128px;

    &.second {
        width: 220px;
        height: 220px;
        border-radius: 110px;
        margin-left: -110px;
        top: 18px;
        background: rgba(255, 255, 255, 0.5);
    }

    &.third {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        margin-left: -100px;
        top: 10px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ${DollarsDorothy} {
            color: #00B5C3;
        }
    }
`;

const Counter = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CounterDigit = styled.div`
    flex: 1;
    text-align: center;
    .digit {
        font-size: 36px;
        font-weight: bold;
    }
    .unit {
        font-size: 10px;
    }
`;

const Box = styled.div`
    background: white;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 8px 15px #455B6326;
    ul.history {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            height: 75px;
            display: flex;
            align-items: center;
            ${DollarsDorothy} {
                width: 56px;
                height: 56px;
                border-radius: 28px;
                background-color: #00B5C3;
                line-height: 56px;
                text-align: center;
                color: white;
                margin-right: 12px;
            }

            .from {
                font-size: 16px;
                font-weight: bold;
            }

            .date {
                font-size: 14px;
                color: #666A80;
            }
        }
    }
`;


class Dorothy extends React.PureComponent {
    timerId = null;
    state = {
        duration: null,
        dorothyDollars: 0,
        dorothyHistory: [],
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        showRulePopup: false,
        showNotiPopup: false,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "dorothy";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        var firstDayOfNextMonth = moment().startOf('month').add(1, 'month').hours(19).minutes(0).seconds(0);

        const me = await API.me();
        if (me.status === 'success') {
            GlobalState.me = me.user;
            firstDayOfNextMonth = moment().startOf('month').add(1, 'month').hours(19).minutes(0).seconds(0);
            var remind_day = moment.duration(firstDayOfNextMonth - moment()).days();
            if (me.user.test_day > 0) {
                var test_remind_date = moment(firstDayOfNextMonth).add(-me.user.test_day, 'day');
                var curDay = moment();
                var test_firstDay = moment.duration(curDay - test_remind_date).days(); //curDay.diff(test_remind_date, 'days');

                if (test_firstDay < 0) {
                    const firstDayOfNextMonth1 = moment().startOf('month').add(2, 'month').hours(19).minutes(0).seconds(0);
                    test_remind_date = moment(firstDayOfNextMonth1).add(-me.user.test_day, 'day');
                    test_firstDay = curDay.diff(test_remind_date, 'days');
                }
                firstDayOfNextMonth = test_remind_date;
            }
        }

        this.setState({
            duration: moment.duration(firstDayOfNextMonth - moment()),
        });
        // const resp = await API.getDorothyTotal();
        // //const historyResp = await API.getDorothyHistory();
        // const historyResp = await API.getDorothyWinning();
        // if (resp.status === 'success' && historyResp) {
        //     this.setState({
        //         dorothyDollars: resp.data,
        //         dorothyHistory: historyResp.data,
        //     });
        // }

        await this.update_dorothy();

        this.timerId = setInterval(() => {
            if (this.state.duration.minutes() <= 0 && this.state.duration.seconds() <= 0) {
                firstDayOfNextMonth = moment().startOf('month').add(1, 'month').hours(19).minutes(0).seconds(0);
                remind_day = moment.duration(firstDayOfNextMonth - moment()).days();
                if (me.user.test_day > 0) {
                    var test_remind_date = moment(firstDayOfNextMonth).add(-me.user.test_day, 'day');
                    var curDay = moment();
                    var test_firstDay = moment.duration(curDay - test_remind_date).days(); //curDay.diff(test_remind_date, 'days');

                    if (test_firstDay < 0) {
                        var temp = moment().startOf('month').hours(19).minutes(0).seconds(0);
                        test_remind_date = moment(temp).add(1, 'month');
                        // const firstDayOfNextMonth1 = moment().startOf('month').add(2, 'month').hours(17).minutes(0).seconds(0);
                        // test_remind_date = moment(firstDayOfNextMonth1).add(-me.user.test_day, 'day');
                        // test_firstDay = curDay.diff(test_remind_date, 'days');
                    }
                    firstDayOfNextMonth = test_remind_date;
                } else {
                    var curDay = parseInt(moment().format("D"));
                    var curHours = parseInt(moment().format("H"));
                    if (curDay == 1 && curHours < 19) {
                        firstDayOfNextMonth = moment().startOf('month').hours(19).minutes(0).seconds(0);
                    }
                }
                this.setState({
                    duration: moment.duration(firstDayOfNextMonth - moment()),
                });
                this.update_dorothy();
            }

            var curDay = parseInt(moment().format("D"));
            var curHours = parseInt(moment().format("H"));
            if (curDay == 1 && curHours < 19) {
                firstDayOfNextMonth = moment().startOf('month').hours(19).minutes(0).seconds(0);
            }
            this.setState({
                duration: moment.duration(firstDayOfNextMonth - moment()),
            });
        }, 500);
    }

    async update_dorothy() {
        const resp = await API.getDorothyTotal();
        const historyResp = await API.getDorothyHistory();
        if (resp.status === 'success' && historyResp) {
            this.setState({
                dorothyDollars: resp.data,
                dorothyHistory: historyResp.data,
            });
        }
    }

    async componentWillUnmount() {
        const me = await API.me();
        if (me.status === 'success') {
            GlobalState.me = me.user;
        }

        if (this.timerId != null)
            clearInterval(this.timerId);
    }

    render() {
        const {lang_data, dorothyDollars, duration, dorothyHistory, showRulePopup, showNotiPopup} = this.state;
        return (
            <Wrap>
                <DorothyPopup
                    enabled={showRulePopup}
                    title={lang_data.str_dorothy_info_rule_title}
                    description={lang_data.str_dorothy_info_rule_content}
                    onButtonClick={() => {
                        this.setState({ showRulePopup: false })
                    }}/>
                <DorothyPopup
                    enabled={showNotiPopup}
                    title={lang_data.str_dorothy_info_caution_title}
                    description={lang_data.str_dorothy_info_caution_content}
                    onButtonClick={() => {
                        this.setState({ showNotiPopup: false })
                    }}/>
                <NavigationBar
                    onBackClick={() => {
                        this.props.history.goBack();
                    }}
                    lang_data={lang_data}/>
                <div style={{ padding: '0 24px' }}>
                    <div style={{ marginTop: 24, position: 'relative', height: 256 }}>
                        <Circle>
                            <Circle className="second">
                                <Circle className="third">
                                    <div style={{ fontSize: 14, marginBottom: 4 }}>{lang_data.dorothy_amount_label}</div>
                                    <DollarsDorothy style={{ fontSize: 28 }}>{dorothyDollars.toFixed(6)}BTC</DollarsDorothy>
                                </Circle>
                            </Circle>
                        </Circle>
                    </div>
                    <Counter style={{ margin: '24px 0' }}>
                        <CounterDigit>
                            <div className="digit">{duration ? duration.days() : '-'}</div>
                            <div className="unit">{lang_data.days_label}</div>
                        </CounterDigit>
                        <CounterDigit>
                            <div className="digit">{duration ? duration.hours() : '-'}</div>
                            <div className="unit">{lang_data.hours_label}</div>
                        </CounterDigit>
                        <CounterDigit>
                            <div className="digit">{duration ? duration.minutes() : '-'}</div>
                            <div className="unit">{lang_data.minutes_label}</div>
                        </CounterDigit>
                        <CounterDigit>
                            <div className="digit">{duration ? duration.seconds() : '-'}</div>
                            <div className="unit">{lang_data.seconds_label}</div>
                        </CounterDigit>
                    </Counter>
                    <Box style={{ marginBottom: 24 }}>
                        <h4>{lang_data.dorothy_winning_result_label}</h4>
                        <div style={{ minHeight: 150, maxHeight: 150, overflow: 'auto' }}>
                            <ul className="history">
                                {dorothyHistory.map((e, i) => <li key={i}>
                                    <DollarsDorothy>{i === 1 ? "1st" : dorothyHistory.length === 1 ? "1st":"2nd"}</DollarsDorothy>
                                    <div>
                                        <div className="from">{moment(e.createDate).format('DD.MM.YYYY')}</div>
                                        <div className="from"> {(e.amountBtc == null || e.amountBtc == 'undefined'  ? e.btc.toFixed(6) : e.amountBtc.toFixed(6))} BTC</div>
                                        <div className="date"> ID : { e.user_id != null ? e.user_id.substr(0, 3).padEnd(e.user_id.length, '*') : e.userId.substr(0, 3).padEnd(e.userId.length, '*')}</div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </Box>
                    <Box style={{ marginBottom: 24 }}>
                        <h4>INFO</h4>
                        <div style={{ color: '#2A2D42', fontSize: 12 }}>
                            {lang_data.str_dorothy_info_1}<span style={{ color: '#0070c0' }} onClick={() => {
                            this.setState({showRulePopup: true})
                        }
                        }>{lang_data.str_dorothy_info_2}</span>{lang_data.str_dorothy_info_3}<span style={{ color: '#0070c0' }} onClick={() => {
                            this.setState({showNotiPopup: true})
                        }
                        }>{lang_data.str_dorothy_info_4}</span>{lang_data.str_dorothy_info_5}
                    </div>
                    </Box>
                </div>
            </Wrap>
        );
    }
}

export default Dorothy;
