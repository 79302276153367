import React from 'react';
import NavigationBar from './components/NavigationBar';
import API from './utils/api';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";
import {ErtsDetailPopup} from "./components/ErtsDetailPopup";
import Common from "./utils/Common";
import ReactCustomFlagSelect from './components/react-custom-flag-select/index.ts';
import {DEFAULT_AREA_CODE, FLAG_SELECTOR_OPTION_LIST} from "./utils/Constants";
import './components/react-custom-flag-select/react-custom-flag-select.css';
import './components/react-custom-datepicker.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ProfileWrapper = styled.div`
  padding: 20px;
  background-color: #f7f7fa;
  flex: 1;

  h3 {
    font-size: 18px;
    line-height: 24px;
    color: black;
    margin: 0;
    margin-bottom: 20px;

    .circle {
      position: relative;
      top: 2px;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      margin-right: 10px;
      display: inline-block;
      background: linear-gradient(to bottom, #df5897, #c1acd2);
    }
  }
`;

const ProfileBox = styled.div`
  box-shadow: 1px 1px 10px 3px #455B6326;
  background: white;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 24px;

  h4 {
    margin: 0;
    margin-bottom: 12px;

    img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .value-wrap {
    height: 24px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    margin-top:10px
    .nation_select {
      width: 100px;

      button {
        width: 100%;
      }
    }
  }

  .value {
    color: #9C9FB1;
    font-size: 14px;
    border:none;

    &.password {
      letter-spacing: 8px;
      font-weight: bold;
    }

    &.value-eth {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    
    &.input {
      border: none;
    }
  }

  button {
    float: right;
    color: #87c6ff;
    background: white;
    font-size: 16px;
    border-radius: 4px;
    min-width: 48px;
    height: 24px;
    line-height: 24px;
    border:none;
    &:hover {
      color: #87c6ff;
    }
  }

  .ic_info {
    width: 15px;
    height: 15px;
    border: #e974a9 1px solid;
    border-radius: 10px;
    text-align: center;
    color: #e974a9;
    font-size: 12px;
    font-weight: bolder;
    margin-left: 2px;
  }
`;

const SettingBox = styled.div`
  box-shadow: 1px 1px 10px 3px #455B6326;
  background: white;
  padding: 24px 24px 1px;
  margin-bottom: 24px;
  border-radius: 24px;

  h4 {
    margin: 0 0 12px 0;

    img {
      vertical-align: middle;
    }
  }

  .value-wrap {
    height: 20px;
    margin-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E7E7EF;
    margin-bottom: 24px;

    .value {
      color: #9C9FB1;
      font-size: 14px;

      &.password {
        letter-spacing: 12px;
        font-weight: bold;
      }
    }

    div {
      background-image: url(/static/img/btn_switch_off.png);
      background-repeat: no-repeat;
      border: none;
      padding: 15px 0;
      font-weight: bold;
      font-size: 16px;
      display: block;
      width: 46px;
      height: 20px;
      margin-left: auto;

      .active {
        background-image: url(/static/img/btn_switch_on.png);
      }
    }
  }

  .ic_arrow {
    width: 30px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`;

const SettingBoxTitle = styled.div`
  padding-left: 0px;
  margin-left: 0px;
  border-bottom: none;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  margin-top: 10px;
`;

const Radiobox = styled.div`
  height: 20px;
  background: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
    margin-top: 5px;

  .radiobox {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
`;

class Profile extends React.PureComponent {
    state = {
        utc: GlobalState.me.utc == null ? '-4' : GlobalState.me.utc,
        isEmail: GlobalState.me.isEmail,
        isAutoLogin: GlobalState.me.isAutoLogin,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        showUpdateSuccessPopup: false,
        showUpdateFailPopup: false,
        birthday: GlobalState.me.birthday == null ? "" : new Date(GlobalState.me.birthday),
        birthday_check: GlobalState.me.birthday != null,
        phone_check: GlobalState.me.phone != null,
        phone: GlobalState.me.phone == null ? "" : GlobalState.me.phone,
        showErtsPaidPopup: false,
        showErtsCompletePopup: false,
        ertsPaidList: [],
        ertsCompleteList: [],
        paid_btc: 0,
        unpaid_btc: 0,
        me: GlobalState.me,
        areaCode: GlobalState.me.phone_nation == null ? DEFAULT_AREA_CODE : GlobalState.me.phone_nation,
        support_type : GlobalState.me.phone == null ? 0 : GlobalState.me.support_type,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "profile";
        document.getElementById('date_picker_input').readOnly = true;
        const language = document.getElementById('app_lang').value;
        await readLangData(language);

        this.setState({
            language: language,
            lang_data: getLangData(),
        });

        const me = await API.me();
        if (me.status === 'success') {
            const utc = me.user.utc == null ? '-4' : me.user.utc;
            const birthday = me.user.birthday == null ? "" : new Date(me.user.birthday);
            const phone = me.user.phone == null ? "" : me.user.phone;
            const areaCode = me.user.phone_nation == null ? DEFAULT_AREA_CODE : me.user.phone_nation;
            this.setState({
                me: me.user,
                utc: utc,
                isEmail: me.user.isEmail,
                isAutoLogin: me.user.isAutoLogin,
                birthday: birthday,
                birthday_check: birthday !== "",
                areaCode: areaCode,
                phone: phone,
                phone_check: phone !== "",
                support_type: me.user.support_type,
            });
            GlobalState.me = me.user;
        } else {
            if (GlobalState.me != null) {
                const utc = GlobalState.me.utc == null ? '-4' : GlobalState.me.utc;
                const birthday = GlobalState.me.birthday == null ? "" : new Date(GlobalState.me.birthday);
                const phone = GlobalState.me.phone == null ? "" : GlobalState.me.phone;
                const areaCode = GlobalState.me.phone_nation == null ? DEFAULT_AREA_CODE : GlobalState.me.phone_nation;
                this.setState({
                    me: GlobalState.me,
                    utc: utc,
                    isEmail: GlobalState.me.isEmail,
                    isAutoLogin: GlobalState.me.isAutoLogin,
                    birthday: birthday,
                    birthday_check: birthday !== "",
                    areaCode: areaCode,
                    phone: phone,
                    phone_check: phone !== "",
                    support_type: GlobalState.me.support_type,
                });
            }
        }

        const ert = await API.getErts();
        if (ert.status === "success") {
            this.setState({
                ertsPaidList: ert.pay_list,
                ertsCompleteList: ert.pay_complete_list,
                paid_btc: ert.paid_btc,
                unpaid_btc: ert.unpaid_btc
            });
        }
    }

    async onClickUpdateSetting(type, value) {
        await API.updateSetting(type, value);
    }

    async onClickUpdatePhone(phone_nation, phone) {
        await API.updatePhone(phone_nation, phone);
        GlobalState.me.phone_nation = phone_nation;
        GlobalState.me.phone = phone;
    }

    async onClickUpdateUtc() {
        const status = await API.setUtc(this.state.utc);
        if (status.status === 'success') {
            this.setState({
                showUpdateSuccessPopup: true
            });
        } else {
            this.setState({
                showUpdateFailPopup: true
            });
        }
    }

    async onClickUpdateSupportType() {
        const status = await API.setSupportType(this.state.me.userId, this.state.support_type);
        if (status.status === 'success') {
            this.setState({
                showUpdateSuccessPopup: true
            });
        } else {
            this.setState({
                showUpdateFailPopup: true
            });
        }
    }

    render() {
        const user = this.state.me;
        const {areaCode, showUpdateSuccessPopup, lang_data, showUpdateFailPopup, showErtsPaidPopup, ertsPaidList, showErtsCompletePopup, ertsCompleteList, birthday, birthday_check, phone, phone_check, utc, unpaid_btc, paid_btc, isEmail, isAutoLogin} = this.state;
        const currentItem = Common.find(FLAG_SELECTOR_OPTION_LIST, {id: areaCode})[0];

        return <div style={{display: 'flex', height: '100%', flexDirection: 'column'}}>
            <Popup
                enabled={showUpdateSuccessPopup}
                isSuccess={true}
                title={lang_data.profile_label}
                description={lang_data.saved_label}
                onButtonClick={() => {
                    this.setState({showUpdateSuccessPopup: false})
                }}/>
            <Popup
                enabled={showUpdateFailPopup}
                isSuccess={false}
                title={lang_data.profile_label}
                description={lang_data.failed_label}
                onButtonClick={() => {
                    this.setState({showUpdateFailPopup: false})
                }}/>
            <ErtsDetailPopup
                enabled={showErtsPaidPopup}
                paidList={ertsPaidList}
                title={lang_data.erts_paid}
                colNames={[
                    lang_data.achieve_date,
                    lang_data.step_achieve,
                    lang_data.quantity_paid,
                ]}
                guide={lang_data.erts_paid_guide}
                closeTitle={lang_data.close}
                onClose={() => {
                    this.setState({showErtsPaidPopup: false})
                }}/>
            <ErtsDetailPopup
                enabled={showErtsCompletePopup}
                paidList={ertsCompleteList}
                title={lang_data.erts_complete}
                colNames={[
                    lang_data.payment_date,
                    lang_data.step_achieve,
                    lang_data.quantity_paid,
                ]}
                guide={lang_data.erts_complete_guide}
                closeTitle={lang_data.close}
                onClose={() => {
                    this.setState({showErtsCompletePopup: false})
                }}/>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <ProfileWrapper>
                <h3>
                    <div className="circle"/>
                    {user.userId}</h3>
                <ProfileBox>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.email_hint}</h4>
                    <div className="value-wrap">
                        <span className="value">{user.email}</span>
                        <button onClick={() => {
                            this.props.history.push('/settings/change-email');
                        }}>{lang_data.edit_label}</button>
                    </div>
                    <h4>
                        <img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.password_hint}
                    </h4>
                    <div className="value-wrap">
                        <span className="value password">&middot; &middot; &middot; &middot; &middot;</span>
                        <button onClick={() => {
                            this.props.history.push('/settings/change-password');
                        }}>{lang_data.edit_label}</button>
                    </div>
                    <h4>
                        <img src="/static/img/profile_sub_icon.png" width="22"/>
                        {lang_data.birthday}
                    </h4>
                    <div className="value-wrap" style={{display: 'flex'}}>
                        <span className="value" style={{flex: 1}}>
                            <DatePicker dateFormat="yyyy-MM-dd" selected={birthday}
                                        showMonthDropdown showYearDropdown
                                        id="date_picker_input"
                                        onChange={(date) => this.setState({birthday: date})}/>
                        </span>
                        <button onClick={() => {
                            if (birthday === '') {
                                return;
                            }
                            this.onClickUpdateSetting(13, moment(birthday).format("YYYY-MM-DD")).then(r => this.setState({
                                birthday_check: true,
                                showUpdateSuccessPopup: true
                            }, () => {
                                GlobalState.me.birthday = birthday
                            }));
                        }}>{!birthday_check ? lang_data.registration : lang_data.edit_label}</button>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.mobile}</h4>
                    <div className="value-wrap" style={{display: "flex"}}>
                        <ReactCustomFlagSelect
                            attributesWrapper={{id: 'areaCodeWrapper', tabIndex: '1'}}
                            attributesButton={{id: 'areaCodeButton'}}
                            attributesInput={{id: 'areaCode', name: 'areaCode'}}
                            name={'areaCode'}
                            value={currentItem.id}
                            disabled={false}
                            showSearch={true}
                            fields={['name', 'locale', 'displayText', 'englishName']}
                            showArrow={true}
                            animate={true}
                            optionList={FLAG_SELECTOR_OPTION_LIST}
                            classNameWrapper="nation_select"
                            classNameContainer=""
                            classNameOptionListContainer=""
                            classNameOptionListItem=""
                            classNameDropdownIconOptionListItem={''}
                            customStyleWrapper={{}}
                            customStyleContainer={{border: 'none', fontSize: '12px'}}
                            customStyleOptionListItem={{}}
                            customStyleOptionListContainer={{maxHeight: '100px', overflow: 'auto', width: '200px'}}
                            onChange={areaCode => {
                                this.setState({areaCode: areaCode});
                            }}/>
                        <input style={{
                            marginLeft: 5,
                            width: "40%",
                            outline: "none",
                            padding: 3,
                            marginRight: 5,
                            border: "none"
                        }}
                               placeholder={lang_data.phone_hint}
                               value={phone}
                               onChange={(e) => {
                                   if (!isNaN(e.currentTarget.value)) this.setState({phone: e.currentTarget.value})
                               }}/>
                        <button onClick={() => {
                            if (phone === '') return;
                            this.onClickUpdatePhone(areaCode, phone).then(r => this.setState({
                                phone_check: true,
                                showUpdateSuccessPopup: true
                            }));
                        }}>  {phone_check ? lang_data.edit_label : lang_data.registration}  </button>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.account_status_label}</h4>
                    <div style={{display: 'flex', marginBottom: 5}}>
                        <Radiobox onClick={() => {
                            this.setState({support_type: 0})
                        }}>
                            <div className={'radiobox'}
                                 style={{backgroundImage: this.state.support_type === 0 ? 'url(/static/img/ic_radio_on.png)' : 'url(/static/img/ic_radio_off.png)'}}/>
                            {lang_data.support}
                        </Radiobox>
                        <Radiobox style={{marginLeft: 10}} onClick={() => {
                            this.setState({support_type: 1})
                        }}>
                            <div className={'radiobox'}
                                 style={{backgroundImage: this.state.support_type === 1 ? 'url(/static/img/ic_radio_on.png)' : 'url(/static/img/ic_radio_off.png)'}}/>
                            {lang_data.donation}
                        </Radiobox>

                        <button style={{marginLeft: "auto", marginRight: 0}} onClick={() => {
                            this.onClickUpdateSupportType().then(r => {
                                GlobalState.me.support_type = this.state.support_type
                            });
                        }}>{lang_data.save_label}</button>
                    </div>
                </ProfileBox>
                <ProfileBox>
                    <h4 style={{marginTop: 15}}>
                        <img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.register_time_zone_label}
                    </h4>
                    <div className="value-wrap">
                        <span className="value" style={{color: '#000000'}}>UTC</span>
                        <select value={utc} style={{marginLeft: 20, width: 100, height: 30, paddingLeft: 10}}
                                onChange={(e) => this.setState({utc: e.currentTarget.value})}>
                            <option value='-12'>-12</option>
                            <option value='-11'>-11</option>
                            <option value='-10'>-10</option>
                            <option value='-9'>-9</option>
                            <option value='-8'>-8</option>
                            <option value='-7'>-7</option>
                            <option value='-6'>-6</option>
                            <option value='-5'>-5</option>
                            <option value='-4'>-4</option>
                            <option value='-3'>-3</option>
                            <option value='-2'>-2</option>
                            <option value='-1'>-1</option>
                            <option value='0'>0</option>
                            <option value='+1'>+1</option>
                            <option value='+2'>+2</option>
                            <option value='+3'>+3</option>
                            <option value='+4'>+4</option>
                            <option value='+5'>+5</option>
                            <option value='+6'>+6</option>
                            <option value='+7'>+7</option>
                            <option value='+8'>+8</option>
                            <option value='+9'>+9</option>
                            <option value='+10'>+10</option>
                            <option value='+11'>+11</option>
                            <option value='+12'>+12</option>
                            <option value='+13'>+13</option>
                            <option value='+14'>+14</option>
                        </select>
                        <button onClick={() => {
                            this.onClickUpdateUtc().then(r => {
                                GlobalState.me.utc = utc
                            });
                        }}>{lang_data.save_label}</button>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.transaction_password_label}</h4>
                    <div className="value-wrap">
                        <span className="value password">&middot; &middot; &middot; &middot; &middot;</span>
                        <button onClick={() => {
                            this.props.history.push('/settings/change-tx-password');
                        }}>{lang_data.edit_label}</button>
                    </div>
                </ProfileBox>
                <ProfileBox>
                    <div style={{display: "flex", alignItems: "center"}} onClick={() => {
                        this.props.history.push('/settings/help');
                    }}>
                        <div style={{color: "#e974a9", marginLeft: "auto"}}>ERTS</div>
                        <div className="ic_info">i</div>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.register_eth_address_label}
                    </h4>
                    <div className="value-wrap">
                        <span className="value">
                            {user.ethAddress == null || user.ethAddress === '' ? 'ETH - Account' : user.ethAddress}
                        </span>
                        <button onClick={() => {
                            this.props.history.push('/settings/change-eth');
                        }}>{lang_data.edit_label}</button>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.erts_paid}</h4>
                    <div className="value-wrap">
                        <span className="value">{Common.formatNumber(paid_btc)} ERTS</span>
                        <button onClick={() => {
                            //if (paid_btc === 0) return;
                            this.setState({showErtsPaidPopup: true})
                        }}>{lang_data.detail_history_label}</button>
                    </div>
                    <h4><img src="/static/img/profile_sub_icon.png" width="22"/>{lang_data.erts_complete}</h4>
                    <div className="value-wrap">
                        <span className="value">{Common.formatNumber(unpaid_btc)} ERTS</span>
                        <button onClick={() => {
                            //if (unpaid_btc === 0) return;
                            this.setState({showErtsCompletePopup: true})
                        }}>{lang_data.detail_history_label}</button>
                    </div>
                </ProfileBox>
                <SettingBox>
                    <SettingBoxTitle onClick={() => this.props.history.push('/pushsetting')}>
                        <h4 style={{flex: 1}}>App Message setting</h4>
                        <img src="/static/img/ic_right.png" width="10"/>
                    </SettingBoxTitle>
                    <div style={
                        {
                            paddingLeft: 0,
                            marginLeft: 0,
                            borderBottom: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                        }
                    }>
                        <h4 style={{flex: 1, marginBottom: 0}}>Receive Email</h4>
                        <img
                            src={isEmail === 1 ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const is_email = isEmail === 1 ? 0 : 1;
                                this.onClickUpdateSetting(1, is_email).then(r => this.setState({isEmail: is_email}, () => {
                                    GlobalState.me.isEmail = is_email;
                                }));
                            }}/>
                    </div>
                    <div style={
                        {
                            paddingLeft: 0,
                            marginLeft: 0,
                            borderBottom: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 20,
                            height: 50,
                        }
                    }>
                        <h4 style={{flex: 1, marginBottom: 0}}>ID/PW Auto Input</h4>
                        <img
                            src={isAutoLogin === 1 ? "/static/img/btn_switch_on.png" : "/static/img/btn_switch_off.png"}
                            onClick={() => {
                                const is_auto_login = isAutoLogin === 1 ? 0 : 1;
                                this.onClickUpdateSetting(2, is_auto_login).then(r => this.setState({isAutoLogin: is_auto_login}));
                                /* 플랫트폼 체크 */
                                GlobalState.me.isAutoLogin = is_auto_login;
                                const platform = Common.checkMobile();
                                const login = JSON.parse(localStorage.getItem('login') || '{}');
                                if (platform === "android") {
                                    if (is_auto_login === 1) {
                                        window.androidBridge.bridge_save_login_info(user.userId, login.passwd, is_auto_login);
                                    } else {
                                        window.androidBridge.bridge_clear_login_info();
                                    }
                                } else { //  if (platform === "ios")
                                    if (is_auto_login === 1) {
                                        window.webkit.messageHandlers.bridge_save_login_info.postMessage({
                                            id: user.userId,
                                            pwd: login.passwd,
                                            autoLogin: is_auto_login
                                        });
                                    } else {
                                        window.webkit.messageHandlers.bridge_clear_login_info.postMessage({});
                                    }
                                }
                            }}/>
                    </div>
                </SettingBox>
                <SettingBox>
                    <SettingBoxTitle onClick={() => this.props.history.push('/withdrawal')}>
                        <h4 style={{flex: 1, marginTop: 3, paddingTop: 5}}>{ lang_data.str_member_withdraw }</h4>
                        <img src="/static/img/ic_right.png" width="10"/>
                    </SettingBoxTitle>
                </SettingBox>
                {/* <ProfileBox>
                    <h4>Account Termination</h4>
                    <div>Terminate My Account<button>EDIT</button></div>
                </ProfileBox> */}
            </ProfileWrapper>
        </div>
    }
}

export default Profile;
