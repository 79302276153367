import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const AvailableBalanceWrapper = styled.div`
    
    line-height: 22px;
    height: 22px;
    margin-bottom : 5px;
    & > span {
        display: inline-block;
        vertical-align: middle;
    }
    .btc {
        float: right;
        font-weight: bold;
    }
`;

const SummaryWrapper = styled.div`
    border: 2px solid #D1D1DB;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    .title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 12px;
        color: #666A80;
    }
`;

const Icon = styled.span`
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: cover;
    background-position: center;
    margin-right: 12px;
`;

//Purcharse 2단계화면
export default class ChargeWalletAddress extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        address: '',
        btcAddressCheck: false,
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_wallet_address";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user
        }
    }

    render() {

        const props = this.props;
        const user = this.state.me;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Enter wallet address
            </GuideTitle>
            <div style={{margin: 21}}>
                <div style={{marginTop: 24, fontSize: 12, marginBottom : 24}}>
                    * Please enter your wallet address correctly. Enter only the
                    addresses of the wallets you own and have access to.
                </div>

                <AvailableBalanceWrapper>
                    <Icon style={{backgroundImage: 'url(/static/img/btc.svg)'}}/>
                    <span style={{fontSize: 12, fontWeight: "bold"}}>BTC</span>
                    <span style={{fontSize: 12}}> - Bitcoin</span>
                </AvailableBalanceWrapper>

                <Input type="text" placeholder={'Enter BTC wallet address'} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({address: e.currentTarget.value})}/>

                <SummaryWrapper>
                    <div className="title">Summary</div>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Current BTC price</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>US${user.btcPrice} </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Amount to received</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>{user.amount_temp / user.btcPrice} BTC </span>
                    </AvailableBalanceWrapper>
                </SummaryWrapper>

                <Button className="green" onClick={
                    async () => {
                        let check = await API.checkAddress(this.state.address);
                        if (check.status === 'success') {

                        } else {
                            this.setState({address: '', btcAddressCheck: false});
                            alert("Incorrect address, please check.");
                            return;
                        }

                        let result = await API.addressPay(user.userId, this.state.address);
                        if (result.status == 'success') {
                            window.location.href = ('/#/Charge/ChargeAddPayment');
                        }
                    }
                }> Continue </Button>
            </div>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Error'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

