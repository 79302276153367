import React from 'react';
import styled from 'styled-components';
import NavigationBar from './components/NavigationBar';
import {DashboardBox, DollarsDorothy, DollarsSmall} from './styled-components';
import moment from 'moment';
import {getLangCode, getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import GlobalState from "./mobx/GlobalState";
import Common from "./utils/Common";

function copyText(text) {
    const tempElem = document.createElement('textarea');
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
}

const Wrap = styled.div`
    background: linear-gradient(#E26FA4, #F8CB9B);
    display: flex;
    flex-direction: column;
`;

const DashboardWrap = styled.div`
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
`;

const DashboardCounterBox = styled(DashboardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 16px;
    text-align: right;
    margin-bottom: 20px;

    .counter {
        margin-right: 16px;
        flex: 1;
    }
    
    .title {
        color: #666A80;
        font-size: 12px;
        text-align: center
    }
    
    .bottom {
        border-top: 1px solid rgb(209, 209, 219);
        padding-top: 8px;
        margin-top: 8px;
        line-height: 16px;
        color: rgb(0, 181, 195);
        font-weight: bold;
    }
`;

const DashboardProfileBox = styled(DashboardBox)`
   
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 16px;
    text-align: right;

    .counter {
        margin-right: 16px;
        flex: 1;
    }
    
    .user_level {
        font-weight: bold;
        font-size: 16px;
        /* width: 125px; */
        text-align: left;
        padding: 5px 10px;
        display: inline-block;
        border-radius:24px;
    }
`;

const DashboardAddButtonBox = styled(DashboardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 10px 0;
    .plus-icon {
        font-size: 24px;
        background-color: #f9e484;
        width: 64px;
        height: 64px;
        line-height: 64px;
        border-radius: 32px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        top: -15px;
        right: -15px;
    }
    .icon {
        width: 40px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 8px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    .description {
        font-size: 11px;
    }
    .image_plus {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 10px;
        right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
    }
`;

const DashboardButtonBox = styled(DashboardBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 24px;
    color: #2A2D42;
    position: relative;

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    .description {
        font-size: 11px;
        margin-top: 25px;
    }

    .image {
        position: absolute;
        width: 52px;
        height: 55px;
        bottom: 0;
        right: 24px;
        background-size: contain;
        background-repeat: no-repeat;

        &.d-day {
            color: #EA589E;
            font-size: 16px;
            padding-top: 25px;
            box-sizing: border-box;
            text-align: center;
        }
    }
    
    .support_total_label {
        
        font-size: 12px;
    }
    
    .support_total_price {
        margin-top : 5px;
        font-size: 17px;
        font-weight: bold;
        color: #000000;
        background-color: #FFFFFF;
        border-radius : 26px;
        margin-right: auto;
        padding: 3px 10px;
        span {
            font-size: 15px;
            font-weight: bold;
            margin-left: 4px;
            color: #ff4c89;
        }
    }
    .dorothy_total_price {
        margin-top : 5px;
        font-size: 17px;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #0085FF;
        border-radius : 26px;
        margin-right: auto;
        padding: 3px 10px;
        span {
            font-size: 17px;
            font-weight: normal;
            margin-left: 4px;
            color: #FFFFFF;
        }
    }
    
    .donation_total_price {
        margin-top : 5px;
        font-size: 17px;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #FF4C89;
        border-radius : 26px;
        margin-right: auto;
        padding: 3px 10px;
        span {
            font-size: 17px;
            font-weight: normal;
            margin-left: 4px;
            color: #FFFFFF;
        }
    }
    
    .voting_total_count {
        margin-top : 5px;
        font-size: 17px;
        font-weight: normal;
        color: #FFFFFF;
        background-color: #B131FF;
        border-radius : 26px;
        margin-right: auto;
        padding: 3px 10px;
        span {
            font-size: 17px;
            font-weight: bold;
            margin-left: 4px;
            color: #FFFFFF;
        }
    }
    
    .dorothy_day {
        position: absolute;
        width: 52px;
        top: 20px;
        right: 15px;
        background-size: contain;
        color: #0085FF;
        background-color: #CADFFF;
        border-radius : 26px;
        padding: 3px 10px;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
    }
`;

export default class Dashboard extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me,
        supports: [],
        donation_btc: 0,
        vote_count: 0,
        supported_btc: 0,
        supported_btc_doller: 0,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "dashboard";
        let language = document.getElementById('app_lang').value;
        let lang_code = getLangCode();
        if (lang_code != "en") {
            language = lang_code;
            document.getElementById('app_lang').value = lang_code;
        }
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        const supports = await API.getSupport();
        if (supports.status === 'success') {
            this.setState({supports: supports.data.map(e => ({...e, type: 'support'}))});
        }

        const donation = await API.getDonationBtc();
        if (donation.status === 'success') {
            this.setState({donation_btc: donation.all_btc});
        }

        const vote = await API.getVoteCount();
        if (vote.status === 'success') {
            this.setState({vote_count: vote.vote_count});
        }

        const supportBtc = await API.getSupportBtc();
        if (supportBtc.status === 'success') {
            this.setState({supported_btc: supportBtc.total_btc, supported_btc_doller: supportBtc.total_btc_dollar});
        }

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user;
        }
    }

    goDashBoard() {
        GlobalState.sel_branch = 0;
        this.props.history.push('/my-code');
    }

    isIOS() {
        var userAgent = navigator.userAgent.toLowerCase();

        var isIOS = ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1) || (userAgent.search("apple") > -1) || (userAgent.search("mac") > -1));
        if (isIOS == true && userAgent.search("safari") > -1) {
            return false;
        }

        return isIOS;
    }

    render() {
        const {lang_data, supports, donation_btc, vote_count} = this.state;
        const props = this.props;
        const user = this.state.me;
        const dollars = ((user.btc * user.btcPrice).toFixed(2)).toLocaleString();
        const btc = user.btc.toFixed(6);

        // const totalSupport = supports ? supports.reduce((a, b) => b.amount + a, 0) : 0;
        // const totalSupportBtc = supports ? supports.reduce((a, b) => b.amountBtc + a, 0) : 0;
        const totalSupport = this.state.supported_btc_doller;
        const totalSupportBtc = this.state.supported_btc;

        const support_dollars = (user.btcSupportedDollar.toFixed(2)).toLocaleString();
        const support_btc = user.btcSupported.toFixed(6);
        const reward_dollars = (user.btcRewardDollar.toFixed(2)).toLocaleString();
        const reward_btc = user.btcReward.toFixed(6);
        const support_url = 'http://app.everythanks.com/supports.html?userId=' + user.userId;

        let firstDayOfNextMonth = moment().startOf('month').add(1, 'month').hours(18).minutes(0).seconds(0);
        let remind_day = moment.duration(firstDayOfNextMonth - moment()).days() + 1;
        if (user.test_day > 0) {
            var test_remind_date = moment(firstDayOfNextMonth).add(-user.test_day, 'day');
            var curDay = moment();
            var test_firstDay = moment.duration(curDay - test_remind_date).days(); //curDay.diff(test_remind_date, 'days');

            if (test_firstDay < 0) {
                const firstDayOfNextMonth1 = moment().startOf('month').add(2, 'month');
                test_remind_date = moment(firstDayOfNextMonth).add(-user.test_day, 'day');
                test_firstDay = curDay.diff(test_remind_date, 'days');
            }
            remind_day = test_firstDay + 1
        }

        let level_background = '#F9E484';
        if (user.level >= 17) {
            level_background = '#96CDFF';
        } else if (user.level >= 13) {
            level_background = '#00B5C3';
        }

        return (
            <Wrap>
                <NavigationBar
                    hideBack
                    title={lang_data.every_thanks_label}
                    lang_data={lang_data}/>
                <DashboardWrap>
                    <DashboardProfileBox style={{padding: '12px', boxSizing: 'border-box', position: 'relative', marginBottom:24}}>
                        <div style={{width: '100%', marginRight: 10, textAlign: 'center', fontSize: 14, display : 'flex'}}>
                            <div style={{width: 90, marginRight: 10, textAlign: 'center', fontSize: 14}} onClick={() => props.history.push('/settings')}>
                                <div style={{
                                    width: 80,
                                    height: 50,
                                    borderRadius: 25,
                                    margin: '0 auto',
                                    marginBottom: 2,
                                    boxShadow: '0 0 0px #999',
                                }}>
                                    <img src="/static/img/main_icon_supporter.png" width="80" style={{
                                        verticalAlign: 'middle',
                                        marginTop: 10,
                                        marginRight: 0,
                                        display: user.rank === 'SUPPORTER' ? 'block' : 'none'
                                    }}/>
                                    <img src="/static/img/main_icon_angel.png" width="80" style={{
                                        verticalAlign: 'middle',
                                        marginTop: 10,
                                        marginRight: 0,
                                        display: user.rank === 'ANGEL' ? 'block' : 'none'
                                    }}/>
                                    <img src="/static/img/main_icon_arch.png" width="80" style={{
                                        verticalAlign: 'middle',
                                        marginTop: 10,
                                        marginRight: 0,
                                        display: user.rank === 'ARCH' ? 'block' : 'none'
                                    }}/>
                                    <img src="/static/img/main_icon_seraphim.png" width="80" style={{
                                        verticalAlign: 'middle',
                                        marginTop: 10,
                                        marginRight: 0,
                                        display: user.rank === 'SERAPHIM' ? 'block' : 'none'
                                    }}/>
                                </div>
                                <span style={{color: 'rgb(102, 106, 128)'}}>{user.rank}</span>
                            </div>
                            <div style={{flex: 1, textAlign: 'initial'}}>
                                <div style={{display:"flex", marginTop:5}}>
                                    <div style={{fontWeight: 'bold', fontSize: 18}} onClick={() => props.history.push('/settings')}>{user.userId}</div>
                                    <div style={{marginLeft:"auto"}}>
                                        <img src="/static/img/etc_btn.png" width="30" style={{verticalAlign: 'middle', marginRight: 0}}/>
                                    </div>
                                </div>
                                <div style={{display:"flex", alignItems:"center", marginTop:5}}>
                                    <label className={'user_level'} style={{background: level_background}} onClick={() => props.history.push('/settings')}><label style={{fontWeight: "normal"}}>My Grade</label> G{user.level}</label>
                                    <div style={{marginLeft:"auto"}}
                                         onClick={() => {
                                             const platform = Common.checkMobile();
                                             if (platform === "android") {
                                                 //copyText('https://play.google.com/store/apps/details?id=com.everythanks');
                                                 copyText('https://everythanks.com/signup?mento=' + user.userId);
                                                 alert('Share link has been copied.');
                                             } else { //  if (platform === "ios")
                                                 window.webkit.messageHandlers.bridge_clip.postMessage({data: 'https://everythanks.com/signup?mento=' + user.userId});
                                             }

                                         }}>
                                        <img src="/static/img/icon_share.png" width="30" style={{verticalAlign: 'middle', marginRight: 0}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DashboardProfileBox>
                    <div style={{display: 'flex', marginBottom: 24}}>
                        <DashboardAddButtonBox
                            style={{flex:1, marginRight: 24}}
                            onClick={() => props.history.push('/charge')}>
                            <div style={{display: 'flex', width: 'calc(100% - 20px)', marginTop: 10, marginBottom:10}}>

                                <div className="icon" style={{backgroundImage: 'url(/static/img/BTC_add.png)'}}/>
                                <div style={{marginLeft:8}}>
                                    <div className="title">{lang_data.charge_label}</div>
                                    <div className="description" style={{color: '#DF5897'}}>${dollars} / {btc} BTC</div>
                                </div>
                            </div>
                            <div className="image_plus" style={{backgroundImage: 'url(/static/img/ic_dash_plus.png)'}}/>
                        </DashboardAddButtonBox>
                        <DashboardAddButtonBox
                            style={{flex:1}}
                            onClick={() => props.history.push('/withdraw')}>
                            <div style={{display: 'flex', width: 'calc(100% - 20px)', marginTop: 10, marginBottom:10}}>
                                <div className="icon" style={{backgroundImage: 'url(/static/img/USD_add.png)'}}/>
                                <div style={{marginLeft:8}}>
                                    <div className="title">{lang_data.withdraw_label}</div>
                                    <div className="description">{lang_data.to_my_account_label}</div>
                                </div>
                            </div>
                            <div className="image_plus" style={{backgroundImage: 'url(/static/img/ic_dash_plus.png)'}}/>
                        </DashboardAddButtonBox>
                    </div>
                    <DashboardButtonBox
                        style={{
                            background: '#DF5897',
                            color: 'white',
                            padding: "15px 24px 24px",
                            marginBottom: '24px',
                            display: this.isIOS() ? '' : ''
                        }}
                        onClick={() => {
                            props.history.push('/support')
                            // const platform = Common.checkMobile();
                            // if (platform === "android") {
                            //     window.androidBridge.bridge_open_url(support_url);
                            // } else { // (platform === "ios")
                            //     window.webkit.messageHandlers.bridge_open_url.postMessage({url: support_url});
                            // }
                        }}>

                        <div className="title">{lang_data.support_label}</div>
                        <div className="support_total_label">{lang_data.my_support_total_amount}</div>
                        <div className="support_total_price">{totalSupportBtc.toFixed(6)}BTC
                            <span>${totalSupport.toFixed(2)}</span>
                        </div>
                        <div style={{marginTop:5, marginRight: 94}} className="description">{lang_data.share_give_your_hope_label}</div>
                        <div className="image" style={{backgroundImage: 'url(/static/img/support_dash.png)', width: 76, height: 96, right: 12}}/>
                    </DashboardButtonBox>
                    <DashboardButtonBox
                        style={{marginBottom: 24, padding: "20px 24px"}}
                        onClick={() => props.history.push('/dorothy')}>
                        <div className="title">{lang_data.dorothy_label}</div>
                        <div style={{paddingRight:70, marginTop:10}} className="description">
                            {lang_data.take_gift_from_god_label}
                        </div>
                        <div className="dorothy_total_price">
                            {user.dorothy_amount.toFixed(6).toLocaleString()}<span>BTC</span>
                        </div>
                        <div className="dorothy_day"
                             style={{}}>D-{remind_day}</div>
                        <div className="image d-day" style={{backgroundImage: 'url(/static/img/ic_gift.png)', marginBottom: 25}}/>
                    </DashboardButtonBox>
                    <DashboardButtonBox
                        style={{padding: "15px 0 15px 24px", marginBottom: 24}}
                        onClick={() => {
                            // const platform = Common.checkMobile();
                            // if (platform === "android") {
                            //     window.androidBridge.bridge_open_url('https://everythanks.com/');
                            // } else if (platform === "ios") {
                            //     window.webkit.messageHandlers.bridge_open_url.postMessage({url: 'https://everythanks.com/'});
                            // }
                        }}>
                        <div className="title">{lang_data.total_donation_amount}</div>
                        <div className="donation_total_price">{donation_btc.toFixed(6).toLocaleString()}<span>BTC</span>
                        </div>
                        <div style={{paddingRight:79,marginTop:10}} className="description">{lang_data.donation_status_desc}</div>
                        <div className="image" style={{backgroundImage: 'url(/static/img/ic_all_money.png)',marginBottom:45}}/>
                    </DashboardButtonBox>

                    <DashboardButtonBox
                        style={{padding: "15px 0 15px 24px", marginBottom: 24}}
                        onClick={() => props.history.push('/voting')}>
                        <div className="title">{lang_data.voting_donation_recommendation}</div>
                        <div style={{paddingRight:79,marginTop:10}} className="description">{lang_data.voting_description_1}</div>
                        <div style={{paddingRight:79,marginTop:5, marginBottom:5}} className="description">{lang_data.voting_description_2}</div>
                        <div className="voting_total_count">{lang_data.voting_register_1}<span>{vote_count}{lang_data.voting_register_2}</span>
                        </div>
                        <div className="image" style={{backgroundImage: 'url(/static/img/ic_medal.png)',marginBottom:50}}/>
                    </DashboardButtonBox>

                    <div style={{display:"none"}}>
                        <DashboardCounterBox onClick={() => {this.goDashBoard()}} style={{flex:1, marginRight:24, padding:"7px 0"}}>
                            <div className="counter">
                                <div style={{fontSize:14}} className="title">{lang_data.total_btc_supported}</div>
                                <div><DollarsDorothy style={{fontSize: 18}}>{support_btc} BTC</DollarsDorothy></div>
                                <div><DollarsSmall style={{fontWeight: 'bold'}}>{support_dollars}</DollarsSmall></div>
                                <div className="bottom">
                                    <img src="/static/img/M.png" alt="R" width="16" style={{verticalAlign: 'baseline', marginRight: 4}}/>
                                    <span style={{marginRight: 4}}>{user.bellow_all_count}</span>
                                    <img src="/static/img/S.png" alt="S" width="16" style={{verticalAlign: 'baseline', marginRight: 4}}/>
                                    <span>{user.bellow_support_all_count}</span>
                                </div>
                            </div>
                        </DashboardCounterBox>
                        <DashboardCounterBox onClick={() => {this.goDashBoard()}} style={{flex:1, padding:"7px 0"}}>
                            <div className="counter">
                                <div style={{fontSize:14}} className="title">{lang_data.total_btc_rewarded}</div>
                                <div><DollarsDorothy style={{fontSize: 18}}>{reward_btc} BTC</DollarsDorothy></div>
                                <div><DollarsSmall style={{fontWeight: 'bold'}}>{reward_dollars}</DollarsSmall></div>
                                <div className="bottom">
                                    <img src="/static/img/R.png" alt="R" width="16" style={{verticalAlign: 'baseline', marginRight: 4}}/>
                                    <span>{user.bellow_reward_all_count}</span>
                                </div>
                            </div>
                        </DashboardCounterBox>
                    </div>
                </DashboardWrap>
            </Wrap>
        );
    }
}