import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const SplitLine = styled.div`
    border-right: 2px solid #E7E7EF;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    left: 138px;
`;

const HistoryList = styled.ul`
    margin: 0;
    padding: 0;
`;

const HistoryRow = styled.li`
    display: flex;
    list-style: none;
    height: 55px;
    line-height: 55px;
    position: relative;
`;

const DateColumn = styled.div`
    width: 120px;
    margin-right: 24px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

const EventColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

const EventDescription = styled.div`
    color: #666A80;
    font-size: 14px;
    text-align: left;
    line-height: 1.2;
    font-weight: normal;
`;

const EventPrice = styled.div`
    font-size: 18px;
    color: #EA589E;
    text-align: left;
    line-height: 1.2;
    font-weight: normal;
`;

const Checkbox = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 1px solid #E7E7EF;
    position: absolute;
    top: 22px;
    left: 122px;
    background: white;
`;

function copyText(text) {
    const tempElem = document.createElement('textarea');
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
}

//function History(props) {
export default class History extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "history";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {
        const props = this.props;
        const {lang_data} = this.state;

        return <div style={{padding: '0 12px', position: 'relative'}}>
            <SplitLine/>
            <HistoryList>
                <HistoryRow style={{height: '55px', lineHeight: '55px'}}>
                    <DateColumn>{lang_data.date_label}</DateColumn>
                    <EventColumn>{lang_data.detail_history_label}</EventColumn>
                </HistoryRow>
                {(props.history || []).map((e, i) =>
                    <HistoryRow key={i} style={{height: props.type === 'withdraw' ? '95px' : '55px', lineHeight: props.type === 'withdraw' ? '95px' : '55px'}}>
                        <DateColumn>{moment(e.createdAt).format('YYYY-MM-DD HH:mm')}</DateColumn>
                        <Checkbox/>
                        <EventColumn>
                            <EventDescription>{props.type === 'support' ? (e.typeT.indexOf('send_donate_support') > -1 ? 'donation' : e.typeT.indexOf('reward') > -1 ? 'reward' : e.typeT.indexOf('seraphim') > -1 ? 'seraphim' : e.typeT.indexOf('arch') > -1 ? 'arch' : e.typeT.indexOf('angel') > -1 ? 'angel' :  e.level < 13 ? (this.state.me.support_type == 0 ? e.type : 'donation') : e.level === '17' ? 'dorothy' : e.level === '14' ? 'Angel Group' : e.level === '15' ? 'Arch Group' : e.level === '16' ? 'Seraphim Group' : 'donation') :
                                props.type === 'charge' ? ((e.type === 'support' || e.type === 'send_dorothy' || e.type === 'send_donate' || e.type === 'send_donate_support') ? 'supported': e.type === 'pedding' ? 'Pending' : e.type === 'pending' ? 'supported (pending)' : e.type === 'send' ? 'withdraw': e.type === 'fee' ? '' : e.type === 'winner_dorothy' ? 'dorothy' : e.type.indexOf('reward') > -1 ? 'reward' : e.type == 'receive' ? 'deposit' : e.type === 'admin' ? 'Deposit (C)' : e.type) : e.type === 'admin' ? 'Deposit (C)' : e.type }</EventDescription>
                            <EventPrice>{props.type === 'support' ? e.amount.toFixed(0) : (
                                props.type === 'charge' ? (e.type1 == 'admin subtract' ? '-' + e.amount.toFixed(6) : e.amount.toFixed(6)) : e.amount.toFixed(6))}{props.unit || 'BTC'}{ props.type === 'support' ? '(' : ''}{ props.type === 'support' ? e.amountBtc.toFixed(6) : ''}{props.type === 'support' ? 'BTC)' : ''}</EventPrice>
                            <EventDescription style={{display: props.type === 'withdraw' ? 'block' : 'none'}}
                            >Address : {e.address != null ? e.address.substr(0, 10) : ''}
                                <button
                                    style={{
                                        backgroundColor: '#ffffff',
                                        border: 'none',
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        copyText(e.address);
                                        alert('Address has been copied.')
                                    }}>[COPY]
                                </button>
                            </EventDescription>
                            <EventDescription
                                style={{display: props.type === 'withdraw' ? 'none' : 'none'}}
                            >Txid : {e.txid != null ? e.txid.substr(0, 10) : ''}
                                <button
                                    style={{
                                        backgroundColor: '#ffffff',
                                        border: 'none',
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        copyText(e.txid);
                                        alert('TXID has been copied.')
                                    }}>[COPY]
                                </button>
                            </EventDescription>
                        </EventColumn>
                    </HistoryRow>
                )}
            </HistoryList>
        </div>
    }
}
