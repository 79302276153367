import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import {Button} from "./styled-components";
import {Popup} from "./components/Popup";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

//Purcharse 7단계화면 - 완료
export default class ChargePurcharseComp extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        card_num: '',
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_purchase_comp";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {

        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                BTC purchase complete
            </GuideTitle>

            <div style={{margin: 21, textAlign: 'Center'}}>
                <div style={{marginTop: 44, fontSize: 14, marginBottom: 10, fontStyle:"bold"}}>
                    BTC charging is complete
                </div>

                <div style={{marginTop: 14, fontSize: 14, marginBottom: 10, fontStyle:"bold"}}>
                    You can check the charged history<br/>
                    in [CHARGE - HISTORY]
                </div>

                <Button className="green" onClick={
                    () => {
                        window.location.href = ('#/dashboard');
                    }
                } style={{marginTop : 40}}> To Main </Button>
            </div>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

