import { observable } from "mobx";

const GlobalState = observable({

    myLatitude: 37.715133,
    myLongitude: 127.169311,
    myAddress: "OOO OOO 369",
    fcmToken: "",
    appToken: "",
    auto_login : 1,

    me: {
        id: "",
        userId: "",
        email: null,
        txPassword: "",
        level: 0,
        btc: 0,
        btcRealPrice: 0,
        btcAddress: "",
        lang: "",
        ethAddress: null,
        rank: "",
        isMsg: 0,
        isEmail: 0,
        isAutoLogin: 0,
        phone_nation: null,
        phone: null,
        birthday: null,
        address: null,
        activate_status: null,
        createDate: "",
        utc: null,
        amount_temp: 0,
        card_number: null,
        card_date: null,
        card_sec_code: null,
        btc_charge_push: 0,
        donation_push: 0,
        pending_occurrence_push: 0,
        pending_donation_push: 0,
        dorothy_push: 0,
        contact_push: 0,
        voting_push: 0,
        voting_comment_push: 0,
        voting_end_push: 0,
        voting_select_push: 0,
        btcPrice: 0,
        btcSupported: 0,
        btcSupportedDollar: 0,
        btcPendding: 0,
        rankUpdate: 0,
        countBellow: 0,
        bellow_all_count: 0,
        bellow_support_all_count: 0,
        bellow_pending_all_count: 0,
        bellow_reward_all_count: 0,
        dorothy_amount: 0,
        test_day: 0,
        support_type: 0,
        btcReward: 0,
        btcRewardDollar: 0
    },

    sign: {},

    sel_branch: 0
}, {});

export default GlobalState
