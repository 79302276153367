import React from 'react';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import NavigationBar from "./components/NavigationBar";
import GlobalState from "./mobx/GlobalState";
import API from "./utils/api";
import {ArticlePopup} from "./components/ArticlePopup";
import moment from "moment";
import Common from "./utils/Common";
import LocalizedStrings from 'react-localization';
import {FLAG_SELECTOR_OPTION_LIST, REPORT_TYPE_COMMENT, REPORT_TYPE_POST} from "./utils/Constants";
import './components/react-custom-flag-select/react-custom-flag-select.css';
import {ReportPopup} from "./components/ReportPopup";
import {DirectSupportPopup} from "./components/DirectSupportPopup";
import {Popup} from "./components/Popup";

const NotificationArea = styled.div`
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  padding: 20px;
  background: #FFF1F4;
  white-space: pre-line;

  .top_ic_like {
    width: 20px;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const EventDescription = styled.div`
    color: #666A80;
    font-size: 14px;
    text-align: left;
    line-height: 2;
    padding-right: 30px;
    font-weight: bold;
    position: relative;
`;

const ReservDescription = styled.div`
    margin-top: 60px;
    text-align: center;
    position: relative;
`;

const Wrap = styled.div`
  padding: 21px;
  background-color: #F7F7FA;

  .articleTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .add_comment {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    box-shadow: 1px 1px 3px 2px #00000029;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    
    img {
      width: 22px;
      margin-right: 5px;
      padding: 7px
    }
    
    .send {
      width: 102px;
      border-top-right-radius: 20px;
      background-color:#87C6FF;
      color:white;
      text-align: center;
      height : 70px;
      line-height : 68px;
    }

    .yours {
      display: flex;
      width: calc(100vw - 102px);
      margin-right: 10px;
      margin-top : 5px;
      margin-bottom : 5px;

      .story {
        padding: 16px 14px;
        border: none;
        background-color: white;
        width: 100%;
        height: 25px;
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    padding-right: 30px;
    padding-left: 11px;

    .modify {
      width: 30%;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      background-color: #e974a9;
      color: white;
    }
  }

  .tab {
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .detail {
      height: 40px;
      font-weight: bold;
      line-height: 38px;
      padding: 10px 10px;
      background: #fff;
      box-shadow: none;
      border: 1px solid #D1D1DB;
      border-radius: 20px;
      font-size: 15px;
      white-space: pre-line;
      box-shadow: 1px 1px 3px 2px #00000029;
      
      #flag {
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }

    #country {
      margin-right: 20px;
      width: 40%;
    }

    #phone {
      width: 40%;
    }

    .icon {
      display: flex;
      align-items: start;
      padding-left: 15px;
      height:40px;
      line-height: 38px;
      width: calc(50% - 5px);
      align-items: center;
      border-radius : 20px;
      
      img {
        width: 20px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }

  .comment_area {
    margin-bottom: 100px;

    .comment_tab {
      background: #f5edaa;
      border-radius: 14px;
      padding: 10px;
      margin-bottom: 15px;

      .id_time {
        display: flex;
        font-size: 13px;
        margin-bottom: 5px;
        align-items: center;
      }

      .comment {
        font-size: 13px;
        white-space: pre-line;
        word-break: break-word;
      }

      .delete {
        margin-left: auto;
        background: #e66161;
        border-radius: 2px;
        padding: 5px;
        font-weight: bold;
        color: white;
      }
      
      .report {
        margin-left: auto;
        border-radius: 2px;
        padding: 5px;
        font-weight: bold;
        color: white;
      }
    }
  }
`;

function copyText(text) {
    const tempElem = document.createElement('textarea');
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
}
export default class Article extends React.Component {

    my_contents = parseInt(this.props.match.params.my_contents);
    id = parseInt(this.props.match.params.id);

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        n_comments: 0,
        n_like: 0,
        comment_list: [],
        me: GlobalState.me,
        comment: '',
        commentPopup: false,
        deletePopup: false,
        like_status: false,
        isMine: false,
        deletedIdx: 0,
        article: {},
        modifyPopup: false,
        articleDelPopup: false,
        showReportPopup: false,
        comment_id : 0,
        btcAddress : '',
        dirrectSupportPopup: false,
        addCommentPopup: false,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "article";
        window.scrollTo(0, 0);

        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
        }, () => {
            // const Strings = new LocalizedStrings({getLangData});
            // document.getElementById('article_guide').innerHTML = this.state.lang_data.article_noti1_label;
        });

        // const me = await API.me();
        // if (me.status === 'success') {
        //     this.setState({me: me.user});
        //     GlobalState.me = me.user;
        // }
        const me = GlobalState.me;
        this.setState({me: me.user});
        const vote = await API.getVoteDetail(me.userId, this.id);

        if (vote.status == 'success') {
            this.setState({
                comment_list: vote.comment_list,
                n_comments: vote.comment_list.length,
                n_like: vote.vote_model.like_count,
                like_status: vote.like_status,
                article: vote.vote_model,
                isMine: vote.is_mine,
                btcAddress: vote.btcAddress
            }, () => {
                const currentItem = Common.find(FLAG_SELECTOR_OPTION_LIST, {id: vote.vote_model.phone_nation});
                if (currentItem.length > 0) {
                    document.getElementById('flag').innerHTML = "<img src=" + currentItem[0].flag + ">" + currentItem[0].displayText
                } else {
                    document.getElementById('flag').innerHTML = ""
                }
            });
        }
    }

    async onDeleteVote() {
        await API.voteDelete(this.id);
    }

    async onDeleteComment(id) {
        await API.commentDelete(this.id, id);
    }

    async onAddComment() {
        let {comment, comment_list} = this.state;
        const me = GlobalState.me;
        const result = await API.commentVote(this.id, comment);
        if (result.status === 'success') {
            comment_list.push({
                id: result.updated_id,
                userId: me.userId,
                time: moment.now(),
                text: comment,
                isMine: true
            });
            this.setState({
                addCommentPopup:true,
                comment_list: comment_list,
                comment: '',
                n_comments: result.comment_count
            }, () => {
                window.scrollTo(0, document.getElementById('root').offsetHeight)
            });
        }
    }

    async onLikeVote(status) {
        const result = await API.likeVote(this.id, status);
        if (result.status === 'success') {
            this.setState({like_status: status === 1, n_like: result.like_count});
        }
    }

    goBack = () => {
        this.props.history.goBack()
    };

    render() {
        const props = this.props;
        const {
            lang_data,
            n_comments,
            n_like,
            isMine,
            comment_list,
            me,
            comment,
            commentPopup,
            deletePopup,
            like_status,
            deletedIdx,
            article,
            modifyPopup,
            articleDelPopup,
            showReportPopup,
            comment_id,
            dirrectSupportPopup,
            addCommentPopup
        } = this.state;
        const voting_classification_labels = [
            lang_data.classification1_label,
            lang_data.classification2_label,
            lang_data.classification3_label,
            lang_data.classification4_label,
            lang_data.classification5_label,
            lang_data.classification6_label,
            lang_data.classification7_label,
            lang_data.classification8_label,
            lang_data.classification9_label,
            lang_data.classification10_label,
            lang_data.classification11_label,
            lang_data.classification12_label,
        ];
        return <>
            <ArticlePopup
                title={"Confirm"}
                enabled={commentPopup}
                description={lang_data.comment_warning_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={(confirm) => {
                    this.setState({commentPopup: false})
                }}
            />
            <ArticlePopup
                title={"Delete"}
                enabled={deletePopup}
                description={lang_data.delete_warning_label}
                buttonTitle={lang_data.yes_label}
                buttonTitle2={lang_data.no_label}
                onButtonClick={(confirm) => {
                    this.setState({deletePopup: false});
                    if (confirm) {
                        this.onDeleteComment(comment_list[deletedIdx].id).then(r => {
                            comment_list.splice(deletedIdx, 1);
                            this.setState({
                                comment_list: comment_list,
                                n_comments: comment_list.length
                            })
                        });
                    }
                }}
            />
            <DirectSupportPopup
                enabled={dirrectSupportPopup}
                description={'보유 BTC: 0.0002 BTC'}
                buttonTitle={'후원'}
                buttonTitle2={"취소"}
                onButtonClick={(confirm) => {
                    this.setState({dirrectSupportPopup: false});
                    if (confirm) {
                        // 후원처리
                    }
                }}/>
            <ArticlePopup
                title={"Confirm"}
                enabled={modifyPopup}
                description={lang_data.modification_fail_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={(confirm) => {
                    this.setState({modifyPopup: false})
                }}
            />
            <ArticlePopup
                title={"Delete"}
                enabled={articleDelPopup}
                description={lang_data.article_delete_waring_label}
                buttonTitle={lang_data.yes_label}
                buttonTitle2={lang_data.no_label}
                onButtonClick={(confirm) => {
                    this.setState({articleDelPopup: false});
                    if (confirm) {
                        this.onDeleteVote().then(r => {
                            this.goBack()
                        });
                    }
                }}
            />
            <ReportPopup
                enabled={showReportPopup}
                menu1={lang_data.str_report}
                menu2=''
                menu3={lang_data.str_report_writer}
                type={REPORT_TYPE_COMMENT}
                onButtonClick={() => {
                    this.setState({ showReportPopup: false, comment_id : 0 })
                }}
                onMenuClick={async (type, proc_type) => {
                    this.setState({ showReportPopup: false })
                    const report = await API.procReport(comment_id, type, proc_type);
                    if (report.status == 'success') {
                        this.setState({ report_id: 0 })
                        alert(lang_data.str_reported)
                    }
                }}/>
            <Popup
                enabled={addCommentPopup}
                isSuccess={true}
                title={lang_data.success_title}
                description={lang_data.comment_successful_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        addCommentPopup: false
                    });
                }}/>
            <NavigationBar
                onBackClick={() => this.goBack()}
                lang_data={lang_data}
                title={lang_data.donation_recommendation_label2}
            />
            <NotificationArea>
                <div style={{fontSize: 20, whiteSpace: "pre-line", display:"flex"}} id={'article_guide'}>
                    <div style={{verticalAlign: 'middle', width: 120}}>
                        <img src="/static/img/detail_voting_heart.png" style={{
                            marginTop: 15, width:60
                        }}/>
                    </div>
                    <div style={{marginLeft: 15, fontSize : 18, whiteSpace: "break-spaces"}}>{lang_data.article_noti1_label}</div>
                </div>
                <div style={{
                    fontSize: 13,
                    lineHeight: 1.5,
                    paddingTop: 20
                }}>{lang_data.article_noti2_label}</div>
            </NotificationArea>
            <Wrap>
                <div className={'articleTitle'}>{article.title}</div>
                <div className={'tab'}>
                    <div className={'title'}>{lang_data.classification_label}</div>
                    <div className={'detail'}>{voting_classification_labels[article.classification]}</div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>{lang_data.donation_org_name_label}</div>
                    <div className={'detail'}>{article.name}</div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>{lang_data.donation_orgnum_label}</div>
                    <div style={{display: 'flex'}}>
                        <div className={'detail'} id={'country'} style={{display: "flex"}}>
                            <div id={'flag'}/>
                        </div>
                        <div className={'detail'} id={'phone'}>{Common.formatPhone(article.phone)}</div>
                    </div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>{lang_data.donation_org_address_label}</div>
                    <div className={'detail'}>{article.address}</div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>{lang_data.Why_donation_label}</div>
                    <div className={'detail'} style={{
                        minHeight: 150,
                        wordBreak: "break-word",
                        fontWeight: "normal",
                        height: "auto"
                    }}>{article.content}</div>
                </div>
                <div className={'tab'} style={{display: "flex", marginBottom: "20px"}}>
                    <div className="icon" style={{border: "1px solid #D5D5D5", marginRight: "5px"}}>
                        <img
                            src={n_comments > 0 ? "/static/img/icon_comment_on.png" : "/static/img/ic_comment_cnt.png"}/>
                        <div style={{marginRight: 15}}>{Common.formatNumber(n_comments)}</div>
                    </div>
                    <div className={'icon'} style={{
                        border: "1px solid #FF5564",
                        marginLeft: "5px",
                        backgroundColor: like_status ? "#FF5564" : "white",
                        color: like_status ? "white" : "black"
                    }}>
                        <img src={like_status ? "/static/img/ic_like_on.png" : "/static/img/ic_like.png"}
                             onClick={() => {
                                 if (isMine) return;
                                 const likeStatus = like_status ? 0 : 1;
                                 this.onLikeVote(likeStatus).then(r => {
                                 });
                             }}/>
                        <div>{Common.formatNumber(n_like)}</div>
                    </div>
                </div>

                <div className={'tab'}>
                    <div className={'title'}>{lang_data.bitcoin_address_label}</div>
                    <EventDescription>{article.btcAddress != null ? article.btcAddress : ''}
                        <button
                            style={{
                                backgroundColor: '#87C6FF',
                                color: '#ffffff',
                                border: 'none',
                                padding: 10,
                                textDecoration: 'none',
                                cursor: 'pointer',
                                position: "absolute",
                                left: "auto",
                                right: 0
                            }}
                            onClick={() => {
                                copyText(article.btcAddress);
                                alert('Address has been copied.')
                            }}>COPY
                        </button>
                    </EventDescription>
                </div>

                {/*<ReservDescription>*/}
                {/*    <button*/}
                {/*        style={{*/}
                {/*            backgroundColor: '#87C6FF',*/}
                {/*            color: '#ffffff',*/}
                {/*            border: 'none',*/}
                {/*            padding: 10,*/}
                {/*            textDecoration: 'none',*/}
                {/*            cursor: 'pointer',*/}
                {/*            fontSize: 16,*/}
                {/*        }}*/}
                {/*        onClick={() => {*/}
                {/*            // 직접 후원하기 팝업 노출*/}
                {/*        }}>{lang_data.direct_sponsorship_label}*/}
                {/*    </button>*/}
                {/*</ReservDescription>*/}

                <div className={'comment_area'}>
                    {comment_list.map((item, index) => {
                        return (
                            <div className={'comment_tab'} key={index}>
                                <div className={"id_time"}>
                                    <div style={{marginRight: 20, fontWeight: "bold"}}>{item.userId}</div>
                                    <div>{moment(item.time).format("YY.MM.DD HH:mm")}</div>
                                    <div className={'delete'}
                                         style={{display: item.isMine ? "" : "none"}}
                                         onClick={() => {
                                             this.setState({deletePopup: true, deletedIdx: index});
                                         }}>{lang_data.delete_label}
                                    </div>
                                    <div className={'report'}
                                         style={{display: item.isMine ? "none" : ""}}
                                         onClick={() => {
                                             this.setState({showReportPopup: true, comment_id: item.id})
                                         }}>
                                        <img src="/static/img/ext_btn.png" style={{height: 20}}/>
                                    </div>
                                </div>
                                <div className={'comment'}>{item.text}</div>
                            </div>
                        )
                    })}
                </div>
                {
                    this.my_contents &&
                    <div className={'add_comment'}>
                        <div className={'buttons'}>
                            <div className={"modify"} style={{marginRight: 'auto'}}
                                 onClick={() => {
                                     if (n_like > 0) {
                                         this.setState({modifyPopup: true})
                                     } else {
                                         props.history.replace("/editarticle/" + this.id)
                                     }
                                 }}>{lang_data.modify_label}</div>
                            <div className={'modify'}
                                 style={{marginLeft: 'auto'}}
                                 onClick={() => {
                                     this.setState({articleDelPopup: true})
                                 }}>{lang_data.delete_label}</div>
                        </div>
                    </div>
                }
                {
                    !this.my_contents &&
                    <div className={'add_comment'}>
                        <div className={'yours'}>
                            <textarea className={'story'} id={'textarea'} value={comment}
                                      placeholder={lang_data.comment_placeholder_label}
                                      onChange={(e) => {
                                          this.setState({comment: e.currentTarget.value});
                                      }}/>
                        </div>
                        <div className={'send'}
                             onClick={() => {
                                 if (comment === '') {
                                     this.setState({commentPopup: true})
                                 } else {
                                     this.onAddComment().then(r => {
                                     });
                                 }
                             }}
                        >
                            {lang_data.comment_send_label}
                        </div>
                    </div>
                }
            </Wrap>
        </>;
    }
}
