export const API_PATH = process.env.NODE_ENV === 'production' ? '/api' : /*'http://localhost:1234';*/'http://app.everythanks.com/api';
// export const API_PATH = 'http://172.104.166.180/api';

async function get(url) {
    const path = `${API_PATH}${url}`;
    console.log("path = " + path)
    const resp = await fetch(path, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    })

    return await resp.json();
}

async function post(url, data) {
    const path = `${API_PATH}${url}`;
    console.log("path = " + path)
    const resp = await fetch(path, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    })

    return await resp.json();
}

export const API = {
    signup: async function (userId, passwd, email, txPassword, reference, no_mentor_id, support_type) {
        const data = { userId, passwd, email, txPassword, reference, no_mentor_id, support_type };
        return post('/signup', data);
    },
    getSupport: async function() {
        return get('/support');
    },
    getDonationBtc: async function() {
        return get('/get_donation_btc');
    },
    getSupportBtc: async function() {
        return get('/get_support_all_btc');
    },
    getVoteCount: async function() {
        return get('/get_vote_count');
    },
    logout: async function() {
        return get('/logout');
    },
    isUserExists: async function (user) {
        return get('/user/exists?userId=' + user);
    },
    isExistsEmail: async function (user) {
        return get('/user/exists_email?email=' + user);
    },
    support: async function (txPassword) {
        const data = { txPassword };
        return post('/support', data);
    },
    me: async function() {
        return post('/me');
    },
    language: async function(en) {
        return post('/language', {en});
    },
    getDorothyTotal: async function() {
        return get('/dorothy/total');
    },
    getDorothyHistory: async function () {
        return get('/dorothy/history');
    },
    getDorothyWinning: async function () {
        return get('/dorothy/winning');
    },
    chartTransactions: async function() {
        return get('/transactions/charge');
    },
    checkAddress: async function (address) {
        const data = { address};
        return post('/check_address', data);
    },
    withdraw: async function (address, amount, txPassword, userId) {
        const data = { address, amount, txPassword, userId };
        return post('/withdraw', data);
    },
    withdrawal: async function () {
        return post('/withdrawal');
    },
    resetPassword: async function(email) {
        return post('/reset-password', { email });
    },
    withdrawTransactions: async function () {
        return get('/transactions/withdraw');
    },
    findId: async function(email) {
        const data = { email };
        return post('/find-id', data);
    },
    changeEmail: async function(email) {
        const data = { email };
        return post('/user/change-email', data);
    },
    changePassword: async function (current, password) {
        const data = { current, password };
        return post('/user/change-password', data);
    },
    changeTxPassword: async function (current, password) {
        const data = { current, password };
        return post('/user/change-tx-password', data);
    },
    updateSetting: async function (type, value) {
        const data = { type, value };
        return post('/user/change-setting', data);
    },
    updatePhone: async function (phone_nation, phone) {
        const data = { phone_nation, phone };
        return post('/user/change-phone', data);
    },
    updatePhoneBirth: async function (birthday, phone_nation, phone) {
        const data = { birthday, phone_nation, phone };
        return post('/user/change-phone-birth', data);
    },
    getErts: async function () {
        return get('/user/get-erts');
    },
    getVotes: async function (userId, my_contents, nation, classification, orderby, search, pageNum, length) {
        const data = { userId, my_contents, nation, classification, orderby, search, pageNum, length };
        return post('/user/get-votes', data);
    },
    getVoteDetail: async function (userId, id) {
        const data = {userId, id};
        return post('/user/detail-vote', data);
    },
    writeVote: async function (id, classification, title, name, address, content, phone_nation, phone) {
        const data = { id, classification, title, name, address, content, phone_nation, phone };
        return post('/user/write-vote', data);
    },
    likeVote: async function (vote_id, status) {
        const data = { vote_id, status };
        return post('/user/like-vote', data);
    },
    commentVote: async function (vote_id, comment) {
        const data = { vote_id, comment };
        return post('/user/comment-vote', data);
    },
    commentDelete: async function (vote_id, id) {
        const data = { vote_id, id };
        return post('/user/comment-delete', data);
    },
    voteDelete: async function (id) {
        const data = { id };
        return post('/user/vote-delete', data);
    },
    procReport: async function (content_id, type, proc_type) {
        const data = { type, content_id, proc_type };
        return post('/proc_report', data);
    },
    signin: async function(userId, passwd) {
        const data = { userId, passwd };
        return post('/login', data);
    },
    pendingSupport: async function() {
        return get('/support_amount');
    },

    branchData: async function(branch) {
        return post('/branch_data', {branch});
    },

    getVerifyCode: async function(email) {
        return post('/verify-code', { email });
    },
    checkCode: async function(email, code) {
        return post('/check-code', { email, code });
    },
    getCoin: async function() {
        // const rp = require('request-promise');
        // const requestOptions = {
        //     method: 'GET',
        //     uri: 'https://undefined/v1/flat/map',
        //     qs: {
        //         'start': '1',
        //         'limit': '5000',
        //         'convert': 'USD'
        //     },
        //     headers: {
        //         'X-CMC_PRO_API_KEY': '86775744-5dde-4335-84dc-44654afcf207'
        //     },
        //     json: true,
        //     gzip: true
        // };
        //
        // rp(requestOptions).then(response => {
        //     console.log('API call response:', response);
        //     return response;
        // }).catch((err) => {
        //     console.log('API call error:', err.message);
        //     return null;
        // });
    },
    changeEth: async function(address) {
        const data = { address };
        return post('/user/change-eth', data);
    },
    insertInquiry: async function (category, msg) {
        const data = { category, msg};
        return post('/insert_inquiry', data);
    },

    notices: async function () {
        const data = {};
        return post('/notice', data);
    },

    getIp: async function() {
        return get('/get_ip');
    },

    loginHistory: async function (id, ip) {
        const data = { id, ip};
        return post('/insert_login_history', data);
    },
    getTermData: async function (type) {
        const data = { type };
        return post('/get_term_data', data);
    },
    getInquiry: async function () {
        const data = {};
        return post('/get_inquiry', data);
    },

    procPayment: async function(card_num) {
        const data = {card_num};
        return post('/proc_payment', data);
    },
    setUtc: async function(utc) {
        const data = {utc};
        return post('/set_utc', data);
    },
    setSupportType: async function(userId, support_type) {
        const data = {userId, support_type};
        return post('/set_support_type', data);
    },
    amountPay: async function(userId, amount) {
        const data = { userId, amount };
        return post('/amount_pay', data);
    },
    addressPay: async function(userId, address) {
        const data = { userId, address };
        return post('/address_pay', data);
    },
    cardAddress: async function(userId, address, address_detail, city, postal_code, nation) {
        const data = { userId, address, address_detail, city, postal_code, nation };
        return post('/card_address', data);
    },

    cardDetail: async function(userId, number, date, sec_code) {
        const data = { userId, number, date, sec_code };
        return post('/card_detail', data);
    },

    buyMoonpay: async function(userId, name, address) {
        const data = { userId, name, address };
        return post('/buy_moopay', data);
    },
}

export default API;
