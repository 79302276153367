import React from 'react';
import API from './utils/api';
import NavigationBar from './components/NavigationBar';
import {Button, Input} from './styled-components';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";

const Title = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
    img {
        vertical-align: middle;
    }
`;

const Wrap = styled.div`
    margin: 24px;
    ${Input} {
        margin-bottom: 24px;
        box-shadow: none;
        border: 1px solid #D1D1DB;
    }
`;

const ChangeButton = styled(Button)`
    &:disabled {
        background: #D1D1DB;
    }
`;

class ChangePassword extends React.PureComponent {
    state = {
        currentPassword: '',
        newPassword: '',
        newRePassword: '',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "change_pwd";
        // const browser = await SignIn.launch();
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {
        const {currentPassword, newPassword, newRePassword, lang_data} = this.state;
        const disabled = currentPassword === '' || !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/.test(newPassword) || newRePassword !== newPassword;
        return <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <Title><img src="/static/img/icon_pass_dark.png" width="22"/>{lang_data.change_pwd_label}</Title>
            <Wrap>
                <Input
                    type="password"
                    placeholder={lang_data.cur_pwd_label}
                    value={currentPassword}
                    onChange={e => this.setState({currentPassword: e.currentTarget.value})}/>
                <Input
                    type="password"
                    placeholder={lang_data.new_pwd_label}
                    value={newPassword}
                    onChange={e => this.setState({newPassword: e.currentTarget.value})}/>
                <Input
                    type="password"
                    placeholder={lang_data.confirm_pwd_label}
                    value={newRePassword}
                    onChange={e => this.setState({newRePassword: e.currentTarget.value})}/>
                <ChangeButton className="green" disabled={disabled} onClick={async () => {
                    if (await API.changePassword(currentPassword, newPassword)) {
                        alert(lang_data.change_password_completed);
                        this.setState({currentPassword: '', newPassword: '', newRePassword: ''});
                    } else {
                        alert('Failed to change password');
                    }
                }}>{lang_data.change_label}</ChangeButton>
            </Wrap>
        </div>
    }
}

export default ChangePassword;
