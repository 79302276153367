import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;


const SummaryWrapper = styled.div`
    border: 2px solid #D1D1DB;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    .title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 12px;
        color: #666A80;
    }
`;

const AvailableBalanceWrapper = styled.div`
    
    line-height: 22px;
    height: 22px;
    margin-bottom : 5px;
    & > span {
        display: inline-block;
        vertical-align: middle;
    }
    .btc {
        float: right;
        font-weight: bold;
    }
`;

//Purcharse 6단계화면
export default class ChargeMakePayment extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        card_num: '',
        btc_address: '',
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_make_payement";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user;
            this.setState({
                card_num: me.user.card_number,
                //btc_address: me.user.address_temp
            });
        }
    }

    render() {

        const props = this.props;
        const user = this.state.me;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Make a payment
            </GuideTitle>
            <div style={{margin: 21}}>
                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Method of payment
                </div>
                <Input type="text" placeholder={'VISA 5085'} value={this.state.card_num} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({card_num: e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * BTC address to be sent
                </div>
                <Input type="text" placeholder={'3Bk48vqoXD8kmTC15TEvuB9BbXVkE7uh6A'} value={this.state.btc_address} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({btc_address: e.currentTarget.value})}/>

                <SummaryWrapper>
                    <div className="title">Summary</div>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Amount to be received</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>~ 0.00052 BTC </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Base Cost</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>US$30.00 </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Processing Fee</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>US$3.99 </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Network Fee</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>US$10.98 </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12, fontWeight: "bold"}}>Sum</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>US$44.9 </span>
                    </AvailableBalanceWrapper>
                </SummaryWrapper>

                <div style={{color: '#999999'}}>
                    <input className="form-control" type="checkbox" name="item[checkbox]" style={{marginRight: 10}} />
                    I agree with you below.
                </div>
                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * I accept MoonPay's Terms and Conditions and
                    authorize MoonPay to impose the above amount
                    on the optional payment method as of today.
                </div>
                <div style={{marginTop: 24, fontSize: 12, marginBottom: 10,}}>
                    * Please review your order carefully as payment is
                    not subject to cancellation, withdrawal or refund
                </div>

                <Button className="green" onClick={
                    async () => {
                        if (this.state.card_num === '' || this.state.btc_address === '') {
                            alert("Incorrect input info, please check.");
                            return;
                        }

                        let result = await API.cardDetail(user.userId, this.state.card_number, this.state.card_date, this.state.card_sec_code);
                        if (result.status === 'success') {
                            window.location.href = ('/#/Charge/ChargePurcharseComp');
                        }
                    }
                } > Buy BTC </Button>
            </div>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

