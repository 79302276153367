import React from 'react';
import styled from 'styled-components';

const TabWrapper = styled.div`
    display: flex;
    background-color: #E7E7EF;
    color: #666A80;
    height: 60px;
`;

const TabButton = styled.div`
    flex: 1;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    &.selected {
        color: #EA589E;
        font-weight: bold;
    }
`;

function Tab(props) {
    return <TabWrapper>
        {props.tabs.map((e, i) =>
            <TabButton
                key={i}
                onClick={() => props.onTabClick(e.value)}
                className={props.selected === e.value ? 'selected' : ''}
            >
                {e.text}
            </TabButton>
        )}
    </TabWrapper>
}

export default Tab;
