import React from 'react';
import styled from 'styled-components';
import {Button, Input} from './styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import NavigationBar from "./components/NavigationBar";
import {ArticlePopup} from "./components/ArticlePopup";
import GlobalState from "./mobx/GlobalState";
import Common from "./utils/Common";
import ReactCustomFlagSelect from './components/react-custom-flag-select/index.ts';
import {DEFAULT_AREA_CODE, FLAG_SELECTOR_OPTION_LIST} from "./utils/Constants";
import './components/react-custom-flag-select/react-custom-flag-select.css';
import './components/react-custom-datepicker.css';
import API from "./utils/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const NotificationArea = styled.div`
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  padding: 20px;
  background: #FFF1F4;
`;

const Wrap = styled.div`
  padding: 40px 21px 130px 21px;
  background: #F7F7FA;

  ${Input} {
    box-shadow: 1px 1px 3px 2px #00000029;
    border: none;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    height : 50px;
    line-height : 38px;
  }

  .tab {
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .selectbox {
      background: #fff;
      box-shadow: 1px 1px 3px 2px #00000029;
      border: none;
      border-radius: 20px;
      opacity: 1;
      outline: none;
      width: 70%;
      margin-right: 10px;
      height : 50px;
    }
    #datePicker {
      box-shadow: 1px 1px 3px 2px #00000029;
      border: none;
      border-radius: 20px;
      font-size: 14px;
      padding: 16px 14px;
    }
  }
`;

const UploadButton = styled(Button)`
  border-radius: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  letter-spacing: 1px;
  font-size: 18px;
  height : 60px;
`;

class AddInfo extends React.Component {

    isContinued() {
        return this.state.birthday !== '' && this.state.phone !== ''
    }

    state = {
        userId: "",
        birthday: GlobalState.me.birthday == null ? "" : new Date(GlobalState.me.birthday),
        phone: GlobalState.me.phone == null ? "" : GlobalState.me.phone,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        successPopup: false,
        failPopup: false,
        areaCode: GlobalState.me.phone_nation == null ? DEFAULT_AREA_CODE : GlobalState.me.phone_nation,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "add_info";
        document.getElementById('datePicker').readOnly = true;

        window.scrollTo(0, 0);
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        const me = GlobalState.me;
        this.setState({
            birthday: me.birthday == null ? "" : new Date(me.birthday),
            phone: me.phone == null ? "" : me.phone,
            areaCode: me.phone_nation == null ? DEFAULT_AREA_CODE : me.phone_nation,
            userId: me.userId == null ? "" : me.userId
        });
    }

    goBack = () => {
        this.props.history.goBack()
    };

    async onClickUpdatePhoneBirth(birthday, phone_nation, phone) {
        await API.updatePhoneBirth(birthday, phone_nation, phone);
        let me = GlobalState.me;
        me.phone = phone;
        me.phone_nation = phone_nation;
        me.birthday = birthday;
    }

    render() {
        const props = this.props;
        const {areaCode, userId, phone, birthday, lang_data, successPopup, failPopup} = this.state;
        const currentItem = Common.find(FLAG_SELECTOR_OPTION_LIST, {id: areaCode})[0];

        return <>
            <ArticlePopup
                enabled={successPopup}
                description={lang_data.addinfo_success_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={() => {
                    this.props.history.replace('/editarticle/0')
                }}/>
            <ArticlePopup
                enabled={failPopup}
                description={lang_data.addinfo_fail_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={() => {
                    this.setState({failPopup: false})
                }}/>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={lang_data}/>
            <NotificationArea>
                <div style={{fontSize: 20, whiteSpace: "pre-line"}}>{lang_data.addinfo_noti_label}</div>
            </NotificationArea>
            <Wrap>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.member_label} ID</div>
                    <Input readOnly value={userId}/>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.birthday}</div>
                    <DatePicker id={'datePicker'}
                                showMonthDropdown showYearDropdown
                                dateFormat="yyyy-MM-dd" selected={this.state.birthday}
                                onChange={(date) => this.setState({birthday: date})}
                    />
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.member_contact_label}</div>
                    <div style={{display: 'flex'}}>
                        <div className={'selectbox'}>
                            <ReactCustomFlagSelect
                                attributesWrapper={{id: 'areaCodeWrapper', tabIndex: '1'}}
                                attributesButton={{id: 'areaCodeButton'}}
                                attributesInput={{id: 'areaCode', name: 'areaCode'}}
                                name={'areaCode'}
                                value={currentItem.id}
                                disabled={false}
                                showSearch={true}
                                fields={['name', 'locale', 'displayText', 'englishName']}
                                showArrow={true}
                                animate={true}
                                optionList={FLAG_SELECTOR_OPTION_LIST}
                                classNameWrapper="nation_select"
                                classNameContainer=""
                                classNameOptionListContainer=""
                                classNameOptionListItem=""
                                classNameDropdownIconOptionListItem={''}
                                customStyleWrapper={{}}
                                customStyleContainer={{border: 'none', fontSize: '12px'}}
                                customStyleOptionListItem={{}}
                                customStyleOptionListContainer={{maxHeight: '100px', overflow: 'auto', width: '200px'}}
                                onChange={areaCode => {
                                    this.setState({areaCode: areaCode});
                                }}/>
                        </div>
                        <Input
                            placeholder={lang_data.member_contact_placeholder_label}
                            value={phone}
                            onChange={(e) => {
                                if (!isNaN(e.currentTarget.value)) this.setState({phone: e.currentTarget.value});
                            }}/>
                    </div>
                </div>
                <UploadButton
                    onClick={async () => {
                        if (this.isContinued()) {
                            this.onClickUpdatePhoneBirth(moment(birthday).format("YYYY-MM-DD"), areaCode, phone).then(r => this.setState({successPopup: true}));
                        } else {
                            this.setState({failPopup: true})
                        }
                    }}>{lang_data.addinfo_register_label}</UploadButton>
            </Wrap>
        </>;
    }
}

export default AddInfo;
