import React from 'react';
import styled from 'styled-components';
import API from "./utils/api";
import {setLang} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";
import {Popup} from "./components/Popup";

export const IntroBackground = styled.div`
  @keyframes main_bg {
    from {
      background-position: 0% center;
    }
    to {
      background-position: 100% center;
    }
  }
  width: 100vw;
  height: 100vh;
  background: url(/static/img/main_bg.jpg);
  animation: main_bg 30s linear .5s forwards infinite;
  background-size: cover;
  background-position: 0px center;
  position: relative;
`;

export const Title = styled.div`
  background: url(/static/img/main_title.png);
  width: 218px;
  height: 188px;
`;

const Description = styled.div`
  font-size: 16px;
  color: white;
`;

const AirBalloon = styled.div`
  background: url(/static/img/air-balloon.png);
  width: 300px;
  height: 300px;
  background-position: 0 center;
  @keyframes airballon {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(100px);
    }
  }

  transform: translateY(0);
  animation: airballon 5s linear alternate-reverse infinite;
`;

export const LoginWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrap = styled.div`
  position: absolute;
  background: linear-gradient(#E974A9FF, #E974A900);
  height: 400px;
  top: 0;
  left: 0;
  right: 0;
`;

function Intro(props) {
  setTimeout(async () => {
      const me =  await API.me();
      const lang = document.getElementById('app_lang').value;
      setLang(lang);
      if (me.status === 'success') {
          GlobalState.me = me.user;
          window.location.href = ('/#/dashboard');
      } else {
          const login = JSON.parse(localStorage.getItem('login') || '{}');
          if (login.this_id && login.this_passwd && login.this_isAutoLogin === 1) {
              const result = await API.signin(login.this_id, login.this_passwd);
              if (result.status === 'success') {
                  const activate_status = result.user.activate_status;
                  if (activate_status === 'Lock') {
                      props.history.replace('/signin');
                      return;
                  } else if (activate_status === 'Permanent account') {
                      props.history.replace('/signin');
                      return;
                  }
                  const result_ip = await API.getIp();
                  await API.loginHistory(result.user.id, result_ip.ip.replace('::ffff:', ''));
                  GlobalState.me = result.user;
                  props.history.push('/dashboard');
              } else {
                  props.history.replace('/signin');
              }
          } else {
              props.history.replace('/signin');
          }
      }
  }, 5100);
  return (
    <IntroBackground>
      <Wrap />
      <LoginWrap>
        <Title />
        <Description>
          Unconditional sponsorship<br />Platform we own
        </Description>
        <AirBalloon />
      </LoginWrap>
    </IntroBackground>
  );
}

export default Intro;
