import React from 'react';
import styled from 'styled-components';
import {REPORT_PROC_TYPE_POST, REPORT_PROC_TYPE_BLOCK, REPORT_PROC_TYPE_MAKER} from "../utils/Constants";

const BackgroundShadow = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;

    .layer-pop {
        width: 300px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        .b-close {
            position: absolute;
            width: 80px;
            height: 80px;
            background: #fff;
            box-sizing: border-box;
            border-radius: 50%;
            padding: 10px;
            left: auto;
            top: 0px;
            margin-top: -35px;
            right: 50%;
            transform: translateX(50%);
        }
        .popupCont {
            text-align:left;
            border-radius: 15px;
        }
        .caution {
            background: white;
            padding: 20px 20px 22px;

            h3 {
                font-size: 16px;
                color: #666a80;
                padding: 5px 0px 5px;
                line-height: 2.5;
            }
        }

    }
`;

export function ReportPopup({
    enabled,
    menu1,
    menu2,
    menu3,
    type,
    onButtonClick,
    onMenuClick,
}) {
    if (!enabled) {
        return null;
    }

    return (
        <BackgroundShadow onClick={() => onButtonClick()}>
            <div className="layer-pop">
                <div className="popupCont">
                    <div className="caution">
                        <h3 onClick={() => onMenuClick(type, REPORT_PROC_TYPE_POST)}>{menu1}</h3>
                        <h3 style = {{display: menu2 == '' ? "none" : ""}} onClick={() => onMenuClick(type, REPORT_PROC_TYPE_BLOCK)}>{menu2}</h3>
                        <h3 onClick={() => onMenuClick(type, REPORT_PROC_TYPE_MAKER)}>{menu3}</h3>
                    </div>
                </div>
            </div>
        </BackgroundShadow>
    );
}
