import React from 'react';
import styled from 'styled-components';
import Common from "../utils/Common";

const BackgroundShadow = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;

  .ic_close {
    justify-content: flex-end;
    align-items: end;
    display: flex;
  }

  .content {
    width: 100%;
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  .header {
    background: #e974a9;
    height: 54px;
    color: white;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .odd_item {
    background: #cbcbcb;
    height: 54px;
    color: black;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1px;
  }

  .even_item {
    background: #e7e7e7;
    height: 54px;
    color: black;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1px;
  }

  .btn_close {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background: #bfbfbf;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px auto;
  }

`;

export function ErtsDetailPopup({paidList, enabled, onClose, title, colNames, guide, closeTitle}) {
    if (!enabled) {
        return null;
    }
    return (
        <BackgroundShadow>
            <div className="content">
                <div className="ic_close">
                    <img src="/static/img/ic_close.png" width="22" onClick={() => onClose()}/>
                </div>
                <div>{title}</div>
                <div style={{display: "flex", width: "100%", marginTop: 10}}>
                    <div className="header" style={{width: "10%"}}>No.</div>
                    <div className="header" style={{marginLeft: 1, width: "30%"}}>{colNames[0]}</div>
                    <div className="header" style={{marginLeft: 1, width: "30%"}}>{colNames[1]}</div>
                    <div className="header" style={{marginLeft: 1, width: "30%"}}>{colNames[2]}</div>
                </div>
                <div style={{marginTop: 3, maxHeight: 280, overflowY: "auto"}}>
                    {
                        paidList.map((item, index) => {
                            return (
                                index % 2 === 0 ?
                                    <div style={{display: "flex"}} key={index}>
                                        <div className="even_item" style={{width: "10%"}}>{index + 1}</div>
                                        <div className="even_item" style={{marginLeft: 1, width: "30%"}}>{item.date}</div>
                                        <div className="even_item" style={{marginLeft: 1, width: "30%"}}>{item.achieve}</div>
                                        <div className="even_item" style={{marginLeft: 1, width: "30%"}}>{Common.formatNumber(item.quantity)} ERTS</div>
                                    </div> :
                                    <div style={{display: "flex"}} key={index}>
                                        <div className="odd_item" style={{width: "10%"}}>{index + 1}</div>
                                        <div className="odd_item" style={{marginLeft: 1, width: "30%"}}>{item.date}</div>
                                        <div className="odd_item" style={{marginLeft: 1, width: "30%"}}>{item.achieve}</div>
                                        <div className="odd_item" style={{marginLeft: 1, width: "30%"}}>{Common.formatNumber(item.quantity)} ERTS</div>
                                    </div>
                            )
                        })
                    }
                </div>
                <div style={{marginTop: 15, lineHeight: 1.5, padding: 5}}>{guide}</div>
                <div className="btn_close" id={'btn_dlg_close'} onClick={() => onClose()}>{closeTitle}</div>
            </div>
        </BackgroundShadow>
    );
}
