import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {Popup} from "./components/Popup";
import GlobalState from "./mobx/GlobalState";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const AvailableBalanceWrapper = styled.div`
    
    line-height: 22px;
    height: 22px;
    margin-bottom : 5px;
    & > span {
        display: inline-block;
        vertical-align: middle;
    }
    .btc {
        float: right;
        font-weight: bold;
    }
`;

const SummaryWrapper = styled.div`
    border: 2px solid #D1D1DB;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    .title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 12px;
        color: #666A80;
    }
`;

//Purcharse 1단계화면
export default class ChargeBtcPurcharse extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        amount: 0,
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_btc_purchage";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user
        }

    }

    async componentWillUnmount() {

    }

    render() {

        const props = this.props;
        const user = this.state.me;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                BTC Purchase
            </GuideTitle>
            <div style={{margin: 21}}>
                <AvailableBalanceWrapper>
                    <span style={{fontSize: 12}}>Amount</span>
                    <span className="btc">BTC / USD </span>
                </AvailableBalanceWrapper>

                <Input type="number" placeholder={'30'} value={this.state.id} style={{borderRadius: 10, paddingLeft: 20, paddingRight: 20}}
                       onChange={(e) => this.setState({amount: e.currentTarget.value === '' ? 0 : e.currentTarget.value})}/>

                <div style={{marginTop: 24, fontSize: 12}}>
                    * The minimum transaction amount is US$30.00.
                </div>
                <div style={{marginTop: 4, fontSize: 12}}>
                    * The maximum purchase price per time is less than $3,000.
                </div>

                <SummaryWrapper>
                    <div className="title">Summary</div>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Current BTC price</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}> US${user.btcPrice} </span>
                    </AvailableBalanceWrapper>
                    <AvailableBalanceWrapper>
                        <span style={{fontSize: 12}}>Amount to received</span>
                        <span className="btc" style={{fontSize: 12, fontWeight: "normal"}}>{this.state.amount / user.btcPrice} BTC </span>
                    </AvailableBalanceWrapper>
                </SummaryWrapper>

                <Button className="green" onClick={
                    async () => {
                        if (this.state.amount < 30 || this.state.amount > 3000) {
                            //The amount must be between 30 and 3000$.
                            this.setState({popup_show_fail: this});
                            return;
                        }
                        //props.amount = this.state.amount;
                        const result = await API.amountPay(user.userId, this.state.amount);
                        if (result.status == 'success') {
                            window.location.href = ('/#/Charge/ChargeWalletAddress');
                        }
                    }
                }> Continue </Button>


                <Popup
                    enabled={this.state.popup_show_success}
                    isSuccess={false}
                    title={'Error'}
                    description={'Payment was successful.'}
                    buttonTitle={this.state.lang_data.ok_label}
                    onButtonClick={() => {
                        this.setState({
                            popup_show_success: false,
                        });
                        this.props.history.goBack();
                    }}
                />

                <Popup
                    enabled={this.state.popup_show_fail}
                    isSuccess={false}
                    title={'Error'}
                    description={'The amount must be between 30 and 3000$'}
                    buttonTitle={this.state.lang_data.ok_label}
                    onButtonClick={() => {
                        this.setState({
                            popup_show_fail: false,
                        });
                    }}
                />
            </div>
            </>
    }
}

