import React from 'react';
import styled from 'styled-components';
import NavigationBar from './components/NavigationBar';
import {Button, DashboardBox} from './styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import GlobalState from "./mobx/GlobalState";

const Wrap = styled.div`
    background: linear-gradient(#E26FA4, #F8CB9B);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-align: center;
`;

const DashboardProfileBox = styled(DashboardBox)`
    grid-column: 1/3;
    grid-row: 1/3;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 16px;
    text-align: center;
    
    background-color: transparent;
    box-shadow : none;
    .counter {
        margin-right: 16px;
        flex: 1;
    }
`;

class Congrats extends React.PureComponent {
    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "congrats";
        // const browser = await SignIn.launch();
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user
        }
    }

    render() {
        const props = this.props;
        const {lang_data, me} = this.state;
        return <>
            <Wrap>
                <NavigationBar
                    hideBack
                    title={"EVERY THANKS"}
                    hideMenu
                    lang_data={lang_data}/>
                <DashboardProfileBox style={{padding: '12px 12px', boxSizing: 'border-box'}}>
                    <img src="/static/img/cong_balloon.png" style={{width: 200, marginTop: 60}}/>
                </DashboardProfileBox>
                <DashboardProfileBox style={{ padding: '0 12px'}}>
                    <div style={{fontSize: 40, fontFamily: 900, textAlign:"center", color:"white"}}><span>{lang_data.congrats_label}</span></div>
                </DashboardProfileBox>
                <DashboardProfileBox style={{ padding: '0 12px',}}>
                    <div style={{fontSize: 20, fontFamily: 900, textAlign:"center", color:"white"}}><span>{me.rank}&nbsp;{lang_data.angle_complete_label} </span></div>
                </DashboardProfileBox>
                <DashboardProfileBox style={{ padding: '12px 12px', marginTop: 40}}>
                    <Button
                        className="green"
                        onClick={() => {
                            props.history.push('/dashboard');
                        }}
                        style={{width:200}}
                    >{lang_data.ok_label}</Button>
                </DashboardProfileBox>
            </Wrap>
        </>
    }
}

export default Congrats;
