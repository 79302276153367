import React from 'react';
import styled from 'styled-components';

const BackgroundShadow = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;

    .layer-pop {
        width: 300px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        .b-close {
            position: absolute;
            width: 80px;
            height: 80px;
            background: #fff;
            box-sizing: border-box;
            border-radius: 50%;
            padding: 10px;
            left: auto;
            top: 0px;
            margin-top: -35px;
            right: 50%;
            transform: translateX(50%);
        }
        .popupCont {
            text-align:left;
            border-radius: 15px;
        }
        .caution {
            background: white;
            padding: 20px 20px 22px;

            h3 {
                font-size: 21px;
                color: #666a80;
                font-weight: 700;
                padding: 5px 0px 5px;
                line-height: 1.5;
                letter-spacing: 0px;
            }
            
            h4 {
                font-size: 21px;
                color: #666a80;
                font-weight: 700;
                padding: 15px 0px 10px;
                line-height: 1.5;
                letter-spacing: 0px;
            }

            .comment {
                font-size: 14px;
                color: #666a80;
                line-height: 1.5;
                padding: 1px 0px;
                margin: 0;
            }
        }

    }
`;

export function DorothyPopup({
    enabled,
    title,
    description,
    onButtonClick,
}) {

    if (!enabled) {
        return null;
    }

    return (
        <BackgroundShadow onClick={() => onButtonClick()}>
            <div className="layer-pop">
                <div className="popupCont">
                    <div className="caution">
                        {
                            <h3>{title}</h3>
                        }
                        <p className="comment">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </BackgroundShadow>
    );
}
