import React from 'react';
import NavigationBar from './components/NavigationBar';
import {getLangData, readLangData} from "./utils/LangState";
import styled from "styled-components";

const HelpContent = styled.div`
  padding: 20px;
  margin: 20px;
  border: #bfbfbf 1px solid;
  border-radius: 40px;
  height: 100%;
    overflow-y: auto;
`;

const HelpSubContent = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export default class Help extends React.PureComponent {
    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "help";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
        });
    }

    render() {
        const {lang_data} = this.state;
        return <div style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <HelpContent>
                <HelpSubContent>
                    <div style={{fontWeight: "bolder"}}>{lang_data.erts_help_title}</div>
                    <div style={{background: "#bfbfbf", height: 1, marginTop: 20}}/>
                    <div style={{
                        marginTop: 20,
                        fontSize: 15,
                        lineHeight: 1.8
                    }}>{lang_data.erts_help_content_1}</div>
                    <div style={{
                        marginTop: 20,
                        fontSize: 15,
                        lineHeight: 1.8,
                        fontWeight: "bold"
                    }}>{lang_data.erts_help_content_2}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_3}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_4}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_5}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_6}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_7}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_8}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_9}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_10}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_11}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_12}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_13}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_14}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_15}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 14,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_16}</div>
                    <div style={{
                        marginTop: 2,
                        fontSize: 12,
                        lineHeight: 1.8,
                        color: "#848484"
                    }}>{lang_data.erts_help_content_17}</div>
                </HelpSubContent>
            </HelpContent>
        </div>
    }
}