import React from 'react';
import NavigationBar from './components/NavigationBar';
import API from './utils/api';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import moment from "moment";
import Common from "./utils/Common";
import ReactCustomFlagSelect from './components/react-custom-flag-select/index.ts';
import {DEFAULT_AREA_CODE, FLAG_SELECTOR_OPTION_LIST, ITEM_LENGTH, REPORT_TYPE_POST} from "./utils/Constants";
import './components/react-custom-flag-select/react-custom-flag-select.css';
import GlobalState from "./mobx/GlobalState";
import {ReportPopup} from "./components/ReportPopup";

const VotingBody = styled.div`
  min-height: calc(100vh - 62px);
  background-color: #fafafa;
`;

const NotificationArea = styled.div`
  background-size: contain;
  font-size: 17px;
  line-height: 25px;
  text-align: justify;
  padding: 30px 20px;
  white-space: pre-line;
  background-color: #fff1f4;
  display:flex;
`;

const NotificationAreaLabel = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  padding: 0px 0px 0px 10px;
  white-space: pre-line;
  top: 62px;
  color: black;
`;

const RecomList = styled.div`
  background-color: white;
  height: 40px;
  align-items: center;
  padding: 10px;
  display: flex;
  font-weight: bold;

  .me {
    background-color: #ff4c89;
    border: none;
    border-radius: 12px;
    color: white;
    width: 100px;
    margin-left: auto;
    padding: 5px;
    letter-spacing: 0.5px;
  }
`;

const WrapContent = styled.div`
  padding-top: 15px;

  .no_info {
    padding-top: 30px;
    padding-bottom: 30px;
    font: 20px bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagechange {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      padding: 10px;
    }
  }
`;

const SearchArea = styled.div`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;

  .search {
    padding: 10px;
    min-width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: white;
    border : none;
    box-shadow: 1px 1px 3px 2px #00000029 !important;
    font-size: 16px;
  }

  .search_word {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    border : none;
  }
  
  .search_word_parent {
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    border-radius: 20px;
    border : none;
    box-shadow: 1px 1px 3px 2px #00000029 !important;
  }
`;

const ClassificationDetail = styled.div`
  width: 90%;
  height: 40px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  select {
    padding-left: 5px;
    padding-right: 5px;
    height: 40px;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 2px #00000029 !important;
  }
  
  .select_div {
    margin-left: 5px;
    height: 40px;
    align-items: center;
    justify-content: center;
    width: 35%;
    border: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 2px #00000029 !important;
  }

  .country, .registration {
    text-align: center;
    border:none;
    border-radius: 20px;
    .nation_select {
      height: 31px;
      border: solid cornflowerblue 2px;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      background: white;
      box-shadow: 1px 1px 3px 2px #00000029 !important;
    }
  }
  
  .select_reg_div {
    margin-left: 5px;
    width: 30%;
    text-align: center;
    border:none;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 2px #00000029 !important;
  }
`;

const Board = styled.div`
  margin-top: 20px;
  padding: 5px;

  .tab {
    margin-top: 5px;
    height: 65px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;

    &:last-child {
      border-bottom: none;
    }

    .voting {
      font-size: 12px;
      margin-top: 5px;
      background-color: #FF4C89;
      width: 70px;
      height: 25px;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius : 12px;
      color : white;
      padding-left: 5px;
      padding-right: 5px;
      line-height: 24px;
    }
    
    .voting.selected {
      background-color: #0085FF;
    }

    .voting.voting_end {
      background-color: #303030;
    }
    
    .donee {
        width: 70px;
        margin-top: 5px;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        height: 25px;
        background-color: #E7E7EF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        border-radius : 12px;
        line-height: 24px;
      }
  }
`;

const DoneeandTitle = styled.div`
  height: 25px;
  margin-left: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;

  .donee {
    width: 70px;
    margin-top: 5px;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    background-color: darkgray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .title {
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
    font-size: 15px;
    margin-top: 5px;
    text-align: center;
  }
`;

const MainDetail = styled.div`
  display: block;
  width: 40%;
  height: 65px;
  margin-left: 10px;
`;

const UserandTime = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  align-items: center;

  .user_id {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    
  }

  .date {
    padding-left: 5px;
    color: #4c3d3d;
    padding-top: 5px;
    font-size: 11px;
  }
  
  .title {
    padding-left: 5px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
  }
`;

const VoteComment = styled.div`
  margin-top: 5px;
  margin-left: auto;

  .count {
    font-size: 14px;
    text-align: center;
    height: 25px;
  }

  img {
    width: 25px;
    height: 25px;
  }
`;

const WriteOwn = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    width: 60px;
    height: 60px;
  }
`;

const ListBg = styled.div`
    background-color: white;
    padding-top: 15px;
    margin: 20px;
    margin-bottom: 15px;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 2px #00000029;
`;

export default class Voting extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        article_list: [],
        my_contents: 0,
        current_page: 1,
        total_page: 1,
        keyword: "",
        areaCode: DEFAULT_AREA_CODE,
        classification: "-1",
        order_by: "-1",
        is_empty: false,
        needProfile: true,
        nationList: FLAG_SELECTOR_OPTION_LIST,
        showReportPopup: false,
        report_id : 0,
    };

    async search(refresh = true) {
      let {my_contents, areaCode, classification, order_by, keyword, current_page} = this.state;
      if (refresh) {
        current_page = 1
      }
      let area = areaCode;
      if (areaCode == this.state.lang_data.nation_label) {
          area = 'All';
      }
      const me = GlobalState.me;
      const votes = await API.getVotes(me.userId, my_contents, area, classification, order_by, keyword, current_page, ITEM_LENGTH);
      if (votes.status == 'success') {
        let list = [];
        const result = votes.result;
        for (let i = 0; i < result.length; i++) {
            list.push({
                id: result[i].id,
                vote_status: result[i].status,
                type: result[i].classification,
                user_id: result[i].userId,
                title: result[i].title,
                createAt: result[i].createdAt,
                comments: result[i].comment_count,
                like: result[i].like_count
            });
        }
        this.setState({
            article_list: list,
            current_page: current_page,
            total_page: votes.pageCount
        }, () => {
            this.setState({is_empty: list.length === 0})
        });
      }
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "voting";
        window.scrollTo(0, 0);

        const language = document.getElementById('app_lang').value;
        await readLangData(language);

        this.setState({
            language: language,
            lang_data: getLangData()
        }, () => {
            let nationList = [];
            let strNation = this.state.lang_data.nation_label;
            nationList.push({
                id: strNation,
                name: 'All',
                displayText: 'All',
                englishName: '',
                locale: 'zh-CN',
                flag: ''
            });
            for (let i = 0; i < FLAG_SELECTOR_OPTION_LIST.length; i++) {
                nationList.push(FLAG_SELECTOR_OPTION_LIST[i])
            }
            this.setState({nationList: nationList, areaCode: strNation}, () => {
              this.search();
            })
        });

        const me = GlobalState.me;
        this.setState({
            needProfile: me.birthday == null || me.phone == null || me.phone_nation == null
        });

        document.getElementById('root').height = '100vh'
    }

    render() {

        const props = this.props;
        const {lang_data, areaCode, nationList, article_list, my_contents, current_page, total_page, keyword, classification, order_by, is_empty, needProfile, showReportPopup, report_id} = this.state;
        let constItemId = "All";
        const findItem = Common.find(nationList, {id: areaCode});
        if (findItem.length > 0) {
            constItemId = findItem[0].id;
        }
        const voting_tab_classes = ["voting", "voting voting_end", "voting selected"];
        const voting_tab_labels = [lang_data.voting_label, lang_data.voting_end_label, lang_data.selected_as_donee_label];
        const voting_classification_labels = [
            lang_data.classification1_label,
            lang_data.classification2_label,
            lang_data.classification3_label,
            lang_data.classification4_label,
            lang_data.classification5_label,
            lang_data.classification6_label,
            lang_data.classification7_label,
            lang_data.classification8_label,
            lang_data.classification9_label,
            lang_data.classification10_label,
            lang_data.classification11_label,
            lang_data.classification12_label,
        ];

        return <>
            <ReportPopup
                enabled={showReportPopup}
                menu1={lang_data.str_report}
                menu2={lang_data.str_block}
                menu3={lang_data.str_report_writer}
                type={REPORT_TYPE_POST}
                onButtonClick={() => {
                    this.setState({ showReportPopup: false, report_id : 0 })
                }}
                onMenuClick={async (type, proc_type) => {
                    this.setState({ showReportPopup: false })
                    const report = await API.procReport(report_id, type, proc_type);
                    if (report.status == 'success') {
                        this.setState({ report_id: 0 })
                        alert(lang_data.str_reported)
                    }
                }}/>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={lang_data}
                title={lang_data.donation_recommendation_label2}/>
            <VotingBody>
                <NotificationArea>
                    <div style={{verticalAlign: 'middle'}}>
                        <img src="/static/img/voting_bg.png" style={{
                            marginTop: 15, width:300
                        }}/>
                    </div>
                    <NotificationAreaLabel>{lang_data.donation_recom_noti_label0}<span style={{backgroundColor: "#ffbfc4", fontWeight: "bold"}}>{lang_data.donation_recom_noti_label1}</span>{lang_data.donation_recom_noti_label2}<span style={{backgroundColor: "#ffbfc4", fontWeight: "bold"}}>{lang_data.donation_recom_noti_label3}</span>{lang_data.donation_recom_noti_label4}<span style={{backgroundColor: "#ffbfc4", fontWeight: "bold"}}>{lang_data.donation_recom_noti_label5}</span>{lang_data.donation_recom_noti_label6}</NotificationAreaLabel>
                </NotificationArea>
                <RecomList>{lang_data.donation_recom_list_label}
                    <button
                        className={'me'}
                        onClick={() => {
                            this.setState({my_contents: !my_contents}, () => {
                                this.search().then(r => {})
                            })
                        }}
                        style={{backgroundColor: my_contents ? "black" : ""}}>
                        {lang_data.contents_by_me_label}
                    </button>
                </RecomList>
                <WrapContent>
                    <SearchArea>
                        <div className={'search_word_parent'}>
                            <input
                                className={'search_word'}
                                type="text"
                                value={keyword}
                                onChange={e => this.setState({keyword: e.currentTarget.value}, ()=>{
                                    this.search().then(r => {})
                                })}
                                placeholder={lang_data.search_word_label}
                            />
                        </div>
                        <button className={'search'} onClick={() => {
                            this.search().then(r => {})
                        }}>
                            {lang_data.search_label}
                        </button>
                    </SearchArea>
                    <ClassificationDetail>
                        <div className={'country'} style={{display: my_contents ? "none" : ""}}>
                            <ReactCustomFlagSelect
                                attributesWrapper={{id: 'areaCodeWrapper', tabIndex: '1'}}
                                attributesButton={{id: 'areaCodeButton'}}
                                attributesInput={{id: 'areaCode', name: 'areaCode'}}
                                name={'areaCode'}
                                value={constItemId}
                                disabled={false}
                                showSearch={true}
                                fields={['name', 'locale', 'displayText', 'englishName']}
                                showArrow={true}
                                animate={true}
                                optionList={nationList}
                                classNameWrapper="nation_select"
                                classNameContainer=""
                                classNameOptionListContainer=""
                                classNameOptionListItem=""
                                classNameDropdownIconOptionListItem={''}
                                customStyleWrapper={{border: 'none', fontSize: '12px', height: '40px', borderRadius: '20px'}}
                                customStyleContainer={{border: 'none', fontSize: '12px'}}
                                customStyleOptionListItem={{}}
                                customStyleOptionListContainer={{maxHeight: '100px', overflow: 'auto', width: '200px'}}
                                onChange={areaCode => {
                                    this.setState({areaCode: areaCode}, () => {
                                        this.search().then(r => {})
                                    });
                                }}
                            />
                        </div>
                        <div className={'select_div'} style={{display: my_contents ? "none" : ""}}>
                            <select style={{display: my_contents ? "none" : ""}}
                                    value={classification}
                                    onChange={e => this.setState({classification: e.currentTarget.value}, () => {
                                        this.search().then(r => {})
                                    })}>
                                <option value="-1">{lang_data.classification_label}</option>
                                {voting_classification_labels.map((item, index) => {
                                    return (
                                        <option value={index} key={index}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className={'select_reg_div'} style={{marginLeft: my_contents ? "60%" : "" }}>
                            <select className={'registration'}
                                    style={{textAlign: "center"}}
                                    value={order_by}
                                    onChange={e => this.setState({order_by: e.currentTarget.value}, () => {
                                        this.search().then(r => {})
                                    })}>
                                <option value="-1">{lang_data.registration_order_label}</option>
                                <option value="recom_order">{lang_data.recom_order_label}</option>
                                <option value="comment_order">{lang_data.comment_order_label}</option>
                                <option value="completion_order">{lang_data.completion_order}</option>
                            </select>
                        </div>
                    </ClassificationDetail>
                    <Board>
                        <ListBg>
                        {!is_empty &&
                        article_list.map((item, index) => {
                            return (
                                <div className={'tab'} key={index} >
                                    <div onClick={() => {
                                        const tmp_my_contents = my_contents ? 1 : 0;
                                        props.history.push('/article/' + item.id + '/' + tmp_my_contents)
                                    }}>
                                        <div
                                            className={voting_tab_classes[item.vote_status]}>{voting_tab_labels[item.vote_status]}</div>
                                        <div className={'donee'}>{voting_classification_labels[item.type]}</div>
                                    </div>

                                    <MainDetail onClick={() => {
                                        const tmp_my_contents = my_contents ? 1 : 0;
                                        props.history.push('/article/' + item.id + '/' + tmp_my_contents)
                                    }}>
                                        <UserandTime>
                                            <div className={'title'}>{item.title}</div>
                                            <div className={'user_id'}>{item.user_id}</div>
                                            <div
                                                className={'date'}>{moment(item.createAt).format("YY.MM.DD HH:mm")}</div>
                                        </UserandTime>
                                    </MainDetail>
                                    <VoteComment onClick={() => {
                                        const tmp_my_contents = my_contents ? 1 : 0;
                                        props.history.push('/article/' + item.id + '/' + tmp_my_contents)
                                    }}>
                                        <div className={'count'}>{Common.formatNumber(item.comments)}</div>
                                        <div className={'count'}>{Common.formatNumber(item.like)}</div>
                                    </VoteComment>
                                    <VoteComment style={{marginLeft: 10, width: 25}} onClick={() => {
                                        const tmp_my_contents = my_contents ? 1 : 0;
                                        props.history.push('/article/' + item.id + '/' + tmp_my_contents)
                                    }}>
                                        <img src="/static/img/voting_edit.png" style={{height: 20}}/>
                                        <img style={{marginTop:-3}} src="/static/img/ic_heart.png"/>
                                    </VoteComment>
                                    <VoteComment style={{marginLeft: 10}}>
                                        <div onClick={() => {
                                            this.setState({showReportPopup: true, report_id: item.id})
                                        }}>
                                            <img src="/static/img/ext_btn.png" style={{height: 20}}/>
                                        </div>
                                    </VoteComment>
                                </div>
                            )
                        })}
                        {is_empty &&
                        <div className={'no_info'}>{lang_data.no_information}</div>
                        }

                            {
                                !is_empty &&
                                <div className={'pagechange'}>
                                    <img style={{width: 25}}
                                         src={current_page === 1 ? '/static/img/ic_arrow_left_gray.png' : '/static/img/ic_arrow_left_black.png'}
                                         onClick={() => {
                                             if (current_page !== 1) {
                                                 this.setState({current_page: current_page - 1}, () => {
                                                     this.search(false).then(r => {})
                                                 })
                                             }
                                         }}/>
                                    <div style={{paddingRight: 5}}>{current_page}</div>
                                    <div style={{color: "grey"}}> / {total_page}</div>
                                    <img style={{width: 25}}
                                         src={current_page === total_page ? '/static/img/ic_arrow_right_gray.png' : '/static/img/ic_arrow_right_black.png'}
                                         onClick={() => {
                                             if (current_page !== total_page) {
                                                 this.setState({current_page: current_page + 1}, () => {
                                                     this.search(false).then(r => {})
                                                 })
                                             }
                                         }}
                                    />
                                </div>
                            }
                        </ListBg>
                    </Board>

                </WrapContent>
            </VotingBody>
            <WriteOwn>
                <img src="/static/img/ic_edit.png"
                     onClick={() => {
                       if (needProfile) {
                        props.history.push('/addinfo')
                       } else {
                        props.history.push('/editarticle/0')
                       }
                     }}/>
            </WriteOwn>
        </>
    }
}

