import styled from 'styled-components';

export const Input = styled.input`
    padding: 16px 14px;
    font-size: 16px;
    background: #fff;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 12px;
    opacity: 1;
    outline: none;
    border: 0;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
        color: rgb(214,214,214);
    }

    &.no-shadow {
        box-shadow: none;
        border: 1px solid #D1D1DB;
    }
`;

export const BoxTitle = styled.h3`
    font-size: 16px;
    color: black;
    margin: 0;
`;

export const Button = styled.button`
    background-color: #8BC7FF;
    box-shadow: 0px 5px 10px #00000029;
    color: white;
    border-radius: 24px;
    opacity: 1;
    box-sizing: border-box;
    border: none;
    padding: 15px 0;
    font-weight: bold;
    font-size: 16px;
    display: block;
    width: 100%;
    &.green {
        background-color: #00B5C3;
    }
    &.white {
        background-color: white;
        color: #666A80;
    }
    &.inline {
        display: inline-block;
    }
    &.small {
        width: initial;
        padding: 5px 16px;
        font-size: 11px;
    }
`;

export const DashboardBox = styled.div`
    background-color: #ffffffcc;
    box-shadow: 0px 8px 15px #455B6326;
    border-radius: 12px;
    .error {
        color: #D0274E;
        font-size: 13px;
        margin-bottom: 10px;
        margin-top: 10px;
        &:before {
            display: inline-block;
            content: '✕';
            border: 1px solid #D0274E;
            border-radius: 8px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            margin-right: 8px;
            font-size: 12px;
        }
    }
`;


export const Dollars = styled.span`
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    &:before {
        content: '$';
        font-size: 16px;
        position: relative;
        top: -6px;
    }
`;

export const DollarsSmall = styled.span`
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    &:before {
        content: '$';
        font-size: 16px;
        position: relative;
        top: -1px;
    }
`;


export const DollarsDorothy = styled.span`
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    &:before {
        content: '';
        font-size: 16px;
        position: relative;
        top: -6px;
    }
`;

