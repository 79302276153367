import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API from "./utils/api";
import {Button, Input} from "./styled-components";
import {LoginWrap} from "./Intro";
import {Popup} from "./components/Popup";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const Title = styled.h4`
    margin: 21px;
    color: black;
`;

const NextButton = styled(Button)`
    border-radius: 0;
    &:disabled {
        background-color: gray;
    }
`;

//function UserGuide(props) {
export default class SetCard extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        card_num: '',
    }

    async componentDidMount() {
        document.getElementById('app_page_name').value = "set_card";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {

        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Add credit card
            </GuideTitle>
            <Title>
                Card Number
            </Title>
            <Input type="text" placeholder={'Input card number'} value={this.state.id} style={{borderRadius: 0, paddingLeft: 20, paddingRight: 20}}
                   onChange={(e) => this.setState({card_num: e.currentTarget.value})}/>
            <NextButton style={{
                position: 'fixed',
                zIndex: '1000',
                bottom: '0'
            }}  onClick={async () => {
                if (this.state.card_num === '') {
                    alert("Please enter your card number.")
                    return
                }

                // MoonPay 연동
                const result = await API.procPayment(this.state.card_num);
                if (result.status === 'success') {
                    this.setState({popup_show_success: true})
                } else {
                    this.setState({popup_show_fail: true})
                }

            }}>{'Payment'}</NextButton>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

