import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router} from 'react-router-dom';
import {Route} from 'react-router';
import Intro from './Intro';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboad';
import MyCode from './MyCode';
import Charge from './Charge';
import Withdraw from './Withdraw';
import Dorothy from './Dorothy';
import Support from './Support';
import UserGuide from './UserGuide';
import Policy from './Policy';
import Settings from './Settings';
import Profile from './Profile';
import Contact from './Contact';
import Language from './Language';
import Notification from './Notification';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ChangeTxPassword from './ChangeTxPassword';
import FindPassword from './FindPassword';
import ChangeEth from './ChangeEth';
import Congrats from './Congrats';
// import './i18n';
import SetCard from "./SetCard";
import Payment from "./Payment";
import ChargeBtcPurcharse from "./ChargeBtcPurcharse";
import ChargeCardAddress from "./ChargeCardAddress";
import ChargeAddPayment from "./ChargeAddPayment";
import ChargeCardDetail from "./ChargeCardDetail";
import ChargeMakePayment from "./ChargeMakePayment";
import ChargePurcharseComp from "./ChargePurcharseComp";
import ChargeWalletAddress from "./ChargeWalletAddress";
import PushServiceSetting from "./PushServiceSetting";
import Help from "./Help";
import Voting from "./Voting";
import EditArticle from "./EditArticle";
import Article from "./Article";
import MenteeList from "./MenteeList";
import AddInfo from "./AddInfo";
import Withdrawal from "./Withdrawal";

async function main() {
    ReactDOM.render(
        <Router>
            <Route path="/" exact component={Intro}/>
            <Route path="/signin" exact component={SignIn}/>
            <Route path="/signup" exact component={SignUp}/>
            <Route path="/find-pw" exact component={FindPassword}/>
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/my-code" exact component={MyCode}/>
            <Route path="/charge" exact component={Charge}/>
            <Route path="/charge/setcard" exact component={SetCard}/>
            <Route path="/charge/payment" exact component={Payment}/>
            <Route path="/charge/chargebtcpurcharse" exact component={ChargeBtcPurcharse}/>
            <Route path="/charge/chargewalletaddress" exact component={ChargeWalletAddress}/>
            <Route path="/charge/chargeaddpayment" exact component={ChargeAddPayment}/>
            <Route path="/charge/chargecardaddress" exact component={ChargeCardAddress}/>
            <Route path="/charge/chargecarddetail" exact component={ChargeCardDetail}/>
            <Route path="/charge/chargemakepayment" exact component={ChargeMakePayment}/>
            <Route path="/charge/chargepurcharsecomp" exact component={ChargePurcharseComp}/>
            <Route path="/withdraw" exact component={Withdraw}/>
            <Route path="/dorothy" exact component={Dorothy}/>
            <Route path="/support" exact component={Support}/>
            <Route path="/user-guide" exact component={UserGuide}/>
            <Route path="/policy/:type/:login" exact component={Policy}/>
            <Route path="/mentee_list/:brunch" exact component={MenteeList}/>
            <Route path="/settings" exact component={Settings}/>
            <Route path="/settings/profile" exact component={Profile}/>
            <Route path="/settings/help" exact component={Help}/>
            <Route path="/settings/notification" exact component={Notification}/>
            <Route path="/settings/contact" exact component={Contact}/>
            <Route path="/settings/language" exact component={Language}/>
            <Route path="/settings/change-email" exact component={ChangeEmail}/>
            <Route path="/settings/change-password" exact component={ChangePassword}/>
            <Route path="/settings/change-tx-password" exact component={ChangeTxPassword}/>
            <Route path="/settings/change-eth" exact component={ChangeEth}/>
            <Route path="/donate/congrats" exact component={Congrats}/>
            <Route path="/pushsetting" exact component={PushServiceSetting}/>
            <Route path="/voting" exact component={Voting}/>
            <Route path="/article/:id/:my_contents" exact component={Article}/>
            <Route path="/editarticle/:id/" exact component={EditArticle}/>
            <Route path="/addinfo" exact component={AddInfo}/>
            <Route path="/withdrawal" exact component={Withdrawal}/>
        </Router>,

        document.getElementById('root')
    );
}

main();
