import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import API, {API_PATH} from "./utils/api";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const GuideDiv = styled.div`
    border: 1px solid rgb(209, 209, 219);
    font-size: 14px;
    padding: 16px;
    margin: 21px;
    word-break: break-all;
    white-space: break-spaces;
    font-family: 'Noto Sans' sans-serif;
    color: #666A80;
`;

const TermImage = styled.img`
    margin: 16px;
    width: calc(100% - 32px);
    height: auto;
`;

export default class UserGuide extends React.Component {

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        content: '',
        file: '',
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "user_guide";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const result = await API.getTermData(3);
        if (result.status === 'success') {
            document.getElementById('div_user_guide').innerHTML = result.content;
            this.setState({
                content: result.content,
                file: result.attach
            });
        }
    }

    render() {

        const props = this.props;
        const {lang_data, file} = this.state;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                lang_data={lang_data}/>
            <GuideTitle>User Guide</GuideTitle>
            {
                file !== '' && <TermImage className={'term_file'} src={API_PATH + "/" + file}/>
            }
            <GuideDiv id={"div_user_guide"}/>
        </>
    }
}

