import React from 'react';
import styled from 'styled-components';
import { Button } from '../styled-components';

export const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#E26FA4, #F8CB9B);
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
      display: block;
      margin-bottom: 24px;
  }
  h3 {
      font-size: 36px;
      color: white;
  }
  p {
      color: white;
      font-size: 16px;
      margin-bottom: 24px;
  }
  input {
      width: 300px;
      height: 32px;
      font-size: 24px;
      letter-spacing: 24px;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none;
      margin-bottom: 32px;;
  }
  
  input::placeholder {
      font-size: 14px;
      letter-spacing: 0px;
  }

  .green {
      width: 300px;
  }

  .title-bar {
    height: 62px;
    margin-bottom: 40px;
    .back:after {
        content: "<";
        position: absolute;
        top: 0;
        left: 16px;
        font-size: 36px;
        line-height: 62px;
        padding: 0 8px;
        color: white;
        cursor: pointer;
    }
  }
`;

function TxPassword({ onBackClick, onSubmit }) {
    const [passwd, setPasswd] = React.useState('');
    var isSubmit = false;
    return (
        <Wrap>
            <div className="title-bar">
                <div className="back" id={'btn_dlg_close'} onClick={() => onBackClick()} />
            </div>
            <img src="/static/img/password.svg" width={60} />
            <h3>PASSWORD</h3>
            <p>Please Enter Your Transaction Password</p>
            <input
                type="password"
                placeholder={"Transaction password 6 digits num"}
                value={passwd}
                onChange={(e) => setPasswd(e.currentTarget.value)}
            />
            <Button className="green" onClick={() => {
                if (!isSubmit) {
                    isSubmit = true;
                    onSubmit(passwd)
                }
            }
            }>OK</Button>
        </Wrap>
    );
}

export default TxPassword;
