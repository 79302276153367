import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import {Popup} from "./components/Popup";

const GuideTitle = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
`;

const AvailableBalanceWrapper = styled.div`
    
    line-height: 22px;
    height: 22px;
    margin-bottom : 5px;
    & > span {
        display: inline-block;
        vertical-align: middle;
    }
    .btc {
        float: right;
        font-weight: bold;
    }
`;

const SummaryWrapper = styled.div`
    border: 2px solid #D1D1DB;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    .title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 12px;
        color: #666A80;
    }
`;

//Purcharse 3단계화면
export default class ChargeAddPayment extends React.Component {

    state = {
        popup_show_success: false,
        popup_show_fail: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        card_num: '',
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge_add_payment";
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {

        const props = this.props;

        return <>
            <NavigationBar
                onBackClick={() => props.history.goBack()}
                title={this.state.lang_data.charge_label}
                lang_data={this.state.lang_data}
            />
            <GuideTitle>
                Add Payment Method
            </GuideTitle>
            <SummaryWrapper style={{marginLeft: 21, marginRight:21, borderRadius:10}} onClick={
                () => {
                    window.location.href = ('/#/Charge/ChargeCardAddress');
                }
            }>
                <AvailableBalanceWrapper style={{height: 40}}>
                    <span style={{fontSize: 12}}>Credit/Debit Card <br/>Add Visa or Mastercard</span>
                    <span className="btc" style={{fontSize: 12, fontWeight: "normal", height: 40}}> &gt </span>
                </AvailableBalanceWrapper>
            </SummaryWrapper>

            <Popup
                enabled={this.state.popup_show_success}
                isSuccess={true}
                title={'Payment Result'}
                description={'Payment was successful.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_success: false,
                    });
                    this.props.history.goBack();
                }}
            />

            <Popup
                enabled={this.state.popup_show_fail}
                isSuccess={false}
                title={'Payment Result'}
                description={'Payment has failed.'}
                buttonTitle={this.state.lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        popup_show_fail: false,
                    });
                    window.location.reload();
                }}
            />
        </>
    }
}

