import React from "react";
import styled from "styled-components";

const BackgroundShadow = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;

  .layer-pop {
    width: 300px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    border-radius: 12px;
    .caution {
      background: white;
      padding: 40px 22px;
      border-radius: 12px;

      .comment {
        font-size: 16px;
        color: #666a80;
        line-height: 1.5;
        padding: 1px 0px;
        margin: 0;
        white-space: pre-line;
      }
    }

    .buttons {
      margin: 0 auto 20px auto;
      background-color: deepskyblue;
      color: white;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      line-height: 52px;
      letter-spacing: 0.5px;
      display: flex;
      width: 110px;
      justify-content: center;
      align-items: center;
    }

      .input {
          width: 100px;
          height: 32px;
          font-size: 16px;
          letter-spacing: 16px;
          background: transparent;
          border: 1px solid white;
          outline: none;
          margin-bottom: 32px;;
      }
  }
`;

export function DirectSupportPopup({
                   description,
                   enabled,
                   buttonTitle = 'OK',
                   buttonTitle2 = 'No',
                   onButtonClick,
               }) {
    if (!enabled) {
        return null;
    }

    return (
        <BackgroundShadow>
            <div className="layer-pop">
                <div className="caution">
                    <p className="comment">
                        {description}
                    </p>
                </div>
                <div style={{display: "flex"}}>
                    <div className={'buttons'}
                         onClick={() => onButtonClick(true)}
                         style={{marginRight: 5}}>
                        {buttonTitle}
                    </div>
                    <div className={'buttons'}
                         id={'btn_dlg_close'}
                         onClick={() => onButtonClick(false)}
                         style={{marginLeft: 5}}>
                        {buttonTitle2}
                    </div>
                </div>
            </div>
        </BackgroundShadow>
    );
}