import React from 'react';
import styled from 'styled-components';
import Tab from './components/Tab';
import NavigationBar from './components/NavigationBar';
import History from './History';
import {Button, Dollars, DollarsDorothy} from './styled-components';
import TxPassword from './components/TxPassword';
import API from './utils/api';
import {Popup} from './components/Popup';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const TotalTitle = styled.h3`
    font-size: 12px;
    color: #666A80;
    margin-bottom: 8px;
`;

const BTC = styled.span`
    color: #9C9FB1;
    font-size: 24px;
    display: inline-block;
    margin-left: 12px;

    &:after {
        content: 'BTC';
        margin-left: 8px;
        font-size: 18px;
    }
`;

const NextLevelWrapper = styled.div`
    border: 1px solid #D1D1DB;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 24px;
    .title {
        font-size: 14px;
        color: #EA589E;
        font-weight: normal;
        margin: 0;
        margin-bottom: 8px;
    }

    .description {
        font-size: 12px;
        color: #666A80;
    }
`;

const NextLevelPrice = styled.div`
    color: #00B5C3;
    border-bottom: 1px solid #D1D1DB;
    padding-bottom: 12px;
    margin-bottom: 12px;
    ${BTC} {
        color: #00B5C3;
        font-size: 20px;
        &:after {
            font-size: 16px;
        }
    }
`;

class Support extends React.Component {
    state = {
        tabValue: 'support',
        supports: [],
        showDonatePopup: false,
        showDontationFailPopup: false,
        showDontationSuccessPopup: false,
        showDontationPwdFailPopup: false,
        showBtcFailPopup: false,
        showTxPassword: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me,
        supported_btc: 0,
        supported_btc_doller: 0,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "support";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const supportBtc = await API.getSupportBtc();
        if (supportBtc.status === 'success') {
            this.setState({supported_btc: supportBtc.total_btc, supported_btc_doller: supportBtc.total_btc_dollar});
        }

        const supports = await API.getSupport();
        if (supports.status === 'success')
            this.setState({
                supports: supports.data.map(e => ({
                    ...e,
                    type: 'support',
                }))
            });
    }

    async componentWillUnmount() {
        const me = await API.me();
        if (me.status === 'success') {
            GlobalState.me = me.user;
        }
    }

    renderSupportTab() {
        const user = this.state.me;
        const {supports, lang_data, showDonatePopup} = this.state;
        // const totalSupport = supports ? supports.reduce((a, b) => b.amount + a, 0) : 0;
        // const totalSupportBtc = supports ? supports.reduce((a, b) => b.amountBtc + a, 0) : 0;
        const totalSupport = this.state.supported_btc_doller;
        const totalSupportBtc = this.state.supported_btc;
        const nextLevel = user.level < 99 ? user.level + 1 : 99;
        let nextSupport = 0;
        if (user.level === 0) {
            nextSupport = 70;
        } else if (user.level < 99) {
            nextSupport = 25;
        } else {
            nextSupport = 0;
        }

        // nextSupport *= (1.1);

        return <div style={{ margin: 21 }}>
            <Popup
                enabled={showDonatePopup}
                isSuccess={true}
                title={lang_data.donate_label}
                description={lang_data.you_want_continue_donate_label}
                subButtonTitle={lang_data.no_label}
                onSubButtonClick={() => this.setState({ showDonatePopup: false })}
                onButtonClick={() => {
                    this.setState({ showDonatePopup: false, showTxPassword: true })
                }}/>
            <TotalTitle>{lang_data.total_support_amount_label}</TotalTitle>
            <div style={{ marginBottom: 32 }}>
                <DollarsDorothy>{(totalSupportBtc).toFixed(6)}BTC</DollarsDorothy>
                <Dollars style={{marginLeft: 10, fontWeight:'normal', color: '#9C9FB1', fontSize: 20}}>{totalSupport.toFixed(2)}</Dollars>
            </div>
            <NextLevelWrapper>
                <h5 className="title">{lang_data.next_level_label} <strong>G{nextLevel}</strong></h5>
                <NextLevelPrice>
                    <Dollars>{nextSupport.toFixed(1)}</Dollars>
                    <BTC>{parseFloat((nextSupport / user.btcPrice).toPrecision(3))}</BTC>
                </NextLevelPrice>
                <div className="description">
                    {lang_data.after_receive_supports_level_label}{nextLevel} {lang_data.after_receive_supports_level_label2}
                </div>
            </NextLevelWrapper>
            <Button
                className="green"
                onClick={() => {
                    if (user.btcPrice == 20000) {
                        this.setState({showBtcFailPopup: true})
                        return;
                    }
                    if (user.btcPrice < nextSupport) {
                        this.setState({showDontationFailPopup: true})
                    } else {
                        this.setState({showDonatePopup: true})
                    }
                }}>{lang_data.support_label}</Button>
            <div style={{ marginTop: 24, fontSize: 14 }}>
                <img src="/static/img/info.png" width="20" style={{ verticalAlign: 'middle', marginRight: 12 }} />
                {lang_data.support_fee_charged_label}
            </div>
        </div>
    }

    render() {
        const {lang_data, showTxPassword, showDontationSuccessPopup, showDontationPwdFailPopup, tabValue, showDontationFailPopup, supports, showBtcFailPopup} = this.state;
        if (showTxPassword) {
            return <TxPassword
                onBackClick={() => this.setState({ showTxPassword: false })}
                onSubmit={async (passwd) => {
                    const supportResponse = await API.support(passwd);
                    const newState = { showTxPassword: false };
                    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>> " + supportResponse.status);
                    if (supportResponse.status === 'success') {
                        newState.showDontationSuccessPopup = true;
                    } else if (supportResponse.status === 'pwd fail') {
                        newState.showDontationPwdFailPopup = true;
                    } else {
                        newState.showDontationFailPopup = true;
                    }
                    this.setState(newState);
                }}/>
        }

        return <>
            <Popup
                enabled={showDontationSuccessPopup}
                isSuccess={true}
                title={lang_data.welcome_title}
                description={lang_data.donation_successful_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={async () => {
                    this.setState({
                        showDontationSuccessPopup: false,
                    });
                    const me = await API.me();
                    if (me.status === 'success') {
                        this.setState({me: me.user});
                        GlobalState.me = me.user;
                    }
                    this.props.history.push('/dashboard');
                }}/>
            <Popup
                enabled={showDontationFailPopup}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.check_current_balance_label}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({
                        showDontationFailPopup: false,
                    });
                }}
            />
            <Popup
                enabled={showBtcFailPopup}
                isSuccess={false}
                title={lang_data.noti_label}
                description={lang_data.noti_bitcoin_network_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={async () => {
                    this.setState({
                        showBtcFailPopup: false,
                    });
                    const me = await API.me();
                    if (me.status === 'success') {
                        this.setState({me: me.user});
                        GlobalState.me = me.user;
                    }
                }}
            />
            <Popup
                enabled={showDontationPwdFailPopup}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.check_current_password}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({
                        showDontationPwdFailPopup: false,
                    });
                }}/>
            <NavigationBar
                onBackClick={() => {
                    this.props.history.goBack();
                }}
                title={lang_data.support_label}
                lang_data={lang_data}/>
            <Tab
                selected={tabValue}
                tabs={[
                    {text: lang_data.support_label, value: 'support'},
                    {text: lang_data.support_history_label, value: 'history'},
                ]}
                onTabClick={(value) => this.setState({tabValue: value})}/>
            {tabValue === 'support' ? this.renderSupportTab() :
                <History history={supports} unit={'$'} type={'support'}/>}
        </>
    }
}

export default Support;
