import React from 'react';
import API from './utils/api';
import styled from 'styled-components';
import {Button, Input} from './styled-components';
import {Popup} from "./components/Popup";
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const Header = styled.div`
  position: relative;
  background-color: #E26EA3;
  background-position: bottom;
  background-size: cover;
  width: 100vw;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: white;
  font-weight: bold;

  .back {
    position: absolute;
    top: 0;
    left: 16px;
    font-size: 36px;
    line-height: 62px;
    padding: 0 8px;
    color: white;
    cursor: pointer;
  }
`;

const Wrap = styled.div`
  padding: 21px;

  ${Input} {
    margin-bottom: 24px;
    box-shadow: none;
    border: 1px solid #D1D1DB;
  }

  .error {
    color: #D0274E;
    font-size: 14px;
    margin-bottom: 24px;

    &:before {
      display: inline-block;
      content: '✕';
      border: 1px solid #D0274E;
      border-radius: 8px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      margin-right: 8px;
      font-size: 12px;
    }
  }
`;

const Span = styled.div`
  position: relative;
  background-image: url('/static/img/signup-wave.svg');
  background-position: bottom;
  background-size: cover;
  width: 100vw;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: white;
  font-weight: bold;
`;

const Confirm = styled.button`
  background: #00b5c3;
  border-radius: 0px 12px 12px 0px;
  opacity: 1;
  right: 0;
  border: none;
  position: absolute;
  height: 52px;
  color: #fff;
  width: 32%;
  margin-top: 20px;
  margin-right: 21px;
  font-size: 15px;
  font-weight: 600;

  &:disabled {
    background-color: gray;
  }
`;

const ContinueButton = styled(Button)`
  border-radius: 0;

  &:disabled {
    background-color: gray;
  }
`;

const Checkbox = styled.div`
  width: 100%;
  height: 20px;
  background: white;
  margin-bottom: 14px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
`;

const Radiobox = styled.div`
  height: 20px;
  background: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .radiobox {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
`;

const TermsOfUse = styled.div`
  font-size: 12px;
  margin-bottom: 12px;
  padding-top: 5px;
  display: -webkit-box !important;
  line-height: 20px;
  cursor: pointer;
  .term_link {
    color: red;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    text-decoration: underline;
  }

  .term_link_read {
    color: black !important;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    text-decoration: underline;
  }

  .checkbox {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    margin-top: 3px;
  }
`;

class SignUp extends React.Component {

    isContinued() {
        const {userId, passwd, confirmPasswd, email, txPassword, txConfirmPassword, userIdError, passwordError, confirm_userId, confirm_code, privacy_policy, terms_of_use, no_mentor_id, reference, referenceError} = this.state;

        return userId !== '' && passwd !== '' && confirmPasswd === passwd && email !== '' && txPassword !== '' && txPassword === txConfirmPassword &&
            !userIdError && !passwordError && confirm_userId && confirm_code && //privacy_policy && terms_of_use &&
            (no_mentor_id || (!no_mentor_id && reference !== '' && !referenceError));
    }

    state = {
        userId: '',
        passwd: '',
        confirmPasswd: '',
        email: '',
        code: '',
        txPassword: '',
        txConfirmPassword: '',
        reference: '',
        userIdError: false,
        passwordError: false,
        emailError: false,
        referenceError: false,
        no_mentor_id: false,
        show_confirm_userId: false,
        confirm_userId_value: '',
        confirm_userId: false,
        show_send_email: false,
        show_confirm_code: false,
        confirm_code: false,
        verify_code: '',
        popup_show: false,
        terms_of_use: false,
        terms_of_use_read: false,
        privacy_policy: false,
        privacy_policy_read: false,
        support_type: 0,
        api_loading: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "signup";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        this.setState(GlobalState.sign);
    }

    goPolicy = (type) => {
        let {terms_of_use_read, privacy_policy_read} = this.state;
        if (type === 0) {
            terms_of_use_read = true
        } else {
            privacy_policy_read = true;
        }
        const {userId, passwd, confirmPasswd, email, txPassword, txConfirmPassword, userIdError, passwordError, confirm_userId, confirm_code, privacy_policy, terms_of_use, no_mentor_id, reference, referenceError, emailError, show_confirm_userId, confirm_userId_value, show_send_email, verify_code, show_confirm_code, code, support_type} = this.state;
        GlobalState.sign = {
            userId: userId,
            passwd: passwd,
            confirmPasswd: confirmPasswd,
            email: email,
            code: code,
            txPassword: txPassword,
            txConfirmPassword: txConfirmPassword,
            reference: reference,
            userIdError: userIdError,
            passwordError: passwordError,
            emailError: emailError,
            referenceError: referenceError,
            no_mentor_id: no_mentor_id,
            show_confirm_userId: show_confirm_userId,
            confirm_userId_value: confirm_userId_value,
            confirm_userId: confirm_userId,
            show_send_email: show_send_email,
            show_confirm_code: show_confirm_code,
            confirm_code: confirm_code,
            verify_code: verify_code,
            terms_of_use_read: terms_of_use_read,
            terms_of_use: terms_of_use,
            privacy_policy_read: privacy_policy_read,
            privacy_policy: privacy_policy,
            support_type: support_type
        };
        this.props.history.push('/policy/' + type + '/0')
    };

    goBack = () => {
        GlobalState.sign = null;
        this.props.history.goBack()
    };

    render() {
        const props = this.props;
        const {popup_show, lang_data, userId, confirm_userId_value, show_confirm_userId, userIdError, passwd, passwordError, reference, referenceError, txPassword, txConfirmPassword, terms_of_use,
            confirmPasswd, email, show_send_email, emailError, code, no_mentor_id, show_confirm_code, verify_code, terms_of_use_read, privacy_policy, privacy_policy_read, support_type} = this.state;
        return <>
            <Popup
                enabled={popup_show}
                isSuccess={true}
                title={lang_data.welcome_title}
                description={lang_data.welcome_des_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({popup_show: false});
                    GlobalState.sign = null;
                    props.history.push('/signin');
                }}/>
            <Header style={{
                top: '0',
                left: '0',
                position: 'fixed',
                zIndex: '1000',
            }}>
                <div className="back" onClick={() => this.goBack()}>
                    <img src="/static/img/arr_back.png" width="26"/>
                </div>
            </Header>
            <Wrap style={{
                paddingTop: 62,
                paddingBottom: 48,
            }}>
                <Span style={{
                    marginLeft: -21,
                }}><span style={{marginBottom: 60}}>{lang_data.hello_label}</span>
                </Span>
                <Input
                    type="text"
                    placeholder={lang_data.id_hint}
                    value={userId}
                    required
                    onChange={(e) => {

                        this.setState({userId: e.currentTarget.value.trim()}); // 아이디 앞뒤 공백 제거
                        if (e.currentTarget.value !== confirm_userId_value && e.currentTarget.value.length >= 5)
                            this.setState({show_confirm_userId: true});
                        else {
                            this.setState({show_confirm_userId: false});
                        }
                    }}
                    // onBlur={async () => {
                    //     const exists = await API.isUserExists(userId);
                    //
                    // }}
                    style={{
                        marginTop: 20,
                        paddingRight: 90
                    }}/>
                <Confirm
                    style={{display: show_confirm_userId ? 'initial' : 'none'}}
                    disabled={userId.length === 0}
                    onClick={async () => {
                        const exists = await API.isUserExists(userId);
                        if (exists) {
                            this.setState({userIdError: true});
                            this.setState({show_confirm_userId: true});
                            this.setState({confirm_userId: false});
                        } else {
                            this.setState({userIdError: false});
                            this.setState({confirm_userId: true});
                            this.setState({confirm_userId_value: userId});
                            this.setState({show_confirm_userId: false});
                            alert('ID available.');
                        }
                    }}>{lang_data.confirm_label}
                </Confirm>
                <div className="error" style={{display: userIdError ? 'block' : 'none'}}>{lang_data.id_already_use_label}</div>
                <Input
                    type="password"
                    placeholder={lang_data.password_hint}
                    value={passwd}
                    required
                    onChange={(e) => this.setState({passwd: e.currentTarget.value})}
                    onBlur={() => {
                        const regexp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
                        if (regexp.test(passwd)) {
                            this.setState({passwordError: false});
                        } else {
                            this.setState({passwordError: true});
                        }
                    }}/>
                <div className="error" style={{display: passwordError ? 'block' : 'none'}}>
                    The password must be a combination of 8 or more digits and 15 or less&lt;English + Special Character + Number&gt;.
                </div>
                <Input
                    type="password"
                    placeholder={lang_data.confirm_password_hint}
                    value={confirmPasswd}
                    required
                    onChange={(e) => this.setState({confirmPasswd: e.currentTarget.value})}
                    onBlur={() => {
                        if (passwd === confirmPasswd) {
                            this.setState({passwordError: false});
                        } else {
                            this.setState({passwordError: true});
                        }
                    }}/>
                <Input
                    type="email"
                    placeholder={lang_data.email_hint}
                    value={email}
                    required
                    onChange={(e) => {
                        this.setState({email: e.currentTarget.value});
                        if (e.currentTarget.value !== '') {
                            this.setState({show_send_email: true});
                        } else {
                            this.setState({show_send_email: false});
                        }
                    }}/>
                <Confirm
                    style={{marginTop: 0, display: show_send_email ? 'initial' : 'none'}}
                    disabled={email.length === 0}
                    onClick={async () => {
                        const exists = await API.isExistsEmail(email);
                        if (!exists) {
                            this.setState({emailError: false});
                            const resp = await API.getVerifyCode(email);
                            if (resp.status === 'success') {
                                this.setState({show_confirm_code: true, verify_code: resp.code});
                                alert("Code has been sent.");
                            } else {
                                this.setState({show_confirm_code: false, verify_code: ''});
                                alert("Code sent has been failed.");
                            }
                        } else {
                            this.setState({emailError: true});
                        }
                    }}
                >{lang_data.send_label}
                </Confirm>
                <div className="error" style={{display: emailError ? 'block' : 'none'}}>It already used email.</div>
                <Input
                    type="text"
                    placeholder={lang_data.code_hint}
                    value={code}
                    required
                    onChange={(e) => {
                        this.setState({code: e.currentTarget.value});
                        if (e.currentTarget.value === '1010') {
                            this.setState({reference: 'everythanks'})
                        } else {
                            this.setState({reference: reference})
                        }
                    }}/>
                <Confirm
                    style={{marginTop: 0, display: show_confirm_code ? 'initial' : 'none'}}
                    disabled={verify_code.length === 0}
                    onClick={async () => {
                        // const resp = await API.checkCode(email, code);
                        if (code === verify_code || code === '1010') {
                            this.setState({confirm_code: true, show_confirm_code: false});
                            alert('Your email has been verified.');
                        } else {
                            this.setState({confirm_code: false, show_confirm_code: true});
                            alert('Please enter the correct code.');
                        }
                    }}
                >{lang_data.confirm_label}
                </Confirm>
                <Input
                    type="string"
                    placeholder={lang_data.referral_hint}
                    value={reference}
                    readOnly={no_mentor_id ? 'readonly' : ''}
                    onChange={(e) => this.setState({reference: e.currentTarget.value})}
                    style={{marginBottom: 10}}
                    onBlur={async (e) => {
                        console.log("onBlue ::: no_mentor_id = " + e.currentTarget.value);
                        const exists = await API.isUserExists(reference);
                        console.log("onBlue2 ::: no_mentor_id = " + reference);
                        console.log("onBlue3 ::: reference = " + this.state.reference);
                        if (exists) {
                            this.setState({referenceError: false});
                        } else {
                            if (this.state.reference != '')
                                this.setState({referenceError: !no_mentor_id});
                        }
                    }}/>
                <div className="error" style={{display: referenceError ? 'block' : 'none'}}>{lang_data.referral_error_label}</div>
                <Checkbox id='chk_is_no_mentor' onClick={() => {
                    console.log("CheckBox ::: no_mentor_id = " + no_mentor_id);
                    this.setState({no_mentor_id: !no_mentor_id, referenceError: false})
                    this.setState({reference: ''})
                    console.log("CheckBox2 ::: no_mentor_id = " + reference);
                }}>
                    <div className={'checkbox'}
                         style={{backgroundImage: no_mentor_id ? 'url(/static/img/ic_check_on.png)' : 'url(/static/img/ic_check_off.png)'}}/>
                    {lang_data.no_mentor_id}
                </Checkbox>
                <TermsOfUse>{lang_data.support_selection}</TermsOfUse>
                <div style={{display: 'flex', marginBottom: 5}}>
                    <Radiobox onClick={() => {
                        this.setState({support_type: 0})
                    }}><div className={'radiobox'} style={{backgroundImage: support_type === 0 ? 'url(/static/img/ic_radio_on.png)' : 'url(/static/img/ic_radio_off.png)'}}/>
                    {lang_data.support}
                    </Radiobox>
                    <Radiobox style={{marginLeft: 10}} onClick={() => {
                        this.setState({support_type: 1})
                    }}><div className={'radiobox'} style={{backgroundImage: support_type === 1 ? 'url(/static/img/ic_radio_on.png)' : 'url(/static/img/ic_radio_off.png)'}}/>
                    {lang_data.donation}
                    </Radiobox>
                </div>
                <TermsOfUse style={{whiteSpace: 'pre-line'}}>{lang_data.support_selection_desc}</TermsOfUse>
                <Input
                    type="password"
                    placeholder={lang_data.transaction_hint}
                    value={txPassword}
                    required
                    onChange={(e) => {
                        let regexp = /^[0-9]*$/;
                        let value = e.currentTarget.value;
                        if (!regexp.test(value)) {
                            return
                        }
                        this.setState({txPassword: e.currentTarget.value})
                    }}/>
                <Input
                    type="password"
                    placeholder={lang_data.confirm_password_hint}
                    value={txConfirmPassword}
                    required
                    onChange={(e) => {
                        let regexp = /^[0-9]*$/;
                        let value = e.currentTarget.value;
                        if (!regexp.test(value)) {
                            return
                        }
                        this.setState({txConfirmPassword: e.currentTarget.value})
                    }}/>
                <TermsOfUse>
                    <div className={'checkbox'}
                         style={{
                             backgroundImage: 'url(/static/img/ic_check_on.png)',
                             display: (terms_of_use && privacy_policy) ? "" : "none"
                         }}/>
                    <span style={{paddingLeft: 10}}>{lang_data.i_agree_with_both_label}</span>
                    <span className={terms_of_use_read ? 'term_link_read' : 'term_link'}
                          onClick={() => {
                              this.goPolicy(0);
                          }}>{lang_data.term_of_use_label}
                        </span>{lang_data.and_label}
                    <span className={privacy_policy_read ? 'term_link_read' : 'term_link'}
                          onClick={() => {
                              this.goPolicy(1);
                          }}>{lang_data.privacy_policy_label}
                    </span>{lang_data.of_everythanks_app_label}
                </TermsOfUse>
            </Wrap>
            <ContinueButton style={{
                bottom: '0',
                left: '0',
                position: 'fixed',
                zIndex: '1000',
            }} disabled={!this.isContinued()} onClick={async () => {
                if (this.state.api_loading) {
                    return;
                }
                if (!terms_of_use_read || !privacy_policy_read) {
                    alert(lang_data.signup_check_policy_label);
                    return;
                }

                if (passwd !== confirmPasswd) {
                    alert("Confirm password is not match to your password");
                    return;
                }

                if (txPassword !== txConfirmPassword) {
                    alert("Confirm transaction password is not match to your transaction password");
                    return;
                }
                this.setState({api_loading:true})
                const result = await API.signup(userId, passwd, email, txPassword, reference, no_mentor_id, support_type);
                this.setState({api_loading:false})
                if (result.status === 'success') {
                    //alert('User account created!');
                    // props.history.push('/signin');
                    this.setState({popup_show: true});
                } else {
                    alert("failed to sign up");
                }
            }}>{lang_data.signup_label}</ContinueButton>
        </>;
    }
}

export default SignUp;
