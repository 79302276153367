import React from 'react';
import styled from 'styled-components';
import API from './utils/api';
import {getLangData, readLangData} from "./utils/LangState";
import NavigationBar from "./components/NavigationBar";

const BackgroundShadow = styled.div`
  display: flex;
  align-items: center;

  .content {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: calc(100% - 50px);
    background: white;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 24px;
  }

  .header {
    background: #e974a9;
    height: 54px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .odd_item {
    background: white;
    height: 54px;
    color: black;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .even_item {
    background: white;
    height: 54px;
    color: black;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
`;

export default class MenteeList extends React.Component {

    brunch = parseInt(this.props.match.params.brunch);

    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        menteeList: [],
        emptyList: false,
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "mentee_list";
        window.scrollTo(0,0);
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData(),
        });

        const branchData = await API.branchData(this.brunch);
        if (branchData.status === 'success') {
            this.setState({
                menteeList: this.setMenteeList(branchData.branchIds)
            }, () => {
                this.setState({emptyList: this.state.menteeList.length === 0})
            });
        }
    }

    setMenteeList = (list) => {
        let item = [];
        for (let i = 0; i < list.length; i++) {
            let tmp = {};
            tmp.id = list[i].userId;
            tmp.grade = list[i].level;
            item.push(tmp)
        }
        return item;
    };

    render() {
        const {lang_data, emptyList, menteeList} = this.state;
        return <>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                title={lang_data.dashboard_label}
                lang_data={lang_data}/>
            <BackgroundShadow>
                <div className="content">
                    {
                        !emptyList &&
                        <div style={{display: "flex", width: "100%"}}>
                            <div className="header" style={{width: "20%", borderTopLeftRadius: 24}}>No</div>
                            <div className="header" style={{width: "25%"}}>Grade</div>
                            <div className="header" style={{width: "55%", borderTopRightRadius: 24}}>ID</div>
                        </div>
                    }
                    <div style={{marginTop: 1}}>
                        {
                            menteeList.map((item, index) => {
                                return (
                                    index % 2 === 0 ?
                                        <div style={{display: "flex"}} key={index}>
                                            <div className="even_item" style={{width: "20%"}}>{index + 1}</div>
                                            <div className="even_item"
                                                 style={{width: "25%"}}>G{item.grade}</div>
                                            <div className="even_item"
                                                 style={{width: "55%"}}>{item.id}</div>
                                        </div> :
                                        <div style={{display: "flex"}} key={index}>
                                            <div className="odd_item" style={{width: "20%"}}>{index + 1}</div>
                                            <div className="odd_item"
                                                 style={{width: "25%"}}>G{item.grade}</div>
                                            <div className="odd_item"
                                                 style={{width: "55%"}}>{item.id}</div>
                                        </div>
                                )
                            })
                        }
                    </div>
                    {
                        emptyList &&
                        <div style={{textAlign: 'center', fontSize: 20, marginTop: 50, fontWeight: "bold"}}>
                            {lang_data.no_information}
                        </div>
                    }
                </div>
            </BackgroundShadow>
        </>
    }
}

