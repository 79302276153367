import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Button, Input} from './styled-components';
import {IntroBackground, LoginWrap, Title, Wrap} from './Intro';
import API from './utils/api';
import {getLangData, readLangData} from './utils/LangState';
import {Popup} from './components/Popup';
import GlobalState from "./mobx/GlobalState";
import Common from "./utils/Common";

const SignInTitle = styled(Title)`
  width: 142px;
  height: 125px;
  background-size: cover;
  background-position: center;
`;

const LoginWrapExtend = styled(LoginWrap)`
  button, input {
    width: 100%;
    max-width: 332px;
    margin-bottom: 24px;
  }
`;

const SignInTools = styled.div`
  width: 100%;
  padding: 0 21px;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  height: 20px;
  line-height: 20px;

  a {
    color: white;
  }
`;

export default class SignIn extends React.Component {

    state = {
        id: '',
        passwd: '',
        popup_show: false,
        error_msg: 'Your ID or PASSWORD is incorrect.',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        showNotiPopup: false
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "signin";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        GlobalState.sign = null
    }

    async componentWillUnmount() {
        document.getElementById('app_lang').value = this.state.language;
    }

    render() {
        const props = this.props;
        const {popup_show, id, passwd, error_msg, lang_data, showNotiPopup} = this.state;
        return <>
            <Popup
                enabled={popup_show}
                isSuccess={false}
                title={lang_data.login_failed}
                description={error_msg}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({popup_show: false});
                }}/>
            <Popup
                enabled={showNotiPopup}
                isSuccess={true}
                title={'공지'}
                description={'데이터베이스를 마이그레이션 하는 과정으로 점검을 진행 합니다. 예상 점검 시간은 utf+8 기준 18시 부터 23시 까지 예상됩니다.'}
                buttonTitle={lang_data.ok_label}
                onButtonClick={async () => {
                    this.setState({
                        showNotiPopup: false,
                    });
                }}/>
            <IntroBackground>
                <Popup/>
                <Wrap/>
                <LoginWrapExtend>
                    <SignInTitle/>
                    <div style={{
                        margin: '32px 0',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: 220
                    }}>
                        <span id={'sp_lang_kr'} onClick={() => {
                            readLangData('kr').then(r => {
                                // eslint-disable-next-line no-undef
                                if (isIOS()) {
                                    window.webkit.messageHandlers.bridge_set_language.postMessage({code:'kr'});
                                    // eslint-disable-next-line no-undef
                                } else if (isAndroid()) {
                                    window.androidBridge.bridge_set_language('kr');
                                }
                                this.setState({
                                    language: 'kr',
                                    lang_data: getLangData()
                                });
                            });
                        }}>{lang_data.kor_label}</span>
                        <span id={'sp_lang_en'} onClick={() => {
                            readLangData('en').then(r => {
                                // eslint-disable-next-line no-undef
                                if (isIOS()) {
                                    window.webkit.messageHandlers.bridge_set_language.postMessage({code:'en'});
                                    // eslint-disable-next-line no-undef
                                } else if (isAndroid()) {
                                    window.androidBridge.bridge_set_language('en');
                                }
                                this.setState({
                                    language: 'en',
                                    lang_data: getLangData()
                                });
                            });
                        }}>{lang_data.eng_label}</span>
                        <span id={'sp_lang_ch'} onClick={() => {
                            readLangData('zh-hk').then(r => {
                                // eslint-disable-next-line no-undef
                                if (isIOS()) {
                                    window.webkit.messageHandlers.bridge_set_language.postMessage({code:'zh-hk'});
                                    // eslint-disable-next-line no-undef
                                } else if (isAndroid()) {
                                    window.androidBridge.bridge_set_language('zh-hk');
                                }
                                this.setState({
                                    language: 'zh-hk',
                                    lang_data: getLangData()
                                }); 
                            });
                        }}>{lang_data.chn_label}</span>
                        <span id={'sp_lang_jp'} onClick={() => {
                            readLangData('jp').then(r => {
                                // eslint-disable-next-line no-undef
                                if (isIOS()) {
                                    window.webkit.messageHandlers.bridge_set_language.postMessage({code:'jp'});
                                    // eslint-disable-next-line no-undef
                                } else if (isAndroid()) {
                                    window.androidBridge.bridge_set_language('jp');
                                }
                                this.setState({
                                    language: 'jp',
                                    lang_data: getLangData()
                                });
                            });
                        }}>{lang_data.jpn_label}</span>
                    </div>
                    <Input type="text" placeholder={lang_data.id_hint} value={id}
                           onChange={(e) => this.setState({id: e.currentTarget.value})}/>
                    <Input type="password" placeholder={lang_data.password_hint}
                           value={passwd} onChange={(e) => this.setState({passwd: e.currentTarget.value})}/>
                    <Button onClick={async () => {
                        const result = await API.signin(id, passwd);
                        const this_id = id;
                        const this_passwd = passwd;
                        if (result.status === 'success') {
                            const activate_status = result.user.activate_status;
                            if (activate_status == 'Lock') {
                                this.setState({error_msg: 'The account has been locked.'});
                                this.setState({popup_show: true});
                                return;
                            } else if (activate_status == 'Permanent account') {
                                this.setState({error_msg: 'This account is a permanent account.'});
                                this.setState({popup_show: true});
                                return;
                            }

                            const result_ip = await API.getIp();
                            await API.loginHistory(result.user.id, result_ip.ip.replace('::ffff:', ''));
                            const this_isAutoLogin = result.user.isAutoLogin;
                            GlobalState.me = result.user;
                            /* 플랫트폼 체크 */
                            const platform = Common.checkMobile();
                            if (platform === "android") {
                                window.androidBridge.bridge_login_info(result.user.userId);
                                if (this_isAutoLogin) {
                                    window.androidBridge.bridge_save_login_info(result.user.userId, passwd, this_isAutoLogin);
                                } else {
                                    window.androidBridge.bridge_clear_login_info();
                                }
                            } else { //  if (platform === "ios")
                                window.webkit.messageHandlers.bridge_login_info.postMessage({userId: result.user.userId});
                                if (this_isAutoLogin) {
                                    window.webkit.messageHandlers.bridge_save_login_info.postMessage({id: result.user.userId, pwd: passwd, autoLogin: parseInt(this_isAutoLogin)});
                                } else {
                                    window.webkit.messageHandlers.bridge_clear_login_info.postMessage({});
                                }
                            }

                            localStorage.setItem('login', JSON.stringify({
                                this_id, this_passwd, this_isAutoLogin
                            }));
                            if (result.user.rankUpdate === 1) {
                                props.history.push('/donate/congrats');
                            } else {
                                props.history.push('/dashboard');
                            }
                        } else if (result.status === 'withdrawal') {
                            this.setState({error_msg: lang_data.str_withdraw_prev});
                            this.setState({popup_show: true});
                        }  else if (result.status === 'not find user') {
                            this.setState({error_msg: lang_data.str_no_find_account});
                            this.setState({popup_show: true});
                        } else {
                            this.setState({error_msg: lang_data.str_password_error});
                            this.setState({popup_show: true});
                        }
                    }}>{lang_data.signin_label}</Button>
                    <SignInTools>
                        <Link to="find-pw" style={{
                            float: 'left',
                            fontWeight: 'bold'
                        }}>{lang_data.find_password_label}</Link>
                        <Link to="signup" style={{
                            float: 'right',
                            fontWeight: 'bold'
                        }}>{lang_data.join_member_label}</Link>
                    </SignInTools>
                    <div style={{margin: '40px 0px 0px 0px', color: 'white', fontSize: 11}}>Copyright 2020
                        everythanks,inc. All Rights Reserved
                    </div>
                </LoginWrapExtend>
            </IntroBackground>
        </>;
    }

}
