import React from 'react';
import API from './utils/api';
import NavigationBar from './components/NavigationBar';
import {Button, Input} from './styled-components';
import styled from 'styled-components';
import TxPassword from "./components/TxPassword";
import {Popup} from "./components/Popup";
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";

const Title = styled.div`
    padding: 21px;
    background: #E7E7EF;
    color: #666A80;
    font-weight: bold;
    img {
        vertical-align: middle;
    }
`;

const Wrap = styled.div`
    margin: 24px;
    ${Input} {
        margin-bottom: 24px;
        box-shadow: none;
        border: 1px solid #D1D1DB;
    }
    .error {
        color: #D0274E;
        font-size: 14px;
        margin-bottom: 24px;
        &:before {
            display: inline-block;
            content: '✕';
            border: 1px solid #D0274E;
            border-radius: 8px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            margin-right: 8px;
            font-size: 12px;
        }
    }
`;

const ChangeButton = styled(Button)`
    &:disabled {
        background: #D1D1DB;
    }
`;

const ConfirmButton = styled(Button)`
    &:disabled {
        background: #D1D1DB;
    }
`;


class ChangeEmail extends React.PureComponent {
    state = {
        me: GlobalState.me,
        newEmail: '',
        verify_code: '',
        show_confirm_code: false,
        code: '',
        enable_change_btn: false,
        show_error_email: false,
        showTxPassword:false,
        showChangeFailPopup: false,
        showChangeSuccessPopup: false,
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "change_email";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {
        const {me, showTxPassword, newEmail, showChangeSuccessPopup, lang_data, showChangeFailPopup, show_confirm_code, code, verify_code, show_error_email, enable_change_btn} = this.state;
        if (showTxPassword) {
            return <TxPassword
                onBackClick={() => this.setState({ showTxPassword: false })}
                onSubmit={async (passwd, value) => {
                    const changeResponse = await API.changeEmail(newEmail);
                    const newState = { showTxPassword: false };
                    if (changeResponse) {
                        newState.showChangeSuccessPopup = true;
                        GlobalState.me.email = newEmail;
                    } else {
                        newState.showChangeFailPopup = true;
                    }
                    this.setState(newState);
                }}/>
        }

        return <div style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>
            <Popup
                enabled={showChangeSuccessPopup}
                isSuccess={true}
                title={lang_data.success_title}
                description={lang_data.donation_successful_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({
                        showChangeSuccessPopup: false,
                        me: GlobalState.me,
                        newEmail: '',
                        verify_code: '',
                        show_confirm_code: false,
                        code: '',
                        enable_change_btn: false,
                        show_error_email: false,
                        showTxPassword:false,
                        showChangeFailPopup: false,
                    });
                }}/>
            <Popup
                enabled={showChangeFailPopup}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.check_current_balance_label}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({
                        showChangeFailPopup: false,
                    });
                }}/>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                lang_data={lang_data}/>
            <Title><img src="/static/img/icon_email_dark.png" width="22"/> {lang_data.change_email_label}</Title>
            <Wrap>
                <Input type="email" value={me.email} readOnly/>
                <div style={{position: 'relative'}}>
                    <Input type="email" placeholder={lang_data.new_email_label} value={newEmail}
                           onChange={e => this.setState({newEmail: e.currentTarget.value})}
                           style={{paddingRight: 100}}/>
                    <ConfirmButton className="green" disabled={newEmail.length === 0} style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 24,
                        width: 90,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }} onClick={async () => {
                        const resp = await API.getVerifyCode(newEmail);
                        if (resp.status === 'success') {
                            this.setState({show_confirm_code: true});
                            this.setState({verify_code: resp.code});
                            alert("The authentication code is " + resp.code);
                        } else {
                            this.setState({show_confirm_code: false, verify_code: ''});
                        }
                    }}>{lang_data.send_label}</ConfirmButton>
                </div>
                <div style={{position: 'relative'}}>
                    <Input type="text" placeholder={lang_data.code_hint}
                           style={{paddingRight: 100}}
                           onChange={e => this.setState({code: e.currentTarget.value})}/>
                    <ConfirmButton className="green" disabled={!show_confirm_code} style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 24,
                        width: 90,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }} onClick={() => {
                        if (code === verify_code) {
                            this.setState({enable_change_btn: true});
                            this.setState({show_error_email: false});
                            alert('The email you entered has been verified.');
                        } else {
                            this.setState({enable_change_btn: false});
                            this.setState({show_error_email: true});
                        }
                    }}>{lang_data.confirm_label}</ConfirmButton>
                </div>

                <div className="error" style={{display: show_error_email ? 'block' : 'none'}}>
                    Your email is not valid.
                </div>

                <ChangeButton className="green" disabled={!enable_change_btn} onClick={async () => {
                    this.setState({showTxPassword: true});
                }}>{lang_data.change_label}</ChangeButton>
            </Wrap>
        </div>
    }
}

export default ChangeEmail;