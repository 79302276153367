import React from 'react';
import Tab from './components/Tab';
import NavigationBar from './components/NavigationBar';
import History from './History';
import {Button, DollarsDorothy, DollarsSmall} from './styled-components';
import API from './utils/api';
import {getLangData, readLangData} from "./utils/LangState";
import GlobalState from "./mobx/GlobalState";
import Common from "./utils/Common";

function copyText(text) {
    const tempElem = document.createElement('textarea');
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
}

class Charge extends React.PureComponent {
    state = {
        tabValue: 'charge',
        transactions: [],
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        me: GlobalState.me
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "charge";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});

        const transactions = await API.chartTransactions();
        if (transactions.status === 'success')
            this.setState({ transactions: transactions.data });

        const me = await API.me();
        if (me.status === 'success') {
            this.setState({me: me.user});
            GlobalState.me = me.user;
        }
    }

    async componentWillUnmount() {
        const me = await API.me();
        if (me.status === 'success') {
            GlobalState.me = me.user;
        }
    }

    ChargeTab() {
        const user = this.state.me;
        const dollars = ((user.btc * user.btcPrice).toFixed(2)).toLocaleString();
        const btc = user.btc.toFixed(6);

        const {lang_data} = this.state;

        return <div style={{ margin: 21 }}>
            <h3 style={{marginBottom: 11}}>{lang_data.my_btc_address_label}</h3>
            <div>
                <span style={{ fontSize: 12 }}>{user.btcAddress}</span>
                <Button
                    className="white inline small"
                    style={{ float: 'right' }}
                    onClick={() => {
                        copyText(user.btcAddress);
                        alert('My btc address has been copied.')
                    }}>COPY</Button>
            </div>
            <hr style={{ margin: '21px 0' }} />
            <h3 style={{marginBottom: 11}}>{lang_data.my_total_balance_label}</h3>
            <div>
                <DollarsDorothy style={{ marginRight: 11 }}>{btc}BTC</DollarsDorothy>
                <DollarsSmall>{dollars}</DollarsSmall>
            </div>
            <hr style={{ margin: '21px 0' }} />
            <Button
                style={{ display: 'none' }}
                className="green"
                onClick={() => {
                    const platform = Common.checkMobile();
                    if (platform === "android") {
                        window.androidBridge.bridge_show_moonpay(user.btcAddress);
                    } else { //  if (platform === "ios")
                        window.webkit.messageHandlers.bridge_show_moonpay.postMessage({wallet_btc: user.btcAddress});
                    }
                }}>{lang_data.buy_btc_with_credit_card_label}</Button>
            <Button
                className="green"
                onClick={() => {
                    const platform = Common.checkMobile();
                    if (platform === "android") {
                        window.androidBridge.bridge_open_url('https://buy.chainbits.com/');
                    } else { //  if (platform === "ios")
                        window.webkit.messageHandlers.bridge_open_url.postMessage({url: 'https://buy.chainbits.com/'});
                    }
                }}>{lang_data.buy_btc_with_credit_card_label_simplex}</Button>
            {/*<Button*/}
            {/*    style={{marginTop:24}}*/}
            {/*    className="green"*/}
            {/*    onClick={() => {*/}
            {/*        const platform = Common.checkMobile();*/}
            {/*        if (platform === "android") {*/}
            {/*            window.androidBridge.bridge_open_url('https://indacoin.com/buy-bitcoin-with-card');*/}
            {/*        } else if (platform === "ios") {*/}
            {/*            window.webkit.messageHandlers.bridge_open_url.postMessage({url: 'https://indacoin.com/buy-bitcoin-with-card'});*/}
            {/*        }*/}
            {/*    }}>{lang_data.buy_btc_with_credit_card_label_indacoin}</Button>*/}
            <div style={{marginTop: 24, fontSize: 14}}>
                <h4 style={{marginBottom: 3}}>{lang_data.charge_noti_label}</h4>
                <div>{lang_data.charge_noti_label_des_1}</div>
                <div>{lang_data.charge_noti_label_des_2}</div>
                <div>{lang_data.charge_noti_label_des_3}</div>
                <div>{lang_data.charge_noti_label_des_4}</div>
                <div>{lang_data.charge_noti_label_des_5}</div>
            </div>
        </div>
    }

    render() {
        const {lang_data, tabValue, transactions} = this.state;
        return <>
            <NavigationBar
                onBackClick={() => {
                    this.props.history.goBack();
                }}
                title={lang_data.charge_label}
                lang_data={lang_data}/>
            <Tab
                selected={tabValue}
                tabs={[{text: lang_data.charge_label, value: 'charge'}, {
                    text: lang_data.charge_history_label,
                    value: 'history'
                }]}
                onTabClick={(value) => this.setState({ tabValue: value })}
            />
            {tabValue === 'charge' ? this.ChargeTab() : <History history={transactions} type={'charge'}/>}
        </>
    }
}

export default Charge;
