import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import {getLangData, readLangData, setLangCode} from "./utils/LangState";

const Wrap = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    background: linear-gradient(#E26FA4, #F8CB9B);
`;

const LanguageWrap = styled.div`
    padding: 24px;
    h3 {
        color: white;
        font-size: 24px;
        margin-bottom: 16px;
    }
    h5 {
        font-size: 16px;
        font-weight: normal;
        color: white;
    }

    .lang-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        margin-top: 48px;

        & > div {
            height: 100px;
            line-height: 100px;
            text-align: center;
            background-color: #D0274E25;
            border-radius: 8px;
            color: white;

            &.selected {
                background: white;
                color: #EA589E;
            }
        }
    }
`;

// const Language = () => {
class Language extends React.PureComponent {
    state = {
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    // const { t, i18n } = useTranslation()
    //
    // const changeLanguage = (lang) => {
    //     i18n.changeLanguage(lang);
    // }

    async componentDidMount() {

        // const browser = await SignIn.launch();
        const language = document.getElementById('app_lang').value;
        readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    async componentWillUnmount() {
        document.getElementById('app_lang').value = this.state.language;
    }

    render() {

        return (
            <Wrap>
                <NavigationBar
                    onBackClick={() => this.props.history.goBack()}
                    lang_data={this.state.lang_data}
                />
                <LanguageWrap>
                    <h3>{this.state.lang_data !== null ? this.state.lang_data.select_your_language_label : 'Select Your Language'}</h3>
                    <h5>{this.state.lang_data.select_one_label}</h5>
                    <div className="lang-grid">
                        <div data_lang="kr" className={ this.state.language === 'kr' ? 'selected' : ''} onClick={() => {
                            setLangCode('kr');
                            readLangData('kr');
                            this.setState({
                                language: 'kr',
                                lang_data: getLangData()
                            });
                        }}>{this.state.lang_data.korean_label}
                        </div>
                        <div data_lang="en" className={ this.state.language === 'en' ? 'selected' : ''} onClick={() => {
                            setLangCode('en');
                            readLangData('en');
                            this.setState({
                                language: 'en',
                                lang_data: getLangData()
                            });
                        }}>{this.state.lang_data.english_label}
                        </div>
                        <div data_lang="zh-hk" className={ this.state.language === 'zh-hk' ? 'selected' : ''} onClick={() => {
                            setLangCode('zh-hk');
                            readLangData('zh-hk');
                            this.setState({
                                language: 'zh-hk',
                                lang_data: getLangData()});
                        }}>{this.state.lang_data.chinese_label}
                        </div>
                        <div data_lang="jp" className={ this.state.language === 'jp' ? 'selected' : ''} onClick={() => {
                            setLangCode('jp');
                            readLangData('jp');
                            this.setState({
                                language: 'jp',
                                lang_data: getLangData()
                            });
                        }}>{this.state.lang_data.japanese_label}
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                </LanguageWrap>
            </Wrap>
        )
    }

}

export default Language