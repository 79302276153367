import React from 'react';
import styled from 'styled-components';
import API from './utils/api';
import {Button, Input} from './styled-components';
import {getLangData, readLangData} from "./utils/LangState";
import NavigationBar from "./components/NavigationBar";
import {ArticlePopup} from "./components/ArticlePopup";
import Common from "./utils/Common";
import ReactCustomFlagSelect from "./components/react-custom-flag-select";
import {DEFAULT_AREA_CODE, FLAG_SELECTOR_OPTION_LIST} from "./utils/Constants";
import './components/react-custom-flag-select/react-custom-flag-select.css';
import GlobalState from "./mobx/GlobalState";

const NotificationArea = styled.div`
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  padding: 20px;
  background: #FFF1F4;
  border: 1px solid;
`;

const Wrap = styled.div`
  padding: 21px;
  background-color: #F7F7FA;

  ${Input} {
    box-shadow: 1px 1px 3px 2px #00000029;
    border: none;
    background-color: white;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    height : 50px;
    line-height : 38px;
  }

  .tab {
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .story {
      padding: 16px 14px;
      width: 100%;
      box-shadow: 1px 1px 3px 2px #00000029;
      border: none;
      border-radius: 20px;
    }

    .select_div {
      background: white;
      box-shadow: 1px 1px 3px 2px #00000029;
      border: none;
      border-radius: 20px;
      opacity: 1;
      outline: none;
      width: 100%;
    }
    
    .selectbox {
      height:40px;
      padding: 6px 14px;
      background: white;
      box-shadow: 1px 1px 3px 2px #00000029;
      border: none;
      border-radius: 20px;
      opacity: 1;
      outline: none;
      width: 100%;
    }

    #country {
      width: 105%;
      margin-right: 20px;
      padding-left: 5px;
    }
  }
`;

const UploadButton = styled(Button)`
  border-radius: 0;
`;

class EditArticle extends React.Component {
    id = parseInt(this.props.match.params.id);

    isContinued() {
        const {title, orgName, phone, address, description} = this.state;
        return title !== '' && orgName !== '' && phone !== '' && address !== '' && description !== ''
    }

    state = {
        doneeType: 0,
        modify: false,
        title: '',
        orgName: '',
        phone: '',
        address: '',
        description: '',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
        successPopup: false,
        failPopup: false,
        modifySuccessPopup: false,
        areaCode: DEFAULT_AREA_CODE
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "edit_article";
        window.scrollTo(0,0);
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()
        });

        const me = GlobalState.me;
        if (this.id > 0) {
            const vote = await API.getVoteDetail(me.userId, this.id);

            if (vote.status == 'success') {
                this.setState({
                    doneeType: vote.vote_model.classification,
                    modify: true,
                    title: vote.vote_model.title,
                    orgName: vote.vote_model.name,
                    phone: vote.vote_model.phone,
                    address: vote.vote_model.address,
                    description: vote.vote_model.content,
                    areaCode: vote.vote_model.phone_nation
                });
            }
        } 
    }

    goBack = () => {
        this.props.history.goBack()
    };

    async onWriteVote() {
        const {doneeType, title, orgName, phone, address, description, areaCode} = this.state;
        await API.writeVote(this.id, doneeType, title, orgName, address, description, areaCode, phone);
    }

    render() {
        const {areaCode, successPopup, lang_data, failPopup, modifySuccessPopup, doneeType, title, orgName, phone, address, description, modify} = this.state;
        const currentItem = Common.find(FLAG_SELECTOR_OPTION_LIST, {id: areaCode })[0];

        const voting_classification_labels = [
            lang_data.classification1_label,
            lang_data.classification2_label,
            lang_data.classification3_label,
            lang_data.classification4_label,
            lang_data.classification5_label,
            lang_data.classification6_label,
            lang_data.classification7_label,
            lang_data.classification8_label,
            lang_data.classification9_label,
            lang_data.classification10_label,
            lang_data.classification11_label,
            lang_data.classification12_label,
        ];

        return <>
            <ArticlePopup
                enabled={successPopup}
                description={lang_data.edit_article_success_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={() => {
                    this.setState({successPopup: false}, () => {
                        this.goBack()
                    });
                }}/>
            <ArticlePopup
                enabled={failPopup}
                description={lang_data.edit_article_fail_label}
                buttonTitle={lang_data.confirm_label}
                onButtonClick={() => {
                    this.setState({failPopup: false})
                }}/>
            <ArticlePopup
                enabled={modifySuccessPopup}
                description={lang_data.modification_success_label}
                buttonTitle={lang_data.finish_label}
                onButtonClick={() => {
                    this.setState({modifySuccessPopup: false}, () => {
                        this.props.history.replace('/article/' + this.id + '/1')
                    });
                }}/>
            <NavigationBar
                onBackClick={() => this.goBack()}
                lang_data={lang_data}
                title={lang_data.donation_recommendation_label2}/>
            <NotificationArea>
                <div style={{fontSize: 20}}>{lang_data.edit_article_noti1_label}</div>
                <div style={{fontSize: 13, lineHeight: 1.5, paddingTop: 20}}>{lang_data.edit_article_noti2_label}</div>
            </NotificationArea>
            <Wrap>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.classification_label}</div>
                    <div className={'select_div'}>
                        <select className={'selectbox'}
                                value={doneeType}
                                onChange={(e) => {
                                    this.setState({doneeType: e.currentTarget.value});
                                }}>
                            {voting_classification_labels.map((item, index) => {
                                return (
                                    <option value={index} key={index}>{item}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.title_label}</div>
                    <Input
                        value={title}
                        placeholder={lang_data.title_placeholder_label}
                        onChange={(e) => {
                            this.setState({title: e.currentTarget.value});
                        }}/>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.donation_org_name_label}</div>
                    <Input
                        value={orgName}
                        placeholder={lang_data.donation_org_placeholder_label}
                        onChange={(e) => {
                            this.setState({orgName: e.currentTarget.value});
                        }}/>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.donation_orgnum_label}</div>
                    <div style={{display: 'flex'}}>
                        <div className={'selectbox'} style={{padding:0, marginRight:10}}>
                            <ReactCustomFlagSelect
                                attributesWrapper={{ id: 'areaCodeWrapper', tabIndex: '1' }}
                                attributesButton={{ id: 'areaCodeButton' }}
                                attributesInput={{ id: 'areaCode', name: 'areaCode' }}
                                name={'areaCode'}
                                value={currentItem.id}
                                disabled={false}
                                showSearch={true}
                                fields={['name', 'locale', 'displayText', 'englishName']}
                                showArrow={true}
                                animate={true}
                                optionList={FLAG_SELECTOR_OPTION_LIST}
                                classNameWrapper="nation_select"
                                classNameContainer=""
                                classNameOptionListContainer=""
                                classNameOptionListItem=""
                                classNameDropdownIconOptionListItem={''}
                                customStyleWrapper={{}}
                                customStyleContainer={{ border: 'none', fontSize: '12px' }}
                                customStyleOptionListItem={{}}
                                customStyleOptionListContainer={{ maxHeight: '100px', overflow: 'auto', width: '200px' }}
                                onChange={areaCode => {
                                    this.setState({areaCode: areaCode});
                                }}
                            />
                        </div>
                        <Input
                            value={phone}
                            placeholder={lang_data.orgnum_placeholder_label}
                            onChange={(e) => {
                                if (!isNaN(e.currentTarget.value)) this.setState({phone: e.currentTarget.value});
                            }}/>
                    </div>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.donation_org_address_label}</div>
                    <Input
                        value={address}
                        placeholder={lang_data.org_address_placeholder_label}
                        onChange={(e) => {
                            this.setState({address: e.currentTarget.value});
                        }}/>
                </div>
                <div className={'tab'}>
                    <div className={'title'}>* {lang_data.Why_donation_label}</div>
                    <div style={{display: 'flex'}}>
                    <textarea className={'story'}
                              value={description}
                              style={{height: 150}} maxLength={300}
                              onChange={(e) => {
                                  let regexp = /<(.|\n)*?>/g;
                                  let value = e.currentTarget.value;
                                  if (regexp.test(value)) {
                                      return
                                  }
                                  this.setState({description: e.currentTarget.value});
                              }}/>
                    </div>
                </div>
                <UploadButton
                    onClick={async () => {
                        if (this.isContinued()) {
                            this.onWriteVote().then(r => {
                                if (!modify) {
                                    this.setState({successPopup: true})
                                } else {
                                    this.setState({modifySuccessPopup:true})
                                }
                            })
                        } else {
                            this.setState({failPopup: true})
                        }
                    }}>{!modify ? lang_data.upload_label : lang_data.modify_label}</UploadButton>

            </Wrap>
        </>;
    }
}

export default EditArticle;
