import React from 'react';
import NavigationBar from './components/NavigationBar';
import styled from 'styled-components';
import { Button, Input } from './styled-components';
import API from './utils/api';
import {Popup} from "./components/Popup";
import {getLangData, readLangData} from "./utils/LangState";

const Wrap = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

const FindMenu = styled.div`
    width: 100%;
    left: 0px;
    top: 62px;
    z-index: 20;
    display: table;
    background: #e7e7ef;
`;

const FindCenter = styled.div`
    display: table-cell;
    width: 50%;
    height: 60px;
    color: #666a80;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
    position: relative;
    line-height: 1;
    padding: 3px 0px 0px;
`;

const FindCenterA = styled.div`
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -17px;
    bottom: -2px;
    width: 32px;
    height: 17px;
    background: url(/static/img/triangle_wh.png) no-repeat center bottom;
    background-size: 100% auto;
    z-index: 5;
`

class Language extends React.PureComponent {
    state = {
        find_type: 0,
        id: '',
        email: '',
        input_id_enable: 'none',
        tab_id_color:'#ea589e',
        tab_pwd_color:'#666a80',
        bottom_id_enable: 'block',
        bottom_pwd_enable: 'none',
        popup_success: false,
        popup_failure: false,
        popup_find_success: false,
        send_title: 'FIND ID', // SEND E-MAIL
        find_id : '',
        language: document.getElementById('app_lang').value,
        lang_data: readLangData(document.getElementById('app_lang').value),
    };

    async componentDidMount() {
        document.getElementById('app_page_name').value = "find_pwd";
        const language = document.getElementById('app_lang').value;
        await readLangData(language);
        this.setState({
            language: language,
            lang_data: getLangData()});
    }

    render() {
        const {lang_data, popup_success, popup_failure, popup_find_success, find_id, tab_id_color, bottom_id_enable, tab_pwd_color, bottom_pwd_enable, input_id_enable, id, email, find_type, send_title} = this.state;
        return <Wrap>
            <NavigationBar
                onBackClick={() => this.props.history.goBack()}
                hideMenu={true}
                title={''}
                lang_data={lang_data}/>
            <Popup
                enabled={popup_success}
                isSuccess={true}
                title={lang_data.success_title}
                description={lang_data.check_your_email_confirm_label}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({popup_success : false});
                }}/>
            <Popup
                enabled={popup_failure}
                isSuccess={false}
                title={lang_data.failure_title}
                description={lang_data.something_went_wrong_label}
                buttonTitle={lang_data.retry_label}
                onButtonClick={() => {
                    this.setState({popup_failure : false});
                }}/>
            <Popup
                enabled={popup_find_success}
                isSuccess={true}
                title={lang_data.success_title}
                description={find_id}
                buttonTitle={lang_data.ok_label}
                onButtonClick={() => {
                    this.setState({popup_find_success : false});
                }}/>
            <FindMenu>
                <FindCenter
                    style={{color: tab_id_color}}
                    onClick={() => {
                        this.setState({
                            find_type: 0,
                            tab_id_color:'#ea589e',
                            tab_pwd_color:'#666a80',
                            bottom_id_enable: 'block',
                            bottom_pwd_enable: 'none',
                            input_id_enable: 'none',
                            send_title: lang_data.find_id_label,
                        })
                    }}>
                    {lang_data.id_hint}
                    <FindCenterA
                        style={{
                            display: bottom_id_enable
                        }}/>
                </FindCenter>
                <FindCenter
                    style={{color: tab_pwd_color}}
                    onClick={() => {
                        this.setState({
                            find_type: 1,
                            tab_id_color:'#666a80',
                            tab_pwd_color:'#ea589e',
                            bottom_id_enable: 'none',
                            bottom_pwd_enable: 'block',
                            input_id_enable: 'block',
                            send_title: lang_data.send_email_label,
                        })
                    }}>
                    {lang_data.password_label}
                    <FindCenterA style={{display: bottom_pwd_enable}}/>
                </FindCenter>
            </FindMenu>
            <div style={{ padding: 16 }}>
                <Input
                    className="no-shadow"
                    style={{ marginBottom: 24, display: input_id_enable }}
                    value={id}
                    placeholder={lang_data.id_hint}
                    onChange={(e) => this.setState({ id: e.currentTarget.value })}/>
                <Input
                    className="no-shadow"
                    style={{ marginBottom: 24 }}
                    value={email}
                    placeholder={lang_data.email_hint}
                    onChange={(e) => this.setState({ email: e.currentTarget.value })}/>
                <Button className="green" onClick={async () => {
                    if (find_type === 1) {
                        if (id === '') {
                            alert('Please enter your ID.');
                            return;
                        }
                    }

                    if (email === '') {
                        alert('Please enter your Email.');
                        return;
                    }

                    if (find_type === 0) {
                        const resp = await API.findId(email);
                        if (resp.status === 'success') {
                            this.setState({
                                popup_find_success: true,
                                find_id: 'Your ID is ' + resp.id + '.',
                            });
                        } else {
                            this.setState({popup_failure: true});
                        }
                    } else {
                        const resp = await API.resetPassword(email);
                        if (resp.status === 'success') {
                            this.setState({popup_success: true});
                        } else {
                            this.setState({popup_failure: true});
                        }
                    }
                }}>{send_title}</Button>
            </div>
        </Wrap>
    }
}

export default Language;
